// Whole widget is wrapped in "Immediately Invoked Function Expression", to keep our functions out of global namespace
(function () {
    // Check if another widget embed code exists in the page.
    if (window.Saber.exists) {
        console.log('Saber Feedback Warning: This page attempted to run the Saber Feedback snippet more than once. If you are the owner of this website, please ensure that you only run the Saber Feedback snippet once.');
        return;
    }
    window.Saber.exists = true;

    let staging = window.Saber.staging || false;
    let apiHost = staging ? 'https://stagingv2.saberfeedback.com' : 'https://app.saberfeedback.com';
    let widgetApiEndpoint = staging ? 'https://widgetapi-staging.saberfeedback.com/feedback_form?api_key=:id' : 'https://widgetapi.saberfeedback.com/feedback_form?api_key=:id';

    // todo find a way to NOT use global name space for these options
    window.saberFeedbackOptions = {
        jsHost: detectJsHost(),
        apiHost: apiHost,
        debug: window.Saber.debugMode || false
    }

    // fetch widget config using api key

    let fetchUrl = widgetApiEndpoint.replace(':id', window.Saber.apiKey);

    // Check if apiKey is missing or empty before anything else
    let errorMessage = null;
    if (!window.Saber.hasOwnProperty('apiKey')) {
        errorMessage = 'The apiKey setting is missing from the Saber Feedback snippet. If you are the owner of this website, please check your Saber Feedback snippet. You can view more info on your Saber Feedback dashboard.';
    } else if (window.Saber.apiKey === '') {
        errorMessage = 'The apiKey setting is not set correctly in the Saber Feedback snippet. If you are the owner of this website, please check your Saber Feedback snippet. You can view more info on your Saber Feedback dashboard.';
    }

    // If we have an error message, display it
    if (errorMessage) {
        // Unable to show form, so configure to show error message
        window.saberWebsiteConfig = createErrorConfiguration(errorMessage);
        createSaberObject();
        return;
    }

    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        // Only run if the request is complete
        // 4 == DONE
        if (xhr.readyState !== 4) return;

        let errorMessage = null;
        let data = null;

        if (xhr.status === 404) {
            errorMessage = 'This Saber Feedback account is currently disabled or non existent. If you are the owner of this website, you can view more info on your Saber Feedback dashboard.';
        } else if (xhr.status < 200 || xhr.status >= 300) {
            errorMessage = 'Saber Feedback\'s API returned this error code: ' + xhr.status + '. Please contact info@saberfeedback.com for assistance.';

        } else {
            data = JSON.parse(xhr.responseText);
        }

        if (data && !data.formActive) {
            errorMessage = 'This feedback form is disabled. If you are the owner of this website, you can enable the form on your Saber Feedback dashboard.';
        }

        if (errorMessage) {
            // Unable to show form, so configure to show error message
            window.saberWebsiteConfig = createErrorConfiguration(errorMessage);
        } else {
            // todo remove this pointless copying of fields
            // todo move this so that it is only visible and reachable within the saber global object
            window.saberWebsiteConfig = {
                apiKey: data.apiKey,
                accountStatus: data.accountStatus,
                formActive: data.formActive,
                displayName: data.displayName,
                displayScreenshotButton: data.displayScreenshotButton,
                screenshots: data.screenshots,
                requireScreenshot: data.requireScreenshot,
                whiteLabel: data.whiteLabel,
                branding: data.branding,
                i18n: data.i18n,
                customCss: data.customCss,
                // todo make API return data.buttonSettings as JSON, not as String, so we can remove this JSON.parse
                buttonSettings: JSON.parse(data.buttonSettings),
                // todo make API return data.fields as JSON, not as String, so we can remove this JSON.parse
                fields: JSON.parse(data.fields)
            };
        }

        createSaberObject();
    };

    xhr.open('GET', fetchUrl);
    xhr.send();

    // detect script host. if fails (eg because user is on IE) fallback to default
    function detectJsHost() {
        let currentScriptSrc = document.currentScript.src;
        if (!currentScriptSrc) {
            return 'https://widget.saberfeedback.com'
        }

        let url = new URL(currentScriptSrc);
        return url.protocol + '//' + url.host;
    }

    function createErrorConfiguration(errorMessage) {
        return {
            apiKey: '',
            accountStatus: 'active',
            formActive: true,
            displayName: 'An error has occurred',
            displayScreenshotButton: false,
            screenshots: false,
            requireScreenshot: false,
            whiteLabel: false,
            branding: false,
            i18n: '',
            customCss: '',
            buttonSettings: {
                language: 'en',
                label_text: 'Feedback ⚠️',
                auto_label_text: true,
                position: 'right',
                style: 'label',
                background_color: '#cc0000',
                text_color: '#ffffff'
            },
            fields: [
                {
                    required: false,
                    active: true,
                    sort: 0,
                    i18n: {},
                    custom: true,
                    name: errorMessage,
                    type: 'paragraph',
                    style: {
                        bold: true,
                        italic: false,
                        underline: false,
                        size: 16,
                        align: 'center',
                        color: '#cc0000'
                    },
                    id: 1
                }
            ]
        };
    }

    function createSaberObject() {
        /**
         * Main Saber object
         */
        window.Saber = new (function (commands) {
            // don't load Saber if there is no native JSON object
            if (!window.JSON) {
                return false;
            }
            // don't don't load in IE < 9
            if (navigator.appName === 'Microsoft Internet Explorer') {
                var user_agent = navigator.userAgent;
                var version_regex = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (version_regex.exec(user_agent) != null) {
                    var ie_version = parseFloat(RegExp.$1);

                    if (ie_version && ie_version < 9) {
                        return false;
                    }
                }
            }

            // JS Includes
            // Concatenated using ccatjs https://www.npmjs.com/package/ccatjs but I can't use it inside objects, so probably not the best option

(function (global, factory) {
  // Don't need require.js or common.js compatability
  //typeof exports === 'object' && typeof module !== 'undefined' ? factory() :
  //typeof define === 'function' && define.amd ? define(factory) :
  (factory());
}(this, (function () { 'use strict';

// Store setTimeout reference so promise-polyfill will be unaffected by
// other code modifying setTimeout (like sinon.useFakeTimers())
var setTimeoutFunc = setTimeout;

function noop() {}

// Polyfill for Function.prototype.bind
function bind(fn, thisArg) {
  return function() {
    fn.apply(thisArg, arguments);
  };
}

function handle(self, deferred) {
  while (self._state === 3) {
    self = self._value;
  }
  if (self._state === 0) {
    self._deferreds.push(deferred);
    return;
  }
  self._handled = true;
  Promise._immediateFn(function() {
    var cb = self._state === 1 ? deferred.onFulfilled : deferred.onRejected;
    if (cb === null) {
      (self._state === 1 ? resolve : reject)(deferred.promise, self._value);
      return;
    }
    var ret;
    try {
      ret = cb(self._value);
    } catch (e) {
      reject(deferred.promise, e);
      return;
    }
    resolve(deferred.promise, ret);
  });
}

function resolve(self, newValue) {
  try {
    // Promise Resolution Procedure: https://github.com/promises-aplus/promises-spec#the-promise-resolution-procedure
    if (newValue === self)
      throw new TypeError('A promise cannot be resolved with itself.');
    if (
      newValue &&
      (typeof newValue === 'object' || typeof newValue === 'function')
    ) {
      var then = newValue.then;
      if (newValue instanceof Promise) {
        self._state = 3;
        self._value = newValue;
        finale(self);
        return;
      } else if (typeof then === 'function') {
        doResolve(bind(then, newValue), self);
        return;
      }
    }
    self._state = 1;
    self._value = newValue;
    finale(self);
  } catch (e) {
    reject(self, e);
  }
}

function reject(self, newValue) {
  self._state = 2;
  self._value = newValue;
  finale(self);
}

function finale(self) {
  if (self._state === 2 && self._deferreds.length === 0) {
    Promise._immediateFn(function() {
      if (!self._handled) {
        Promise._unhandledRejectionFn(self._value);
      }
    });
  }

  for (var i = 0, len = self._deferreds.length; i < len; i++) {
    handle(self, self._deferreds[i]);
  }
  self._deferreds = null;
}

function Handler(onFulfilled, onRejected, promise) {
  this.onFulfilled = typeof onFulfilled === 'function' ? onFulfilled : null;
  this.onRejected = typeof onRejected === 'function' ? onRejected : null;
  this.promise = promise;
}

/**
 * Take a potentially misbehaving resolver function and make sure
 * onFulfilled and onRejected are only called once.
 *
 * Makes no guarantees about asynchrony.
 */
function doResolve(fn, self) {
  var done = false;
  try {
    fn(
      function(value) {
        if (done) return;
        done = true;
        resolve(self, value);
      },
      function(reason) {
        if (done) return;
        done = true;
        reject(self, reason);
      }
    );
  } catch (ex) {
    if (done) return;
    done = true;
    reject(self, ex);
  }
}

function Promise(fn) {
  if (!(this instanceof Promise))
    throw new TypeError('Promises must be constructed via new');
  if (typeof fn !== 'function') throw new TypeError('not a function');
  this._state = 0;
  this._handled = false;
  this._value = undefined;
  this._deferreds = [];

  doResolve(fn, this);
}

var _proto = Promise.prototype;
_proto.catch = function(onRejected) {
  return this.then(null, onRejected);
};

_proto.then = function(onFulfilled, onRejected) {
  var prom = new this.constructor(noop);

  handle(this, new Handler(onFulfilled, onRejected, prom));
  return prom;
};

Promise.all = function(arr) {
  return new Promise(function(resolve, reject) {
    if (!arr || typeof arr.length === 'undefined')
      throw new TypeError('Promise.all accepts an array');
    var args = Array.prototype.slice.call(arr);
    if (args.length === 0) return resolve([]);
    var remaining = args.length;

    function res(i, val) {
      try {
        if (val && (typeof val === 'object' || typeof val === 'function')) {
          var then = val.then;
          if (typeof then === 'function') {
            then.call(
              val,
              function(val) {
                res(i, val);
              },
              reject
            );
            return;
          }
        }
        args[i] = val;
        if (--remaining === 0) {
          resolve(args);
        }
      } catch (ex) {
        reject(ex);
      }
    }

    for (var i = 0; i < args.length; i++) {
      res(i, args[i]);
    }
  });
};

Promise.resolve = function(value) {
  if (value && typeof value === 'object' && value.constructor === Promise) {
    return value;
  }

  return new Promise(function(resolve) {
    resolve(value);
  });
};

Promise.reject = function(value) {
  return new Promise(function(resolve, reject) {
    reject(value);
  });
};

Promise.race = function(values) {
  return new Promise(function(resolve, reject) {
    for (var i = 0, len = values.length; i < len; i++) {
      values[i].then(resolve, reject);
    }
  });
};

// Use polyfill for setImmediate for performance gains
Promise._immediateFn =
  (typeof setImmediate === 'function' &&
    function(fn) {
      setImmediate(fn);
    }) ||
  function(fn) {
    setTimeoutFunc(fn, 0);
  };

Promise._unhandledRejectionFn = function _unhandledRejectionFn(err) {
  if (typeof console !== 'undefined' && console) {
    console.warn('Possible Unhandled Promise Rejection:', err); // eslint-disable-line no-console
  }
};

var global = (function() {
  // the only reliable means to get the global object is
  // `Function('return this')()`
  // However, this causes CSP violations in Chrome apps.
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw new Error('unable to locate global object');
})();

if (!global.Promise) {
  global.Promise = Promise;
}

})));
/* pako 1.0.10 nodeca/pako */(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.pako = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';


var TYPED_OK =  (typeof Uint8Array !== 'undefined') &&
                (typeof Uint16Array !== 'undefined') &&
                (typeof Int32Array !== 'undefined');

function _has(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

exports.assign = function (obj /*from1, from2, from3, ...*/) {
  var sources = Array.prototype.slice.call(arguments, 1);
  while (sources.length) {
    var source = sources.shift();
    if (!source) { continue; }

    if (typeof source !== 'object') {
      throw new TypeError(source + 'must be non-object');
    }

    for (var p in source) {
      if (_has(source, p)) {
        obj[p] = source[p];
      }
    }
  }

  return obj;
};


// reduce buffer size, avoiding mem copy
exports.shrinkBuf = function (buf, size) {
  if (buf.length === size) { return buf; }
  if (buf.subarray) { return buf.subarray(0, size); }
  buf.length = size;
  return buf;
};


var fnTyped = {
  arraySet: function (dest, src, src_offs, len, dest_offs) {
    if (src.subarray && dest.subarray) {
      dest.set(src.subarray(src_offs, src_offs + len), dest_offs);
      return;
    }
    // Fallback to ordinary array
    for (var i = 0; i < len; i++) {
      dest[dest_offs + i] = src[src_offs + i];
    }
  },
  // Join array of chunks to single array.
  flattenChunks: function (chunks) {
    var i, l, len, pos, chunk, result;

    // calculate data length
    len = 0;
    for (i = 0, l = chunks.length; i < l; i++) {
      len += chunks[i].length;
    }

    // join chunks
    result = new Uint8Array(len);
    pos = 0;
    for (i = 0, l = chunks.length; i < l; i++) {
      chunk = chunks[i];
      result.set(chunk, pos);
      pos += chunk.length;
    }

    return result;
  }
};

var fnUntyped = {
  arraySet: function (dest, src, src_offs, len, dest_offs) {
    for (var i = 0; i < len; i++) {
      dest[dest_offs + i] = src[src_offs + i];
    }
  },
  // Join array of chunks to single array.
  flattenChunks: function (chunks) {
    return [].concat.apply([], chunks);
  }
};


// Enable/Disable typed arrays use, for testing
//
exports.setTyped = function (on) {
  if (on) {
    exports.Buf8  = Uint8Array;
    exports.Buf16 = Uint16Array;
    exports.Buf32 = Int32Array;
    exports.assign(exports, fnTyped);
  } else {
    exports.Buf8  = Array;
    exports.Buf16 = Array;
    exports.Buf32 = Array;
    exports.assign(exports, fnUntyped);
  }
};

exports.setTyped(TYPED_OK);

},{}],2:[function(require,module,exports){
// String encode/decode helpers
'use strict';


var utils = require('./common');


// Quick check if we can use fast array to bin string conversion
//
// - apply(Array) can fail on Android 2.2
// - apply(Uint8Array) can fail on iOS 5.1 Safari
//
var STR_APPLY_OK = true;
var STR_APPLY_UIA_OK = true;

try { String.fromCharCode.apply(null, [ 0 ]); } catch (__) { STR_APPLY_OK = false; }
try { String.fromCharCode.apply(null, new Uint8Array(1)); } catch (__) { STR_APPLY_UIA_OK = false; }


// Table with utf8 lengths (calculated by first byte of sequence)
// Note, that 5 & 6-byte values and some 4-byte values can not be represented in JS,
// because max possible codepoint is 0x10ffff
var _utf8len = new utils.Buf8(256);
for (var q = 0; q < 256; q++) {
  _utf8len[q] = (q >= 252 ? 6 : q >= 248 ? 5 : q >= 240 ? 4 : q >= 224 ? 3 : q >= 192 ? 2 : 1);
}
_utf8len[254] = _utf8len[254] = 1; // Invalid sequence start


// convert string to array (typed, when possible)
exports.string2buf = function (str) {
  var buf, c, c2, m_pos, i, str_len = str.length, buf_len = 0;

  // count binary size
  for (m_pos = 0; m_pos < str_len; m_pos++) {
    c = str.charCodeAt(m_pos);
    if ((c & 0xfc00) === 0xd800 && (m_pos + 1 < str_len)) {
      c2 = str.charCodeAt(m_pos + 1);
      if ((c2 & 0xfc00) === 0xdc00) {
        c = 0x10000 + ((c - 0xd800) << 10) + (c2 - 0xdc00);
        m_pos++;
      }
    }
    buf_len += c < 0x80 ? 1 : c < 0x800 ? 2 : c < 0x10000 ? 3 : 4;
  }

  // allocate buffer
  buf = new utils.Buf8(buf_len);

  // convert
  for (i = 0, m_pos = 0; i < buf_len; m_pos++) {
    c = str.charCodeAt(m_pos);
    if ((c & 0xfc00) === 0xd800 && (m_pos + 1 < str_len)) {
      c2 = str.charCodeAt(m_pos + 1);
      if ((c2 & 0xfc00) === 0xdc00) {
        c = 0x10000 + ((c - 0xd800) << 10) + (c2 - 0xdc00);
        m_pos++;
      }
    }
    if (c < 0x80) {
      /* one byte */
      buf[i++] = c;
    } else if (c < 0x800) {
      /* two bytes */
      buf[i++] = 0xC0 | (c >>> 6);
      buf[i++] = 0x80 | (c & 0x3f);
    } else if (c < 0x10000) {
      /* three bytes */
      buf[i++] = 0xE0 | (c >>> 12);
      buf[i++] = 0x80 | (c >>> 6 & 0x3f);
      buf[i++] = 0x80 | (c & 0x3f);
    } else {
      /* four bytes */
      buf[i++] = 0xf0 | (c >>> 18);
      buf[i++] = 0x80 | (c >>> 12 & 0x3f);
      buf[i++] = 0x80 | (c >>> 6 & 0x3f);
      buf[i++] = 0x80 | (c & 0x3f);
    }
  }

  return buf;
};

// Helper (used in 2 places)
function buf2binstring(buf, len) {
  // On Chrome, the arguments in a function call that are allowed is `65534`.
  // If the length of the buffer is smaller than that, we can use this optimization,
  // otherwise we will take a slower path.
  if (len < 65534) {
    if ((buf.subarray && STR_APPLY_UIA_OK) || (!buf.subarray && STR_APPLY_OK)) {
      return String.fromCharCode.apply(null, utils.shrinkBuf(buf, len));
    }
  }

  var result = '';
  for (var i = 0; i < len; i++) {
    result += String.fromCharCode(buf[i]);
  }
  return result;
}


// Convert byte array to binary string
exports.buf2binstring = function (buf) {
  return buf2binstring(buf, buf.length);
};


// Convert binary string (typed, when possible)
exports.binstring2buf = function (str) {
  var buf = new utils.Buf8(str.length);
  for (var i = 0, len = buf.length; i < len; i++) {
    buf[i] = str.charCodeAt(i);
  }
  return buf;
};


// convert array to string
exports.buf2string = function (buf, max) {
  var i, out, c, c_len;
  var len = max || buf.length;

  // Reserve max possible length (2 words per char)
  // NB: by unknown reasons, Array is significantly faster for
  //     String.fromCharCode.apply than Uint16Array.
  var utf16buf = new Array(len * 2);

  for (out = 0, i = 0; i < len;) {
    c = buf[i++];
    // quick process ascii
    if (c < 0x80) { utf16buf[out++] = c; continue; }

    c_len = _utf8len[c];
    // skip 5 & 6 byte codes
    if (c_len > 4) { utf16buf[out++] = 0xfffd; i += c_len - 1; continue; }

    // apply mask on first byte
    c &= c_len === 2 ? 0x1f : c_len === 3 ? 0x0f : 0x07;
    // join the rest
    while (c_len > 1 && i < len) {
      c = (c << 6) | (buf[i++] & 0x3f);
      c_len--;
    }

    // terminated by end of string?
    if (c_len > 1) { utf16buf[out++] = 0xfffd; continue; }

    if (c < 0x10000) {
      utf16buf[out++] = c;
    } else {
      c -= 0x10000;
      utf16buf[out++] = 0xd800 | ((c >> 10) & 0x3ff);
      utf16buf[out++] = 0xdc00 | (c & 0x3ff);
    }
  }

  return buf2binstring(utf16buf, out);
};


// Calculate max possible position in utf8 buffer,
// that will not break sequence. If that's not possible
// - (very small limits) return max size as is.
//
// buf[] - utf8 bytes array
// max   - length limit (mandatory);
exports.utf8border = function (buf, max) {
  var pos;

  max = max || buf.length;
  if (max > buf.length) { max = buf.length; }

  // go back from last position, until start of sequence found
  pos = max - 1;
  while (pos >= 0 && (buf[pos] & 0xC0) === 0x80) { pos--; }

  // Very small and broken sequence,
  // return max, because we should return something anyway.
  if (pos < 0) { return max; }

  // If we came to start of buffer - that means buffer is too small,
  // return max too.
  if (pos === 0) { return max; }

  return (pos + _utf8len[buf[pos]] > max) ? pos : max;
};

},{"./common":1}],3:[function(require,module,exports){
'use strict';

// Note: adler32 takes 12% for level 0 and 2% for level 6.
// It isn't worth it to make additional optimizations as in original.
// Small size is preferable.

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

function adler32(adler, buf, len, pos) {
  var s1 = (adler & 0xffff) |0,
      s2 = ((adler >>> 16) & 0xffff) |0,
      n = 0;

  while (len !== 0) {
    // Set limit ~ twice less than 5552, to keep
    // s2 in 31-bits, because we force signed ints.
    // in other case %= will fail.
    n = len > 2000 ? 2000 : len;
    len -= n;

    do {
      s1 = (s1 + buf[pos++]) |0;
      s2 = (s2 + s1) |0;
    } while (--n);

    s1 %= 65521;
    s2 %= 65521;
  }

  return (s1 | (s2 << 16)) |0;
}


module.exports = adler32;

},{}],4:[function(require,module,exports){
'use strict';

// Note: we can't get significant speed boost here.
// So write code to minimize size - no pregenerated tables
// and array tools dependencies.

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

// Use ordinary array, since untyped makes no boost here
function makeTable() {
  var c, table = [];

  for (var n = 0; n < 256; n++) {
    c = n;
    for (var k = 0; k < 8; k++) {
      c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
    }
    table[n] = c;
  }

  return table;
}

// Create table on load. Just 255 signed longs. Not a problem.
var crcTable = makeTable();


function crc32(crc, buf, len, pos) {
  var t = crcTable,
      end = pos + len;

  crc ^= -1;

  for (var i = pos; i < end; i++) {
    crc = (crc >>> 8) ^ t[(crc ^ buf[i]) & 0xFF];
  }

  return (crc ^ (-1)); // >>> 0;
}


module.exports = crc32;

},{}],5:[function(require,module,exports){
'use strict';

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

var utils   = require('../utils/common');
var trees   = require('./trees');
var adler32 = require('./adler32');
var crc32   = require('./crc32');
var msg     = require('./messages');

/* Public constants ==========================================================*/
/* ===========================================================================*/


/* Allowed flush values; see deflate() and inflate() below for details */
var Z_NO_FLUSH      = 0;
var Z_PARTIAL_FLUSH = 1;
//var Z_SYNC_FLUSH    = 2;
var Z_FULL_FLUSH    = 3;
var Z_FINISH        = 4;
var Z_BLOCK         = 5;
//var Z_TREES         = 6;


/* Return codes for the compression/decompression functions. Negative values
 * are errors, positive values are used for special but normal events.
 */
var Z_OK            = 0;
var Z_STREAM_END    = 1;
//var Z_NEED_DICT     = 2;
//var Z_ERRNO         = -1;
var Z_STREAM_ERROR  = -2;
var Z_DATA_ERROR    = -3;
//var Z_MEM_ERROR     = -4;
var Z_BUF_ERROR     = -5;
//var Z_VERSION_ERROR = -6;


/* compression levels */
//var Z_NO_COMPRESSION      = 0;
//var Z_BEST_SPEED          = 1;
//var Z_BEST_COMPRESSION    = 9;
var Z_DEFAULT_COMPRESSION = -1;


var Z_FILTERED            = 1;
var Z_HUFFMAN_ONLY        = 2;
var Z_RLE                 = 3;
var Z_FIXED               = 4;
var Z_DEFAULT_STRATEGY    = 0;

/* Possible values of the data_type field (though see inflate()) */
//var Z_BINARY              = 0;
//var Z_TEXT                = 1;
//var Z_ASCII               = 1; // = Z_TEXT
var Z_UNKNOWN             = 2;


/* The deflate compression method */
var Z_DEFLATED  = 8;

/*============================================================================*/


var MAX_MEM_LEVEL = 9;
/* Maximum value for memLevel in deflateInit2 */
var MAX_WBITS = 15;
/* 32K LZ77 window */
var DEF_MEM_LEVEL = 8;


var LENGTH_CODES  = 29;
/* number of length codes, not counting the special END_BLOCK code */
var LITERALS      = 256;
/* number of literal bytes 0..255 */
var L_CODES       = LITERALS + 1 + LENGTH_CODES;
/* number of Literal or Length codes, including the END_BLOCK code */
var D_CODES       = 30;
/* number of distance codes */
var BL_CODES      = 19;
/* number of codes used to transfer the bit lengths */
var HEAP_SIZE     = 2 * L_CODES + 1;
/* maximum heap size */
var MAX_BITS  = 15;
/* All codes must not exceed MAX_BITS bits */

var MIN_MATCH = 3;
var MAX_MATCH = 258;
var MIN_LOOKAHEAD = (MAX_MATCH + MIN_MATCH + 1);

var PRESET_DICT = 0x20;

var INIT_STATE = 42;
var EXTRA_STATE = 69;
var NAME_STATE = 73;
var COMMENT_STATE = 91;
var HCRC_STATE = 103;
var BUSY_STATE = 113;
var FINISH_STATE = 666;

var BS_NEED_MORE      = 1; /* block not completed, need more input or more output */
var BS_BLOCK_DONE     = 2; /* block flush performed */
var BS_FINISH_STARTED = 3; /* finish started, need only more output at next deflate */
var BS_FINISH_DONE    = 4; /* finish done, accept no more input or output */

var OS_CODE = 0x03; // Unix :) . Don't detect, use this default.

function err(strm, errorCode) {
  strm.msg = msg[errorCode];
  return errorCode;
}

function rank(f) {
  return ((f) << 1) - ((f) > 4 ? 9 : 0);
}

function zero(buf) { var len = buf.length; while (--len >= 0) { buf[len] = 0; } }


/* =========================================================================
 * Flush as much pending output as possible. All deflate() output goes
 * through this function so some applications may wish to modify it
 * to avoid allocating a large strm->output buffer and copying into it.
 * (See also read_buf()).
 */
function flush_pending(strm) {
  var s = strm.state;

  //_tr_flush_bits(s);
  var len = s.pending;
  if (len > strm.avail_out) {
    len = strm.avail_out;
  }
  if (len === 0) { return; }

  utils.arraySet(strm.output, s.pending_buf, s.pending_out, len, strm.next_out);
  strm.next_out += len;
  s.pending_out += len;
  strm.total_out += len;
  strm.avail_out -= len;
  s.pending -= len;
  if (s.pending === 0) {
    s.pending_out = 0;
  }
}


function flush_block_only(s, last) {
  trees._tr_flush_block(s, (s.block_start >= 0 ? s.block_start : -1), s.strstart - s.block_start, last);
  s.block_start = s.strstart;
  flush_pending(s.strm);
}


function put_byte(s, b) {
  s.pending_buf[s.pending++] = b;
}


/* =========================================================================
 * Put a short in the pending buffer. The 16-bit value is put in MSB order.
 * IN assertion: the stream state is correct and there is enough room in
 * pending_buf.
 */
function putShortMSB(s, b) {
//  put_byte(s, (Byte)(b >> 8));
//  put_byte(s, (Byte)(b & 0xff));
  s.pending_buf[s.pending++] = (b >>> 8) & 0xff;
  s.pending_buf[s.pending++] = b & 0xff;
}


/* ===========================================================================
 * Read a new buffer from the current input stream, update the adler32
 * and total number of bytes read.  All deflate() input goes through
 * this function so some applications may wish to modify it to avoid
 * allocating a large strm->input buffer and copying from it.
 * (See also flush_pending()).
 */
function read_buf(strm, buf, start, size) {
  var len = strm.avail_in;

  if (len > size) { len = size; }
  if (len === 0) { return 0; }

  strm.avail_in -= len;

  // zmemcpy(buf, strm->next_in, len);
  utils.arraySet(buf, strm.input, strm.next_in, len, start);
  if (strm.state.wrap === 1) {
    strm.adler = adler32(strm.adler, buf, len, start);
  }

  else if (strm.state.wrap === 2) {
    strm.adler = crc32(strm.adler, buf, len, start);
  }

  strm.next_in += len;
  strm.total_in += len;

  return len;
}


/* ===========================================================================
 * Set match_start to the longest match starting at the given string and
 * return its length. Matches shorter or equal to prev_length are discarded,
 * in which case the result is equal to prev_length and match_start is
 * garbage.
 * IN assertions: cur_match is the head of the hash chain for the current
 *   string (strstart) and its distance is <= MAX_DIST, and prev_length >= 1
 * OUT assertion: the match length is not greater than s->lookahead.
 */
function longest_match(s, cur_match) {
  var chain_length = s.max_chain_length;      /* max hash chain length */
  var scan = s.strstart; /* current string */
  var match;                       /* matched string */
  var len;                           /* length of current match */
  var best_len = s.prev_length;              /* best match length so far */
  var nice_match = s.nice_match;             /* stop if match long enough */
  var limit = (s.strstart > (s.w_size - MIN_LOOKAHEAD)) ?
      s.strstart - (s.w_size - MIN_LOOKAHEAD) : 0/*NIL*/;

  var _win = s.window; // shortcut

  var wmask = s.w_mask;
  var prev  = s.prev;

  /* Stop when cur_match becomes <= limit. To simplify the code,
   * we prevent matches with the string of window index 0.
   */

  var strend = s.strstart + MAX_MATCH;
  var scan_end1  = _win[scan + best_len - 1];
  var scan_end   = _win[scan + best_len];

  /* The code is optimized for HASH_BITS >= 8 and MAX_MATCH-2 multiple of 16.
   * It is easy to get rid of this optimization if necessary.
   */
  // Assert(s->hash_bits >= 8 && MAX_MATCH == 258, "Code too clever");

  /* Do not waste too much time if we already have a good match: */
  if (s.prev_length >= s.good_match) {
    chain_length >>= 2;
  }
  /* Do not look for matches beyond the end of the input. This is necessary
   * to make deflate deterministic.
   */
  if (nice_match > s.lookahead) { nice_match = s.lookahead; }

  // Assert((ulg)s->strstart <= s->window_size-MIN_LOOKAHEAD, "need lookahead");

  do {
    // Assert(cur_match < s->strstart, "no future");
    match = cur_match;

    /* Skip to next match if the match length cannot increase
     * or if the match length is less than 2.  Note that the checks below
     * for insufficient lookahead only occur occasionally for performance
     * reasons.  Therefore uninitialized memory will be accessed, and
     * conditional jumps will be made that depend on those values.
     * However the length of the match is limited to the lookahead, so
     * the output of deflate is not affected by the uninitialized values.
     */

    if (_win[match + best_len]     !== scan_end  ||
        _win[match + best_len - 1] !== scan_end1 ||
        _win[match]                !== _win[scan] ||
        _win[++match]              !== _win[scan + 1]) {
      continue;
    }

    /* The check at best_len-1 can be removed because it will be made
     * again later. (This heuristic is not always a win.)
     * It is not necessary to compare scan[2] and match[2] since they
     * are always equal when the other bytes match, given that
     * the hash keys are equal and that HASH_BITS >= 8.
     */
    scan += 2;
    match++;
    // Assert(*scan == *match, "match[2]?");

    /* We check for insufficient lookahead only every 8th comparison;
     * the 256th check will be made at strstart+258.
     */
    do {
      /*jshint noempty:false*/
    } while (_win[++scan] === _win[++match] && _win[++scan] === _win[++match] &&
             _win[++scan] === _win[++match] && _win[++scan] === _win[++match] &&
             _win[++scan] === _win[++match] && _win[++scan] === _win[++match] &&
             _win[++scan] === _win[++match] && _win[++scan] === _win[++match] &&
             scan < strend);

    // Assert(scan <= s->window+(unsigned)(s->window_size-1), "wild scan");

    len = MAX_MATCH - (strend - scan);
    scan = strend - MAX_MATCH;

    if (len > best_len) {
      s.match_start = cur_match;
      best_len = len;
      if (len >= nice_match) {
        break;
      }
      scan_end1  = _win[scan + best_len - 1];
      scan_end   = _win[scan + best_len];
    }
  } while ((cur_match = prev[cur_match & wmask]) > limit && --chain_length !== 0);

  if (best_len <= s.lookahead) {
    return best_len;
  }
  return s.lookahead;
}


/* ===========================================================================
 * Fill the window when the lookahead becomes insufficient.
 * Updates strstart and lookahead.
 *
 * IN assertion: lookahead < MIN_LOOKAHEAD
 * OUT assertions: strstart <= window_size-MIN_LOOKAHEAD
 *    At least one byte has been read, or avail_in == 0; reads are
 *    performed for at least two bytes (required for the zip translate_eol
 *    option -- not supported here).
 */
function fill_window(s) {
  var _w_size = s.w_size;
  var p, n, m, more, str;

  //Assert(s->lookahead < MIN_LOOKAHEAD, "already enough lookahead");

  do {
    more = s.window_size - s.lookahead - s.strstart;

    // JS ints have 32 bit, block below not needed
    /* Deal with !@#$% 64K limit: */
    //if (sizeof(int) <= 2) {
    //    if (more == 0 && s->strstart == 0 && s->lookahead == 0) {
    //        more = wsize;
    //
    //  } else if (more == (unsigned)(-1)) {
    //        /* Very unlikely, but possible on 16 bit machine if
    //         * strstart == 0 && lookahead == 1 (input done a byte at time)
    //         */
    //        more--;
    //    }
    //}


    /* If the window is almost full and there is insufficient lookahead,
     * move the upper half to the lower one to make room in the upper half.
     */
    if (s.strstart >= _w_size + (_w_size - MIN_LOOKAHEAD)) {

      utils.arraySet(s.window, s.window, _w_size, _w_size, 0);
      s.match_start -= _w_size;
      s.strstart -= _w_size;
      /* we now have strstart >= MAX_DIST */
      s.block_start -= _w_size;

      /* Slide the hash table (could be avoided with 32 bit values
       at the expense of memory usage). We slide even when level == 0
       to keep the hash table consistent if we switch back to level > 0
       later. (Using level 0 permanently is not an optimal usage of
       zlib, so we don't care about this pathological case.)
       */

      n = s.hash_size;
      p = n;
      do {
        m = s.head[--p];
        s.head[p] = (m >= _w_size ? m - _w_size : 0);
      } while (--n);

      n = _w_size;
      p = n;
      do {
        m = s.prev[--p];
        s.prev[p] = (m >= _w_size ? m - _w_size : 0);
        /* If n is not on any hash chain, prev[n] is garbage but
         * its value will never be used.
         */
      } while (--n);

      more += _w_size;
    }
    if (s.strm.avail_in === 0) {
      break;
    }

    /* If there was no sliding:
     *    strstart <= WSIZE+MAX_DIST-1 && lookahead <= MIN_LOOKAHEAD - 1 &&
     *    more == window_size - lookahead - strstart
     * => more >= window_size - (MIN_LOOKAHEAD-1 + WSIZE + MAX_DIST-1)
     * => more >= window_size - 2*WSIZE + 2
     * In the BIG_MEM or MMAP case (not yet supported),
     *   window_size == input_size + MIN_LOOKAHEAD  &&
     *   strstart + s->lookahead <= input_size => more >= MIN_LOOKAHEAD.
     * Otherwise, window_size == 2*WSIZE so more >= 2.
     * If there was sliding, more >= WSIZE. So in all cases, more >= 2.
     */
    //Assert(more >= 2, "more < 2");
    n = read_buf(s.strm, s.window, s.strstart + s.lookahead, more);
    s.lookahead += n;

    /* Initialize the hash value now that we have some input: */
    if (s.lookahead + s.insert >= MIN_MATCH) {
      str = s.strstart - s.insert;
      s.ins_h = s.window[str];

      /* UPDATE_HASH(s, s->ins_h, s->window[str + 1]); */
      s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[str + 1]) & s.hash_mask;
//#if MIN_MATCH != 3
//        Call update_hash() MIN_MATCH-3 more times
//#endif
      while (s.insert) {
        /* UPDATE_HASH(s, s->ins_h, s->window[str + MIN_MATCH-1]); */
        s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[str + MIN_MATCH - 1]) & s.hash_mask;

        s.prev[str & s.w_mask] = s.head[s.ins_h];
        s.head[s.ins_h] = str;
        str++;
        s.insert--;
        if (s.lookahead + s.insert < MIN_MATCH) {
          break;
        }
      }
    }
    /* If the whole input has less than MIN_MATCH bytes, ins_h is garbage,
     * but this is not important since only literal bytes will be emitted.
     */

  } while (s.lookahead < MIN_LOOKAHEAD && s.strm.avail_in !== 0);

  /* If the WIN_INIT bytes after the end of the current data have never been
   * written, then zero those bytes in order to avoid memory check reports of
   * the use of uninitialized (or uninitialised as Julian writes) bytes by
   * the longest match routines.  Update the high water mark for the next
   * time through here.  WIN_INIT is set to MAX_MATCH since the longest match
   * routines allow scanning to strstart + MAX_MATCH, ignoring lookahead.
   */
//  if (s.high_water < s.window_size) {
//    var curr = s.strstart + s.lookahead;
//    var init = 0;
//
//    if (s.high_water < curr) {
//      /* Previous high water mark below current data -- zero WIN_INIT
//       * bytes or up to end of window, whichever is less.
//       */
//      init = s.window_size - curr;
//      if (init > WIN_INIT)
//        init = WIN_INIT;
//      zmemzero(s->window + curr, (unsigned)init);
//      s->high_water = curr + init;
//    }
//    else if (s->high_water < (ulg)curr + WIN_INIT) {
//      /* High water mark at or above current data, but below current data
//       * plus WIN_INIT -- zero out to current data plus WIN_INIT, or up
//       * to end of window, whichever is less.
//       */
//      init = (ulg)curr + WIN_INIT - s->high_water;
//      if (init > s->window_size - s->high_water)
//        init = s->window_size - s->high_water;
//      zmemzero(s->window + s->high_water, (unsigned)init);
//      s->high_water += init;
//    }
//  }
//
//  Assert((ulg)s->strstart <= s->window_size - MIN_LOOKAHEAD,
//    "not enough room for search");
}

/* ===========================================================================
 * Copy without compression as much as possible from the input stream, return
 * the current block state.
 * This function does not insert new strings in the dictionary since
 * uncompressible data is probably not useful. This function is used
 * only for the level=0 compression option.
 * NOTE: this function should be optimized to avoid extra copying from
 * window to pending_buf.
 */
function deflate_stored(s, flush) {
  /* Stored blocks are limited to 0xffff bytes, pending_buf is limited
   * to pending_buf_size, and each stored block has a 5 byte header:
   */
  var max_block_size = 0xffff;

  if (max_block_size > s.pending_buf_size - 5) {
    max_block_size = s.pending_buf_size - 5;
  }

  /* Copy as much as possible from input to output: */
  for (;;) {
    /* Fill the window as much as possible: */
    if (s.lookahead <= 1) {

      //Assert(s->strstart < s->w_size+MAX_DIST(s) ||
      //  s->block_start >= (long)s->w_size, "slide too late");
//      if (!(s.strstart < s.w_size + (s.w_size - MIN_LOOKAHEAD) ||
//        s.block_start >= s.w_size)) {
//        throw  new Error("slide too late");
//      }

      fill_window(s);
      if (s.lookahead === 0 && flush === Z_NO_FLUSH) {
        return BS_NEED_MORE;
      }

      if (s.lookahead === 0) {
        break;
      }
      /* flush the current block */
    }
    //Assert(s->block_start >= 0L, "block gone");
//    if (s.block_start < 0) throw new Error("block gone");

    s.strstart += s.lookahead;
    s.lookahead = 0;

    /* Emit a stored block if pending_buf will be full: */
    var max_start = s.block_start + max_block_size;

    if (s.strstart === 0 || s.strstart >= max_start) {
      /* strstart == 0 is possible when wraparound on 16-bit machine */
      s.lookahead = s.strstart - max_start;
      s.strstart = max_start;
      /*** FLUSH_BLOCK(s, 0); ***/
      flush_block_only(s, false);
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
      /***/


    }
    /* Flush if we may have to slide, otherwise block_start may become
     * negative and the data will be gone:
     */
    if (s.strstart - s.block_start >= (s.w_size - MIN_LOOKAHEAD)) {
      /*** FLUSH_BLOCK(s, 0); ***/
      flush_block_only(s, false);
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
      /***/
    }
  }

  s.insert = 0;

  if (flush === Z_FINISH) {
    /*** FLUSH_BLOCK(s, 1); ***/
    flush_block_only(s, true);
    if (s.strm.avail_out === 0) {
      return BS_FINISH_STARTED;
    }
    /***/
    return BS_FINISH_DONE;
  }

  if (s.strstart > s.block_start) {
    /*** FLUSH_BLOCK(s, 0); ***/
    flush_block_only(s, false);
    if (s.strm.avail_out === 0) {
      return BS_NEED_MORE;
    }
    /***/
  }

  return BS_NEED_MORE;
}

/* ===========================================================================
 * Compress as much as possible from the input stream, return the current
 * block state.
 * This function does not perform lazy evaluation of matches and inserts
 * new strings in the dictionary only for unmatched strings or for short
 * matches. It is used only for the fast compression options.
 */
function deflate_fast(s, flush) {
  var hash_head;        /* head of the hash chain */
  var bflush;           /* set if current block must be flushed */

  for (;;) {
    /* Make sure that we always have enough lookahead, except
     * at the end of the input file. We need MAX_MATCH bytes
     * for the next match, plus MIN_MATCH bytes to insert the
     * string following the next match.
     */
    if (s.lookahead < MIN_LOOKAHEAD) {
      fill_window(s);
      if (s.lookahead < MIN_LOOKAHEAD && flush === Z_NO_FLUSH) {
        return BS_NEED_MORE;
      }
      if (s.lookahead === 0) {
        break; /* flush the current block */
      }
    }

    /* Insert the string window[strstart .. strstart+2] in the
     * dictionary, and set hash_head to the head of the hash chain:
     */
    hash_head = 0/*NIL*/;
    if (s.lookahead >= MIN_MATCH) {
      /*** INSERT_STRING(s, s.strstart, hash_head); ***/
      s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[s.strstart + MIN_MATCH - 1]) & s.hash_mask;
      hash_head = s.prev[s.strstart & s.w_mask] = s.head[s.ins_h];
      s.head[s.ins_h] = s.strstart;
      /***/
    }

    /* Find the longest match, discarding those <= prev_length.
     * At this point we have always match_length < MIN_MATCH
     */
    if (hash_head !== 0/*NIL*/ && ((s.strstart - hash_head) <= (s.w_size - MIN_LOOKAHEAD))) {
      /* To simplify the code, we prevent matches with the string
       * of window index 0 (in particular we have to avoid a match
       * of the string with itself at the start of the input file).
       */
      s.match_length = longest_match(s, hash_head);
      /* longest_match() sets match_start */
    }
    if (s.match_length >= MIN_MATCH) {
      // check_match(s, s.strstart, s.match_start, s.match_length); // for debug only

      /*** _tr_tally_dist(s, s.strstart - s.match_start,
                     s.match_length - MIN_MATCH, bflush); ***/
      bflush = trees._tr_tally(s, s.strstart - s.match_start, s.match_length - MIN_MATCH);

      s.lookahead -= s.match_length;

      /* Insert new strings in the hash table only if the match length
       * is not too large. This saves time but degrades compression.
       */
      if (s.match_length <= s.max_lazy_match/*max_insert_length*/ && s.lookahead >= MIN_MATCH) {
        s.match_length--; /* string at strstart already in table */
        do {
          s.strstart++;
          /*** INSERT_STRING(s, s.strstart, hash_head); ***/
          s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[s.strstart + MIN_MATCH - 1]) & s.hash_mask;
          hash_head = s.prev[s.strstart & s.w_mask] = s.head[s.ins_h];
          s.head[s.ins_h] = s.strstart;
          /***/
          /* strstart never exceeds WSIZE-MAX_MATCH, so there are
           * always MIN_MATCH bytes ahead.
           */
        } while (--s.match_length !== 0);
        s.strstart++;
      } else
      {
        s.strstart += s.match_length;
        s.match_length = 0;
        s.ins_h = s.window[s.strstart];
        /* UPDATE_HASH(s, s.ins_h, s.window[s.strstart+1]); */
        s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[s.strstart + 1]) & s.hash_mask;

//#if MIN_MATCH != 3
//                Call UPDATE_HASH() MIN_MATCH-3 more times
//#endif
        /* If lookahead < MIN_MATCH, ins_h is garbage, but it does not
         * matter since it will be recomputed at next deflate call.
         */
      }
    } else {
      /* No match, output a literal byte */
      //Tracevv((stderr,"%c", s.window[s.strstart]));
      /*** _tr_tally_lit(s, s.window[s.strstart], bflush); ***/
      bflush = trees._tr_tally(s, 0, s.window[s.strstart]);

      s.lookahead--;
      s.strstart++;
    }
    if (bflush) {
      /*** FLUSH_BLOCK(s, 0); ***/
      flush_block_only(s, false);
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
      /***/
    }
  }
  s.insert = ((s.strstart < (MIN_MATCH - 1)) ? s.strstart : MIN_MATCH - 1);
  if (flush === Z_FINISH) {
    /*** FLUSH_BLOCK(s, 1); ***/
    flush_block_only(s, true);
    if (s.strm.avail_out === 0) {
      return BS_FINISH_STARTED;
    }
    /***/
    return BS_FINISH_DONE;
  }
  if (s.last_lit) {
    /*** FLUSH_BLOCK(s, 0); ***/
    flush_block_only(s, false);
    if (s.strm.avail_out === 0) {
      return BS_NEED_MORE;
    }
    /***/
  }
  return BS_BLOCK_DONE;
}

/* ===========================================================================
 * Same as above, but achieves better compression. We use a lazy
 * evaluation for matches: a match is finally adopted only if there is
 * no better match at the next window position.
 */
function deflate_slow(s, flush) {
  var hash_head;          /* head of hash chain */
  var bflush;              /* set if current block must be flushed */

  var max_insert;

  /* Process the input block. */
  for (;;) {
    /* Make sure that we always have enough lookahead, except
     * at the end of the input file. We need MAX_MATCH bytes
     * for the next match, plus MIN_MATCH bytes to insert the
     * string following the next match.
     */
    if (s.lookahead < MIN_LOOKAHEAD) {
      fill_window(s);
      if (s.lookahead < MIN_LOOKAHEAD && flush === Z_NO_FLUSH) {
        return BS_NEED_MORE;
      }
      if (s.lookahead === 0) { break; } /* flush the current block */
    }

    /* Insert the string window[strstart .. strstart+2] in the
     * dictionary, and set hash_head to the head of the hash chain:
     */
    hash_head = 0/*NIL*/;
    if (s.lookahead >= MIN_MATCH) {
      /*** INSERT_STRING(s, s.strstart, hash_head); ***/
      s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[s.strstart + MIN_MATCH - 1]) & s.hash_mask;
      hash_head = s.prev[s.strstart & s.w_mask] = s.head[s.ins_h];
      s.head[s.ins_h] = s.strstart;
      /***/
    }

    /* Find the longest match, discarding those <= prev_length.
     */
    s.prev_length = s.match_length;
    s.prev_match = s.match_start;
    s.match_length = MIN_MATCH - 1;

    if (hash_head !== 0/*NIL*/ && s.prev_length < s.max_lazy_match &&
        s.strstart - hash_head <= (s.w_size - MIN_LOOKAHEAD)/*MAX_DIST(s)*/) {
      /* To simplify the code, we prevent matches with the string
       * of window index 0 (in particular we have to avoid a match
       * of the string with itself at the start of the input file).
       */
      s.match_length = longest_match(s, hash_head);
      /* longest_match() sets match_start */

      if (s.match_length <= 5 &&
         (s.strategy === Z_FILTERED || (s.match_length === MIN_MATCH && s.strstart - s.match_start > 4096/*TOO_FAR*/))) {

        /* If prev_match is also MIN_MATCH, match_start is garbage
         * but we will ignore the current match anyway.
         */
        s.match_length = MIN_MATCH - 1;
      }
    }
    /* If there was a match at the previous step and the current
     * match is not better, output the previous match:
     */
    if (s.prev_length >= MIN_MATCH && s.match_length <= s.prev_length) {
      max_insert = s.strstart + s.lookahead - MIN_MATCH;
      /* Do not insert strings in hash table beyond this. */

      //check_match(s, s.strstart-1, s.prev_match, s.prev_length);

      /***_tr_tally_dist(s, s.strstart - 1 - s.prev_match,
                     s.prev_length - MIN_MATCH, bflush);***/
      bflush = trees._tr_tally(s, s.strstart - 1 - s.prev_match, s.prev_length - MIN_MATCH);
      /* Insert in hash table all strings up to the end of the match.
       * strstart-1 and strstart are already inserted. If there is not
       * enough lookahead, the last two strings are not inserted in
       * the hash table.
       */
      s.lookahead -= s.prev_length - 1;
      s.prev_length -= 2;
      do {
        if (++s.strstart <= max_insert) {
          /*** INSERT_STRING(s, s.strstart, hash_head); ***/
          s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[s.strstart + MIN_MATCH - 1]) & s.hash_mask;
          hash_head = s.prev[s.strstart & s.w_mask] = s.head[s.ins_h];
          s.head[s.ins_h] = s.strstart;
          /***/
        }
      } while (--s.prev_length !== 0);
      s.match_available = 0;
      s.match_length = MIN_MATCH - 1;
      s.strstart++;

      if (bflush) {
        /*** FLUSH_BLOCK(s, 0); ***/
        flush_block_only(s, false);
        if (s.strm.avail_out === 0) {
          return BS_NEED_MORE;
        }
        /***/
      }

    } else if (s.match_available) {
      /* If there was no match at the previous position, output a
       * single literal. If there was a match but the current match
       * is longer, truncate the previous match to a single literal.
       */
      //Tracevv((stderr,"%c", s->window[s->strstart-1]));
      /*** _tr_tally_lit(s, s.window[s.strstart-1], bflush); ***/
      bflush = trees._tr_tally(s, 0, s.window[s.strstart - 1]);

      if (bflush) {
        /*** FLUSH_BLOCK_ONLY(s, 0) ***/
        flush_block_only(s, false);
        /***/
      }
      s.strstart++;
      s.lookahead--;
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
    } else {
      /* There is no previous match to compare with, wait for
       * the next step to decide.
       */
      s.match_available = 1;
      s.strstart++;
      s.lookahead--;
    }
  }
  //Assert (flush != Z_NO_FLUSH, "no flush?");
  if (s.match_available) {
    //Tracevv((stderr,"%c", s->window[s->strstart-1]));
    /*** _tr_tally_lit(s, s.window[s.strstart-1], bflush); ***/
    bflush = trees._tr_tally(s, 0, s.window[s.strstart - 1]);

    s.match_available = 0;
  }
  s.insert = s.strstart < MIN_MATCH - 1 ? s.strstart : MIN_MATCH - 1;
  if (flush === Z_FINISH) {
    /*** FLUSH_BLOCK(s, 1); ***/
    flush_block_only(s, true);
    if (s.strm.avail_out === 0) {
      return BS_FINISH_STARTED;
    }
    /***/
    return BS_FINISH_DONE;
  }
  if (s.last_lit) {
    /*** FLUSH_BLOCK(s, 0); ***/
    flush_block_only(s, false);
    if (s.strm.avail_out === 0) {
      return BS_NEED_MORE;
    }
    /***/
  }

  return BS_BLOCK_DONE;
}


/* ===========================================================================
 * For Z_RLE, simply look for runs of bytes, generate matches only of distance
 * one.  Do not maintain a hash table.  (It will be regenerated if this run of
 * deflate switches away from Z_RLE.)
 */
function deflate_rle(s, flush) {
  var bflush;            /* set if current block must be flushed */
  var prev;              /* byte at distance one to match */
  var scan, strend;      /* scan goes up to strend for length of run */

  var _win = s.window;

  for (;;) {
    /* Make sure that we always have enough lookahead, except
     * at the end of the input file. We need MAX_MATCH bytes
     * for the longest run, plus one for the unrolled loop.
     */
    if (s.lookahead <= MAX_MATCH) {
      fill_window(s);
      if (s.lookahead <= MAX_MATCH && flush === Z_NO_FLUSH) {
        return BS_NEED_MORE;
      }
      if (s.lookahead === 0) { break; } /* flush the current block */
    }

    /* See how many times the previous byte repeats */
    s.match_length = 0;
    if (s.lookahead >= MIN_MATCH && s.strstart > 0) {
      scan = s.strstart - 1;
      prev = _win[scan];
      if (prev === _win[++scan] && prev === _win[++scan] && prev === _win[++scan]) {
        strend = s.strstart + MAX_MATCH;
        do {
          /*jshint noempty:false*/
        } while (prev === _win[++scan] && prev === _win[++scan] &&
                 prev === _win[++scan] && prev === _win[++scan] &&
                 prev === _win[++scan] && prev === _win[++scan] &&
                 prev === _win[++scan] && prev === _win[++scan] &&
                 scan < strend);
        s.match_length = MAX_MATCH - (strend - scan);
        if (s.match_length > s.lookahead) {
          s.match_length = s.lookahead;
        }
      }
      //Assert(scan <= s->window+(uInt)(s->window_size-1), "wild scan");
    }

    /* Emit match if have run of MIN_MATCH or longer, else emit literal */
    if (s.match_length >= MIN_MATCH) {
      //check_match(s, s.strstart, s.strstart - 1, s.match_length);

      /*** _tr_tally_dist(s, 1, s.match_length - MIN_MATCH, bflush); ***/
      bflush = trees._tr_tally(s, 1, s.match_length - MIN_MATCH);

      s.lookahead -= s.match_length;
      s.strstart += s.match_length;
      s.match_length = 0;
    } else {
      /* No match, output a literal byte */
      //Tracevv((stderr,"%c", s->window[s->strstart]));
      /*** _tr_tally_lit(s, s.window[s.strstart], bflush); ***/
      bflush = trees._tr_tally(s, 0, s.window[s.strstart]);

      s.lookahead--;
      s.strstart++;
    }
    if (bflush) {
      /*** FLUSH_BLOCK(s, 0); ***/
      flush_block_only(s, false);
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
      /***/
    }
  }
  s.insert = 0;
  if (flush === Z_FINISH) {
    /*** FLUSH_BLOCK(s, 1); ***/
    flush_block_only(s, true);
    if (s.strm.avail_out === 0) {
      return BS_FINISH_STARTED;
    }
    /***/
    return BS_FINISH_DONE;
  }
  if (s.last_lit) {
    /*** FLUSH_BLOCK(s, 0); ***/
    flush_block_only(s, false);
    if (s.strm.avail_out === 0) {
      return BS_NEED_MORE;
    }
    /***/
  }
  return BS_BLOCK_DONE;
}

/* ===========================================================================
 * For Z_HUFFMAN_ONLY, do not look for matches.  Do not maintain a hash table.
 * (It will be regenerated if this run of deflate switches away from Huffman.)
 */
function deflate_huff(s, flush) {
  var bflush;             /* set if current block must be flushed */

  for (;;) {
    /* Make sure that we have a literal to write. */
    if (s.lookahead === 0) {
      fill_window(s);
      if (s.lookahead === 0) {
        if (flush === Z_NO_FLUSH) {
          return BS_NEED_MORE;
        }
        break;      /* flush the current block */
      }
    }

    /* Output a literal byte */
    s.match_length = 0;
    //Tracevv((stderr,"%c", s->window[s->strstart]));
    /*** _tr_tally_lit(s, s.window[s.strstart], bflush); ***/
    bflush = trees._tr_tally(s, 0, s.window[s.strstart]);
    s.lookahead--;
    s.strstart++;
    if (bflush) {
      /*** FLUSH_BLOCK(s, 0); ***/
      flush_block_only(s, false);
      if (s.strm.avail_out === 0) {
        return BS_NEED_MORE;
      }
      /***/
    }
  }
  s.insert = 0;
  if (flush === Z_FINISH) {
    /*** FLUSH_BLOCK(s, 1); ***/
    flush_block_only(s, true);
    if (s.strm.avail_out === 0) {
      return BS_FINISH_STARTED;
    }
    /***/
    return BS_FINISH_DONE;
  }
  if (s.last_lit) {
    /*** FLUSH_BLOCK(s, 0); ***/
    flush_block_only(s, false);
    if (s.strm.avail_out === 0) {
      return BS_NEED_MORE;
    }
    /***/
  }
  return BS_BLOCK_DONE;
}

/* Values for max_lazy_match, good_match and max_chain_length, depending on
 * the desired pack level (0..9). The values given below have been tuned to
 * exclude worst case performance for pathological files. Better values may be
 * found for specific files.
 */
function Config(good_length, max_lazy, nice_length, max_chain, func) {
  this.good_length = good_length;
  this.max_lazy = max_lazy;
  this.nice_length = nice_length;
  this.max_chain = max_chain;
  this.func = func;
}

var configuration_table;

configuration_table = [
  /*      good lazy nice chain */
  new Config(0, 0, 0, 0, deflate_stored),          /* 0 store only */
  new Config(4, 4, 8, 4, deflate_fast),            /* 1 max speed, no lazy matches */
  new Config(4, 5, 16, 8, deflate_fast),           /* 2 */
  new Config(4, 6, 32, 32, deflate_fast),          /* 3 */

  new Config(4, 4, 16, 16, deflate_slow),          /* 4 lazy matches */
  new Config(8, 16, 32, 32, deflate_slow),         /* 5 */
  new Config(8, 16, 128, 128, deflate_slow),       /* 6 */
  new Config(8, 32, 128, 256, deflate_slow),       /* 7 */
  new Config(32, 128, 258, 1024, deflate_slow),    /* 8 */
  new Config(32, 258, 258, 4096, deflate_slow)     /* 9 max compression */
];


/* ===========================================================================
 * Initialize the "longest match" routines for a new zlib stream
 */
function lm_init(s) {
  s.window_size = 2 * s.w_size;

  /*** CLEAR_HASH(s); ***/
  zero(s.head); // Fill with NIL (= 0);

  /* Set the default configuration parameters:
   */
  s.max_lazy_match = configuration_table[s.level].max_lazy;
  s.good_match = configuration_table[s.level].good_length;
  s.nice_match = configuration_table[s.level].nice_length;
  s.max_chain_length = configuration_table[s.level].max_chain;

  s.strstart = 0;
  s.block_start = 0;
  s.lookahead = 0;
  s.insert = 0;
  s.match_length = s.prev_length = MIN_MATCH - 1;
  s.match_available = 0;
  s.ins_h = 0;
}


function DeflateState() {
  this.strm = null;            /* pointer back to this zlib stream */
  this.status = 0;            /* as the name implies */
  this.pending_buf = null;      /* output still pending */
  this.pending_buf_size = 0;  /* size of pending_buf */
  this.pending_out = 0;       /* next pending byte to output to the stream */
  this.pending = 0;           /* nb of bytes in the pending buffer */
  this.wrap = 0;              /* bit 0 true for zlib, bit 1 true for gzip */
  this.gzhead = null;         /* gzip header information to write */
  this.gzindex = 0;           /* where in extra, name, or comment */
  this.method = Z_DEFLATED; /* can only be DEFLATED */
  this.last_flush = -1;   /* value of flush param for previous deflate call */

  this.w_size = 0;  /* LZ77 window size (32K by default) */
  this.w_bits = 0;  /* log2(w_size)  (8..16) */
  this.w_mask = 0;  /* w_size - 1 */

  this.window = null;
  /* Sliding window. Input bytes are read into the second half of the window,
   * and move to the first half later to keep a dictionary of at least wSize
   * bytes. With this organization, matches are limited to a distance of
   * wSize-MAX_MATCH bytes, but this ensures that IO is always
   * performed with a length multiple of the block size.
   */

  this.window_size = 0;
  /* Actual size of window: 2*wSize, except when the user input buffer
   * is directly used as sliding window.
   */

  this.prev = null;
  /* Link to older string with same hash index. To limit the size of this
   * array to 64K, this link is maintained only for the last 32K strings.
   * An index in this array is thus a window index modulo 32K.
   */

  this.head = null;   /* Heads of the hash chains or NIL. */

  this.ins_h = 0;       /* hash index of string to be inserted */
  this.hash_size = 0;   /* number of elements in hash table */
  this.hash_bits = 0;   /* log2(hash_size) */
  this.hash_mask = 0;   /* hash_size-1 */

  this.hash_shift = 0;
  /* Number of bits by which ins_h must be shifted at each input
   * step. It must be such that after MIN_MATCH steps, the oldest
   * byte no longer takes part in the hash key, that is:
   *   hash_shift * MIN_MATCH >= hash_bits
   */

  this.block_start = 0;
  /* Window position at the beginning of the current output block. Gets
   * negative when the window is moved backwards.
   */

  this.match_length = 0;      /* length of best match */
  this.prev_match = 0;        /* previous match */
  this.match_available = 0;   /* set if previous match exists */
  this.strstart = 0;          /* start of string to insert */
  this.match_start = 0;       /* start of matching string */
  this.lookahead = 0;         /* number of valid bytes ahead in window */

  this.prev_length = 0;
  /* Length of the best match at previous step. Matches not greater than this
   * are discarded. This is used in the lazy match evaluation.
   */

  this.max_chain_length = 0;
  /* To speed up deflation, hash chains are never searched beyond this
   * length.  A higher limit improves compression ratio but degrades the
   * speed.
   */

  this.max_lazy_match = 0;
  /* Attempt to find a better match only when the current match is strictly
   * smaller than this value. This mechanism is used only for compression
   * levels >= 4.
   */
  // That's alias to max_lazy_match, don't use directly
  //this.max_insert_length = 0;
  /* Insert new strings in the hash table only if the match length is not
   * greater than this length. This saves time but degrades compression.
   * max_insert_length is used only for compression levels <= 3.
   */

  this.level = 0;     /* compression level (1..9) */
  this.strategy = 0;  /* favor or force Huffman coding*/

  this.good_match = 0;
  /* Use a faster search when the previous match is longer than this */

  this.nice_match = 0; /* Stop searching when current match exceeds this */

              /* used by trees.c: */

  /* Didn't use ct_data typedef below to suppress compiler warning */

  // struct ct_data_s dyn_ltree[HEAP_SIZE];   /* literal and length tree */
  // struct ct_data_s dyn_dtree[2*D_CODES+1]; /* distance tree */
  // struct ct_data_s bl_tree[2*BL_CODES+1];  /* Huffman tree for bit lengths */

  // Use flat array of DOUBLE size, with interleaved fata,
  // because JS does not support effective
  this.dyn_ltree  = new utils.Buf16(HEAP_SIZE * 2);
  this.dyn_dtree  = new utils.Buf16((2 * D_CODES + 1) * 2);
  this.bl_tree    = new utils.Buf16((2 * BL_CODES + 1) * 2);
  zero(this.dyn_ltree);
  zero(this.dyn_dtree);
  zero(this.bl_tree);

  this.l_desc   = null;         /* desc. for literal tree */
  this.d_desc   = null;         /* desc. for distance tree */
  this.bl_desc  = null;         /* desc. for bit length tree */

  //ush bl_count[MAX_BITS+1];
  this.bl_count = new utils.Buf16(MAX_BITS + 1);
  /* number of codes at each bit length for an optimal tree */

  //int heap[2*L_CODES+1];      /* heap used to build the Huffman trees */
  this.heap = new utils.Buf16(2 * L_CODES + 1);  /* heap used to build the Huffman trees */
  zero(this.heap);

  this.heap_len = 0;               /* number of elements in the heap */
  this.heap_max = 0;               /* element of largest frequency */
  /* The sons of heap[n] are heap[2*n] and heap[2*n+1]. heap[0] is not used.
   * The same heap array is used to build all trees.
   */

  this.depth = new utils.Buf16(2 * L_CODES + 1); //uch depth[2*L_CODES+1];
  zero(this.depth);
  /* Depth of each subtree used as tie breaker for trees of equal frequency
   */

  this.l_buf = 0;          /* buffer index for literals or lengths */

  this.lit_bufsize = 0;
  /* Size of match buffer for literals/lengths.  There are 4 reasons for
   * limiting lit_bufsize to 64K:
   *   - frequencies can be kept in 16 bit counters
   *   - if compression is not successful for the first block, all input
   *     data is still in the window so we can still emit a stored block even
   *     when input comes from standard input.  (This can also be done for
   *     all blocks if lit_bufsize is not greater than 32K.)
   *   - if compression is not successful for a file smaller than 64K, we can
   *     even emit a stored file instead of a stored block (saving 5 bytes).
   *     This is applicable only for zip (not gzip or zlib).
   *   - creating new Huffman trees less frequently may not provide fast
   *     adaptation to changes in the input data statistics. (Take for
   *     example a binary file with poorly compressible code followed by
   *     a highly compressible string table.) Smaller buffer sizes give
   *     fast adaptation but have of course the overhead of transmitting
   *     trees more frequently.
   *   - I can't count above 4
   */

  this.last_lit = 0;      /* running index in l_buf */

  this.d_buf = 0;
  /* Buffer index for distances. To simplify the code, d_buf and l_buf have
   * the same number of elements. To use different lengths, an extra flag
   * array would be necessary.
   */

  this.opt_len = 0;       /* bit length of current block with optimal trees */
  this.static_len = 0;    /* bit length of current block with static trees */
  this.matches = 0;       /* number of string matches in current block */
  this.insert = 0;        /* bytes at end of window left to insert */


  this.bi_buf = 0;
  /* Output buffer. bits are inserted starting at the bottom (least
   * significant bits).
   */
  this.bi_valid = 0;
  /* Number of valid bits in bi_buf.  All bits above the last valid bit
   * are always zero.
   */

  // Used for window memory init. We safely ignore it for JS. That makes
  // sense only for pointers and memory check tools.
  //this.high_water = 0;
  /* High water mark offset in window for initialized bytes -- bytes above
   * this are set to zero in order to avoid memory check warnings when
   * longest match routines access bytes past the input.  This is then
   * updated to the new high water mark.
   */
}


function deflateResetKeep(strm) {
  var s;

  if (!strm || !strm.state) {
    return err(strm, Z_STREAM_ERROR);
  }

  strm.total_in = strm.total_out = 0;
  strm.data_type = Z_UNKNOWN;

  s = strm.state;
  s.pending = 0;
  s.pending_out = 0;

  if (s.wrap < 0) {
    s.wrap = -s.wrap;
    /* was made negative by deflate(..., Z_FINISH); */
  }
  s.status = (s.wrap ? INIT_STATE : BUSY_STATE);
  strm.adler = (s.wrap === 2) ?
    0  // crc32(0, Z_NULL, 0)
  :
    1; // adler32(0, Z_NULL, 0)
  s.last_flush = Z_NO_FLUSH;
  trees._tr_init(s);
  return Z_OK;
}


function deflateReset(strm) {
  var ret = deflateResetKeep(strm);
  if (ret === Z_OK) {
    lm_init(strm.state);
  }
  return ret;
}


function deflateSetHeader(strm, head) {
  if (!strm || !strm.state) { return Z_STREAM_ERROR; }
  if (strm.state.wrap !== 2) { return Z_STREAM_ERROR; }
  strm.state.gzhead = head;
  return Z_OK;
}


function deflateInit2(strm, level, method, windowBits, memLevel, strategy) {
  if (!strm) { // === Z_NULL
    return Z_STREAM_ERROR;
  }
  var wrap = 1;

  if (level === Z_DEFAULT_COMPRESSION) {
    level = 6;
  }

  if (windowBits < 0) { /* suppress zlib wrapper */
    wrap = 0;
    windowBits = -windowBits;
  }

  else if (windowBits > 15) {
    wrap = 2;           /* write gzip wrapper instead */
    windowBits -= 16;
  }


  if (memLevel < 1 || memLevel > MAX_MEM_LEVEL || method !== Z_DEFLATED ||
    windowBits < 8 || windowBits > 15 || level < 0 || level > 9 ||
    strategy < 0 || strategy > Z_FIXED) {
    return err(strm, Z_STREAM_ERROR);
  }


  if (windowBits === 8) {
    windowBits = 9;
  }
  /* until 256-byte window bug fixed */

  var s = new DeflateState();

  strm.state = s;
  s.strm = strm;

  s.wrap = wrap;
  s.gzhead = null;
  s.w_bits = windowBits;
  s.w_size = 1 << s.w_bits;
  s.w_mask = s.w_size - 1;

  s.hash_bits = memLevel + 7;
  s.hash_size = 1 << s.hash_bits;
  s.hash_mask = s.hash_size - 1;
  s.hash_shift = ~~((s.hash_bits + MIN_MATCH - 1) / MIN_MATCH);

  s.window = new utils.Buf8(s.w_size * 2);
  s.head = new utils.Buf16(s.hash_size);
  s.prev = new utils.Buf16(s.w_size);

  // Don't need mem init magic for JS.
  //s.high_water = 0;  /* nothing written to s->window yet */

  s.lit_bufsize = 1 << (memLevel + 6); /* 16K elements by default */

  s.pending_buf_size = s.lit_bufsize * 4;

  //overlay = (ushf *) ZALLOC(strm, s->lit_bufsize, sizeof(ush)+2);
  //s->pending_buf = (uchf *) overlay;
  s.pending_buf = new utils.Buf8(s.pending_buf_size);

  // It is offset from `s.pending_buf` (size is `s.lit_bufsize * 2`)
  //s->d_buf = overlay + s->lit_bufsize/sizeof(ush);
  s.d_buf = 1 * s.lit_bufsize;

  //s->l_buf = s->pending_buf + (1+sizeof(ush))*s->lit_bufsize;
  s.l_buf = (1 + 2) * s.lit_bufsize;

  s.level = level;
  s.strategy = strategy;
  s.method = method;

  return deflateReset(strm);
}

function deflateInit(strm, level) {
  return deflateInit2(strm, level, Z_DEFLATED, MAX_WBITS, DEF_MEM_LEVEL, Z_DEFAULT_STRATEGY);
}


function deflate(strm, flush) {
  var old_flush, s;
  var beg, val; // for gzip header write only

  if (!strm || !strm.state ||
    flush > Z_BLOCK || flush < 0) {
    return strm ? err(strm, Z_STREAM_ERROR) : Z_STREAM_ERROR;
  }

  s = strm.state;

  if (!strm.output ||
      (!strm.input && strm.avail_in !== 0) ||
      (s.status === FINISH_STATE && flush !== Z_FINISH)) {
    return err(strm, (strm.avail_out === 0) ? Z_BUF_ERROR : Z_STREAM_ERROR);
  }

  s.strm = strm; /* just in case */
  old_flush = s.last_flush;
  s.last_flush = flush;

  /* Write the header */
  if (s.status === INIT_STATE) {

    if (s.wrap === 2) { // GZIP header
      strm.adler = 0;  //crc32(0L, Z_NULL, 0);
      put_byte(s, 31);
      put_byte(s, 139);
      put_byte(s, 8);
      if (!s.gzhead) { // s->gzhead == Z_NULL
        put_byte(s, 0);
        put_byte(s, 0);
        put_byte(s, 0);
        put_byte(s, 0);
        put_byte(s, 0);
        put_byte(s, s.level === 9 ? 2 :
                    (s.strategy >= Z_HUFFMAN_ONLY || s.level < 2 ?
                     4 : 0));
        put_byte(s, OS_CODE);
        s.status = BUSY_STATE;
      }
      else {
        put_byte(s, (s.gzhead.text ? 1 : 0) +
                    (s.gzhead.hcrc ? 2 : 0) +
                    (!s.gzhead.extra ? 0 : 4) +
                    (!s.gzhead.name ? 0 : 8) +
                    (!s.gzhead.comment ? 0 : 16)
        );
        put_byte(s, s.gzhead.time & 0xff);
        put_byte(s, (s.gzhead.time >> 8) & 0xff);
        put_byte(s, (s.gzhead.time >> 16) & 0xff);
        put_byte(s, (s.gzhead.time >> 24) & 0xff);
        put_byte(s, s.level === 9 ? 2 :
                    (s.strategy >= Z_HUFFMAN_ONLY || s.level < 2 ?
                     4 : 0));
        put_byte(s, s.gzhead.os & 0xff);
        if (s.gzhead.extra && s.gzhead.extra.length) {
          put_byte(s, s.gzhead.extra.length & 0xff);
          put_byte(s, (s.gzhead.extra.length >> 8) & 0xff);
        }
        if (s.gzhead.hcrc) {
          strm.adler = crc32(strm.adler, s.pending_buf, s.pending, 0);
        }
        s.gzindex = 0;
        s.status = EXTRA_STATE;
      }
    }
    else // DEFLATE header
    {
      var header = (Z_DEFLATED + ((s.w_bits - 8) << 4)) << 8;
      var level_flags = -1;

      if (s.strategy >= Z_HUFFMAN_ONLY || s.level < 2) {
        level_flags = 0;
      } else if (s.level < 6) {
        level_flags = 1;
      } else if (s.level === 6) {
        level_flags = 2;
      } else {
        level_flags = 3;
      }
      header |= (level_flags << 6);
      if (s.strstart !== 0) { header |= PRESET_DICT; }
      header += 31 - (header % 31);

      s.status = BUSY_STATE;
      putShortMSB(s, header);

      /* Save the adler32 of the preset dictionary: */
      if (s.strstart !== 0) {
        putShortMSB(s, strm.adler >>> 16);
        putShortMSB(s, strm.adler & 0xffff);
      }
      strm.adler = 1; // adler32(0L, Z_NULL, 0);
    }
  }

//#ifdef GZIP
  if (s.status === EXTRA_STATE) {
    if (s.gzhead.extra/* != Z_NULL*/) {
      beg = s.pending;  /* start of bytes to update crc */

      while (s.gzindex < (s.gzhead.extra.length & 0xffff)) {
        if (s.pending === s.pending_buf_size) {
          if (s.gzhead.hcrc && s.pending > beg) {
            strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
          }
          flush_pending(strm);
          beg = s.pending;
          if (s.pending === s.pending_buf_size) {
            break;
          }
        }
        put_byte(s, s.gzhead.extra[s.gzindex] & 0xff);
        s.gzindex++;
      }
      if (s.gzhead.hcrc && s.pending > beg) {
        strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
      }
      if (s.gzindex === s.gzhead.extra.length) {
        s.gzindex = 0;
        s.status = NAME_STATE;
      }
    }
    else {
      s.status = NAME_STATE;
    }
  }
  if (s.status === NAME_STATE) {
    if (s.gzhead.name/* != Z_NULL*/) {
      beg = s.pending;  /* start of bytes to update crc */
      //int val;

      do {
        if (s.pending === s.pending_buf_size) {
          if (s.gzhead.hcrc && s.pending > beg) {
            strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
          }
          flush_pending(strm);
          beg = s.pending;
          if (s.pending === s.pending_buf_size) {
            val = 1;
            break;
          }
        }
        // JS specific: little magic to add zero terminator to end of string
        if (s.gzindex < s.gzhead.name.length) {
          val = s.gzhead.name.charCodeAt(s.gzindex++) & 0xff;
        } else {
          val = 0;
        }
        put_byte(s, val);
      } while (val !== 0);

      if (s.gzhead.hcrc && s.pending > beg) {
        strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
      }
      if (val === 0) {
        s.gzindex = 0;
        s.status = COMMENT_STATE;
      }
    }
    else {
      s.status = COMMENT_STATE;
    }
  }
  if (s.status === COMMENT_STATE) {
    if (s.gzhead.comment/* != Z_NULL*/) {
      beg = s.pending;  /* start of bytes to update crc */
      //int val;

      do {
        if (s.pending === s.pending_buf_size) {
          if (s.gzhead.hcrc && s.pending > beg) {
            strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
          }
          flush_pending(strm);
          beg = s.pending;
          if (s.pending === s.pending_buf_size) {
            val = 1;
            break;
          }
        }
        // JS specific: little magic to add zero terminator to end of string
        if (s.gzindex < s.gzhead.comment.length) {
          val = s.gzhead.comment.charCodeAt(s.gzindex++) & 0xff;
        } else {
          val = 0;
        }
        put_byte(s, val);
      } while (val !== 0);

      if (s.gzhead.hcrc && s.pending > beg) {
        strm.adler = crc32(strm.adler, s.pending_buf, s.pending - beg, beg);
      }
      if (val === 0) {
        s.status = HCRC_STATE;
      }
    }
    else {
      s.status = HCRC_STATE;
    }
  }
  if (s.status === HCRC_STATE) {
    if (s.gzhead.hcrc) {
      if (s.pending + 2 > s.pending_buf_size) {
        flush_pending(strm);
      }
      if (s.pending + 2 <= s.pending_buf_size) {
        put_byte(s, strm.adler & 0xff);
        put_byte(s, (strm.adler >> 8) & 0xff);
        strm.adler = 0; //crc32(0L, Z_NULL, 0);
        s.status = BUSY_STATE;
      }
    }
    else {
      s.status = BUSY_STATE;
    }
  }
//#endif

  /* Flush as much pending output as possible */
  if (s.pending !== 0) {
    flush_pending(strm);
    if (strm.avail_out === 0) {
      /* Since avail_out is 0, deflate will be called again with
       * more output space, but possibly with both pending and
       * avail_in equal to zero. There won't be anything to do,
       * but this is not an error situation so make sure we
       * return OK instead of BUF_ERROR at next call of deflate:
       */
      s.last_flush = -1;
      return Z_OK;
    }

    /* Make sure there is something to do and avoid duplicate consecutive
     * flushes. For repeated and useless calls with Z_FINISH, we keep
     * returning Z_STREAM_END instead of Z_BUF_ERROR.
     */
  } else if (strm.avail_in === 0 && rank(flush) <= rank(old_flush) &&
    flush !== Z_FINISH) {
    return err(strm, Z_BUF_ERROR);
  }

  /* User must not provide more input after the first FINISH: */
  if (s.status === FINISH_STATE && strm.avail_in !== 0) {
    return err(strm, Z_BUF_ERROR);
  }

  /* Start a new block or continue the current one.
   */
  if (strm.avail_in !== 0 || s.lookahead !== 0 ||
    (flush !== Z_NO_FLUSH && s.status !== FINISH_STATE)) {
    var bstate = (s.strategy === Z_HUFFMAN_ONLY) ? deflate_huff(s, flush) :
      (s.strategy === Z_RLE ? deflate_rle(s, flush) :
        configuration_table[s.level].func(s, flush));

    if (bstate === BS_FINISH_STARTED || bstate === BS_FINISH_DONE) {
      s.status = FINISH_STATE;
    }
    if (bstate === BS_NEED_MORE || bstate === BS_FINISH_STARTED) {
      if (strm.avail_out === 0) {
        s.last_flush = -1;
        /* avoid BUF_ERROR next call, see above */
      }
      return Z_OK;
      /* If flush != Z_NO_FLUSH && avail_out == 0, the next call
       * of deflate should use the same flush parameter to make sure
       * that the flush is complete. So we don't have to output an
       * empty block here, this will be done at next call. This also
       * ensures that for a very small output buffer, we emit at most
       * one empty block.
       */
    }
    if (bstate === BS_BLOCK_DONE) {
      if (flush === Z_PARTIAL_FLUSH) {
        trees._tr_align(s);
      }
      else if (flush !== Z_BLOCK) { /* FULL_FLUSH or SYNC_FLUSH */

        trees._tr_stored_block(s, 0, 0, false);
        /* For a full flush, this empty block will be recognized
         * as a special marker by inflate_sync().
         */
        if (flush === Z_FULL_FLUSH) {
          /*** CLEAR_HASH(s); ***/             /* forget history */
          zero(s.head); // Fill with NIL (= 0);

          if (s.lookahead === 0) {
            s.strstart = 0;
            s.block_start = 0;
            s.insert = 0;
          }
        }
      }
      flush_pending(strm);
      if (strm.avail_out === 0) {
        s.last_flush = -1; /* avoid BUF_ERROR at next call, see above */
        return Z_OK;
      }
    }
  }
  //Assert(strm->avail_out > 0, "bug2");
  //if (strm.avail_out <= 0) { throw new Error("bug2");}

  if (flush !== Z_FINISH) { return Z_OK; }
  if (s.wrap <= 0) { return Z_STREAM_END; }

  /* Write the trailer */
  if (s.wrap === 2) {
    put_byte(s, strm.adler & 0xff);
    put_byte(s, (strm.adler >> 8) & 0xff);
    put_byte(s, (strm.adler >> 16) & 0xff);
    put_byte(s, (strm.adler >> 24) & 0xff);
    put_byte(s, strm.total_in & 0xff);
    put_byte(s, (strm.total_in >> 8) & 0xff);
    put_byte(s, (strm.total_in >> 16) & 0xff);
    put_byte(s, (strm.total_in >> 24) & 0xff);
  }
  else
  {
    putShortMSB(s, strm.adler >>> 16);
    putShortMSB(s, strm.adler & 0xffff);
  }

  flush_pending(strm);
  /* If avail_out is zero, the application will call deflate again
   * to flush the rest.
   */
  if (s.wrap > 0) { s.wrap = -s.wrap; }
  /* write the trailer only once! */
  return s.pending !== 0 ? Z_OK : Z_STREAM_END;
}

function deflateEnd(strm) {
  var status;

  if (!strm/*== Z_NULL*/ || !strm.state/*== Z_NULL*/) {
    return Z_STREAM_ERROR;
  }

  status = strm.state.status;
  if (status !== INIT_STATE &&
    status !== EXTRA_STATE &&
    status !== NAME_STATE &&
    status !== COMMENT_STATE &&
    status !== HCRC_STATE &&
    status !== BUSY_STATE &&
    status !== FINISH_STATE
  ) {
    return err(strm, Z_STREAM_ERROR);
  }

  strm.state = null;

  return status === BUSY_STATE ? err(strm, Z_DATA_ERROR) : Z_OK;
}


/* =========================================================================
 * Initializes the compression dictionary from the given byte
 * sequence without producing any compressed output.
 */
function deflateSetDictionary(strm, dictionary) {
  var dictLength = dictionary.length;

  var s;
  var str, n;
  var wrap;
  var avail;
  var next;
  var input;
  var tmpDict;

  if (!strm/*== Z_NULL*/ || !strm.state/*== Z_NULL*/) {
    return Z_STREAM_ERROR;
  }

  s = strm.state;
  wrap = s.wrap;

  if (wrap === 2 || (wrap === 1 && s.status !== INIT_STATE) || s.lookahead) {
    return Z_STREAM_ERROR;
  }

  /* when using zlib wrappers, compute Adler-32 for provided dictionary */
  if (wrap === 1) {
    /* adler32(strm->adler, dictionary, dictLength); */
    strm.adler = adler32(strm.adler, dictionary, dictLength, 0);
  }

  s.wrap = 0;   /* avoid computing Adler-32 in read_buf */

  /* if dictionary would fill window, just replace the history */
  if (dictLength >= s.w_size) {
    if (wrap === 0) {            /* already empty otherwise */
      /*** CLEAR_HASH(s); ***/
      zero(s.head); // Fill with NIL (= 0);
      s.strstart = 0;
      s.block_start = 0;
      s.insert = 0;
    }
    /* use the tail */
    // dictionary = dictionary.slice(dictLength - s.w_size);
    tmpDict = new utils.Buf8(s.w_size);
    utils.arraySet(tmpDict, dictionary, dictLength - s.w_size, s.w_size, 0);
    dictionary = tmpDict;
    dictLength = s.w_size;
  }
  /* insert dictionary into window and hash */
  avail = strm.avail_in;
  next = strm.next_in;
  input = strm.input;
  strm.avail_in = dictLength;
  strm.next_in = 0;
  strm.input = dictionary;
  fill_window(s);
  while (s.lookahead >= MIN_MATCH) {
    str = s.strstart;
    n = s.lookahead - (MIN_MATCH - 1);
    do {
      /* UPDATE_HASH(s, s->ins_h, s->window[str + MIN_MATCH-1]); */
      s.ins_h = ((s.ins_h << s.hash_shift) ^ s.window[str + MIN_MATCH - 1]) & s.hash_mask;

      s.prev[str & s.w_mask] = s.head[s.ins_h];

      s.head[s.ins_h] = str;
      str++;
    } while (--n);
    s.strstart = str;
    s.lookahead = MIN_MATCH - 1;
    fill_window(s);
  }
  s.strstart += s.lookahead;
  s.block_start = s.strstart;
  s.insert = s.lookahead;
  s.lookahead = 0;
  s.match_length = s.prev_length = MIN_MATCH - 1;
  s.match_available = 0;
  strm.next_in = next;
  strm.input = input;
  strm.avail_in = avail;
  s.wrap = wrap;
  return Z_OK;
}


exports.deflateInit = deflateInit;
exports.deflateInit2 = deflateInit2;
exports.deflateReset = deflateReset;
exports.deflateResetKeep = deflateResetKeep;
exports.deflateSetHeader = deflateSetHeader;
exports.deflate = deflate;
exports.deflateEnd = deflateEnd;
exports.deflateSetDictionary = deflateSetDictionary;
exports.deflateInfo = 'pako deflate (from Nodeca project)';

/* Not implemented
exports.deflateBound = deflateBound;
exports.deflateCopy = deflateCopy;
exports.deflateParams = deflateParams;
exports.deflatePending = deflatePending;
exports.deflatePrime = deflatePrime;
exports.deflateTune = deflateTune;
*/

},{"../utils/common":1,"./adler32":3,"./crc32":4,"./messages":6,"./trees":7}],6:[function(require,module,exports){
'use strict';

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

module.exports = {
  2:      'need dictionary',     /* Z_NEED_DICT       2  */
  1:      'stream end',          /* Z_STREAM_END      1  */
  0:      '',                    /* Z_OK              0  */
  '-1':   'file error',          /* Z_ERRNO         (-1) */
  '-2':   'stream error',        /* Z_STREAM_ERROR  (-2) */
  '-3':   'data error',          /* Z_DATA_ERROR    (-3) */
  '-4':   'insufficient memory', /* Z_MEM_ERROR     (-4) */
  '-5':   'buffer error',        /* Z_BUF_ERROR     (-5) */
  '-6':   'incompatible version' /* Z_VERSION_ERROR (-6) */
};

},{}],7:[function(require,module,exports){
'use strict';

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

/* eslint-disable space-unary-ops */

var utils = require('../utils/common');

/* Public constants ==========================================================*/
/* ===========================================================================*/


//var Z_FILTERED          = 1;
//var Z_HUFFMAN_ONLY      = 2;
//var Z_RLE               = 3;
var Z_FIXED               = 4;
//var Z_DEFAULT_STRATEGY  = 0;

/* Possible values of the data_type field (though see inflate()) */
var Z_BINARY              = 0;
var Z_TEXT                = 1;
//var Z_ASCII             = 1; // = Z_TEXT
var Z_UNKNOWN             = 2;

/*============================================================================*/


function zero(buf) { var len = buf.length; while (--len >= 0) { buf[len] = 0; } }

// From zutil.h

var STORED_BLOCK = 0;
var STATIC_TREES = 1;
var DYN_TREES    = 2;
/* The three kinds of block type */

var MIN_MATCH    = 3;
var MAX_MATCH    = 258;
/* The minimum and maximum match lengths */

// From deflate.h
/* ===========================================================================
 * Internal compression state.
 */

var LENGTH_CODES  = 29;
/* number of length codes, not counting the special END_BLOCK code */

var LITERALS      = 256;
/* number of literal bytes 0..255 */

var L_CODES       = LITERALS + 1 + LENGTH_CODES;
/* number of Literal or Length codes, including the END_BLOCK code */

var D_CODES       = 30;
/* number of distance codes */

var BL_CODES      = 19;
/* number of codes used to transfer the bit lengths */

var HEAP_SIZE     = 2 * L_CODES + 1;
/* maximum heap size */

var MAX_BITS      = 15;
/* All codes must not exceed MAX_BITS bits */

var Buf_size      = 16;
/* size of bit buffer in bi_buf */


/* ===========================================================================
 * Constants
 */

var MAX_BL_BITS = 7;
/* Bit length codes must not exceed MAX_BL_BITS bits */

var END_BLOCK   = 256;
/* end of block literal code */

var REP_3_6     = 16;
/* repeat previous bit length 3-6 times (2 bits of repeat count) */

var REPZ_3_10   = 17;
/* repeat a zero length 3-10 times  (3 bits of repeat count) */

var REPZ_11_138 = 18;
/* repeat a zero length 11-138 times  (7 bits of repeat count) */

/* eslint-disable comma-spacing,array-bracket-spacing */
var extra_lbits =   /* extra bits for each length code */
  [0,0,0,0,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,4,4,4,4,5,5,5,5,0];

var extra_dbits =   /* extra bits for each distance code */
  [0,0,0,0,1,1,2,2,3,3,4,4,5,5,6,6,7,7,8,8,9,9,10,10,11,11,12,12,13,13];

var extra_blbits =  /* extra bits for each bit length code */
  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,3,7];

var bl_order =
  [16,17,18,0,8,7,9,6,10,5,11,4,12,3,13,2,14,1,15];
/* eslint-enable comma-spacing,array-bracket-spacing */

/* The lengths of the bit length codes are sent in order of decreasing
 * probability, to avoid transmitting the lengths for unused bit length codes.
 */

/* ===========================================================================
 * Local data. These are initialized only once.
 */

// We pre-fill arrays with 0 to avoid uninitialized gaps

var DIST_CODE_LEN = 512; /* see definition of array dist_code below */

// !!!! Use flat array instead of structure, Freq = i*2, Len = i*2+1
var static_ltree  = new Array((L_CODES + 2) * 2);
zero(static_ltree);
/* The static literal tree. Since the bit lengths are imposed, there is no
 * need for the L_CODES extra codes used during heap construction. However
 * The codes 286 and 287 are needed to build a canonical tree (see _tr_init
 * below).
 */

var static_dtree  = new Array(D_CODES * 2);
zero(static_dtree);
/* The static distance tree. (Actually a trivial tree since all codes use
 * 5 bits.)
 */

var _dist_code    = new Array(DIST_CODE_LEN);
zero(_dist_code);
/* Distance codes. The first 256 values correspond to the distances
 * 3 .. 258, the last 256 values correspond to the top 8 bits of
 * the 15 bit distances.
 */

var _length_code  = new Array(MAX_MATCH - MIN_MATCH + 1);
zero(_length_code);
/* length code for each normalized match length (0 == MIN_MATCH) */

var base_length   = new Array(LENGTH_CODES);
zero(base_length);
/* First normalized length for each code (0 = MIN_MATCH) */

var base_dist     = new Array(D_CODES);
zero(base_dist);
/* First normalized distance for each code (0 = distance of 1) */


function StaticTreeDesc(static_tree, extra_bits, extra_base, elems, max_length) {

  this.static_tree  = static_tree;  /* static tree or NULL */
  this.extra_bits   = extra_bits;   /* extra bits for each code or NULL */
  this.extra_base   = extra_base;   /* base index for extra_bits */
  this.elems        = elems;        /* max number of elements in the tree */
  this.max_length   = max_length;   /* max bit length for the codes */

  // show if `static_tree` has data or dummy - needed for monomorphic objects
  this.has_stree    = static_tree && static_tree.length;
}


var static_l_desc;
var static_d_desc;
var static_bl_desc;


function TreeDesc(dyn_tree, stat_desc) {
  this.dyn_tree = dyn_tree;     /* the dynamic tree */
  this.max_code = 0;            /* largest code with non zero frequency */
  this.stat_desc = stat_desc;   /* the corresponding static tree */
}



function d_code(dist) {
  return dist < 256 ? _dist_code[dist] : _dist_code[256 + (dist >>> 7)];
}


/* ===========================================================================
 * Output a short LSB first on the stream.
 * IN assertion: there is enough room in pendingBuf.
 */
function put_short(s, w) {
//    put_byte(s, (uch)((w) & 0xff));
//    put_byte(s, (uch)((ush)(w) >> 8));
  s.pending_buf[s.pending++] = (w) & 0xff;
  s.pending_buf[s.pending++] = (w >>> 8) & 0xff;
}


/* ===========================================================================
 * Send a value on a given number of bits.
 * IN assertion: length <= 16 and value fits in length bits.
 */
function send_bits(s, value, length) {
  if (s.bi_valid > (Buf_size - length)) {
    s.bi_buf |= (value << s.bi_valid) & 0xffff;
    put_short(s, s.bi_buf);
    s.bi_buf = value >> (Buf_size - s.bi_valid);
    s.bi_valid += length - Buf_size;
  } else {
    s.bi_buf |= (value << s.bi_valid) & 0xffff;
    s.bi_valid += length;
  }
}


function send_code(s, c, tree) {
  send_bits(s, tree[c * 2]/*.Code*/, tree[c * 2 + 1]/*.Len*/);
}


/* ===========================================================================
 * Reverse the first len bits of a code, using straightforward code (a faster
 * method would use a table)
 * IN assertion: 1 <= len <= 15
 */
function bi_reverse(code, len) {
  var res = 0;
  do {
    res |= code & 1;
    code >>>= 1;
    res <<= 1;
  } while (--len > 0);
  return res >>> 1;
}


/* ===========================================================================
 * Flush the bit buffer, keeping at most 7 bits in it.
 */
function bi_flush(s) {
  if (s.bi_valid === 16) {
    put_short(s, s.bi_buf);
    s.bi_buf = 0;
    s.bi_valid = 0;

  } else if (s.bi_valid >= 8) {
    s.pending_buf[s.pending++] = s.bi_buf & 0xff;
    s.bi_buf >>= 8;
    s.bi_valid -= 8;
  }
}


/* ===========================================================================
 * Compute the optimal bit lengths for a tree and update the total bit length
 * for the current block.
 * IN assertion: the fields freq and dad are set, heap[heap_max] and
 *    above are the tree nodes sorted by increasing frequency.
 * OUT assertions: the field len is set to the optimal bit length, the
 *     array bl_count contains the frequencies for each bit length.
 *     The length opt_len is updated; static_len is also updated if stree is
 *     not null.
 */
function gen_bitlen(s, desc)
//    deflate_state *s;
//    tree_desc *desc;    /* the tree descriptor */
{
  var tree            = desc.dyn_tree;
  var max_code        = desc.max_code;
  var stree           = desc.stat_desc.static_tree;
  var has_stree       = desc.stat_desc.has_stree;
  var extra           = desc.stat_desc.extra_bits;
  var base            = desc.stat_desc.extra_base;
  var max_length      = desc.stat_desc.max_length;
  var h;              /* heap index */
  var n, m;           /* iterate over the tree elements */
  var bits;           /* bit length */
  var xbits;          /* extra bits */
  var f;              /* frequency */
  var overflow = 0;   /* number of elements with bit length too large */

  for (bits = 0; bits <= MAX_BITS; bits++) {
    s.bl_count[bits] = 0;
  }

  /* In a first pass, compute the optimal bit lengths (which may
   * overflow in the case of the bit length tree).
   */
  tree[s.heap[s.heap_max] * 2 + 1]/*.Len*/ = 0; /* root of the heap */

  for (h = s.heap_max + 1; h < HEAP_SIZE; h++) {
    n = s.heap[h];
    bits = tree[tree[n * 2 + 1]/*.Dad*/ * 2 + 1]/*.Len*/ + 1;
    if (bits > max_length) {
      bits = max_length;
      overflow++;
    }
    tree[n * 2 + 1]/*.Len*/ = bits;
    /* We overwrite tree[n].Dad which is no longer needed */

    if (n > max_code) { continue; } /* not a leaf node */

    s.bl_count[bits]++;
    xbits = 0;
    if (n >= base) {
      xbits = extra[n - base];
    }
    f = tree[n * 2]/*.Freq*/;
    s.opt_len += f * (bits + xbits);
    if (has_stree) {
      s.static_len += f * (stree[n * 2 + 1]/*.Len*/ + xbits);
    }
  }
  if (overflow === 0) { return; }

  // Trace((stderr,"\nbit length overflow\n"));
  /* This happens for example on obj2 and pic of the Calgary corpus */

  /* Find the first bit length which could increase: */
  do {
    bits = max_length - 1;
    while (s.bl_count[bits] === 0) { bits--; }
    s.bl_count[bits]--;      /* move one leaf down the tree */
    s.bl_count[bits + 1] += 2; /* move one overflow item as its brother */
    s.bl_count[max_length]--;
    /* The brother of the overflow item also moves one step up,
     * but this does not affect bl_count[max_length]
     */
    overflow -= 2;
  } while (overflow > 0);

  /* Now recompute all bit lengths, scanning in increasing frequency.
   * h is still equal to HEAP_SIZE. (It is simpler to reconstruct all
   * lengths instead of fixing only the wrong ones. This idea is taken
   * from 'ar' written by Haruhiko Okumura.)
   */
  for (bits = max_length; bits !== 0; bits--) {
    n = s.bl_count[bits];
    while (n !== 0) {
      m = s.heap[--h];
      if (m > max_code) { continue; }
      if (tree[m * 2 + 1]/*.Len*/ !== bits) {
        // Trace((stderr,"code %d bits %d->%d\n", m, tree[m].Len, bits));
        s.opt_len += (bits - tree[m * 2 + 1]/*.Len*/) * tree[m * 2]/*.Freq*/;
        tree[m * 2 + 1]/*.Len*/ = bits;
      }
      n--;
    }
  }
}


/* ===========================================================================
 * Generate the codes for a given tree and bit counts (which need not be
 * optimal).
 * IN assertion: the array bl_count contains the bit length statistics for
 * the given tree and the field len is set for all tree elements.
 * OUT assertion: the field code is set for all tree elements of non
 *     zero code length.
 */
function gen_codes(tree, max_code, bl_count)
//    ct_data *tree;             /* the tree to decorate */
//    int max_code;              /* largest code with non zero frequency */
//    ushf *bl_count;            /* number of codes at each bit length */
{
  var next_code = new Array(MAX_BITS + 1); /* next code value for each bit length */
  var code = 0;              /* running code value */
  var bits;                  /* bit index */
  var n;                     /* code index */

  /* The distribution counts are first used to generate the code values
   * without bit reversal.
   */
  for (bits = 1; bits <= MAX_BITS; bits++) {
    next_code[bits] = code = (code + bl_count[bits - 1]) << 1;
  }
  /* Check that the bit counts in bl_count are consistent. The last code
   * must be all ones.
   */
  //Assert (code + bl_count[MAX_BITS]-1 == (1<<MAX_BITS)-1,
  //        "inconsistent bit counts");
  //Tracev((stderr,"\ngen_codes: max_code %d ", max_code));

  for (n = 0;  n <= max_code; n++) {
    var len = tree[n * 2 + 1]/*.Len*/;
    if (len === 0) { continue; }
    /* Now reverse the bits */
    tree[n * 2]/*.Code*/ = bi_reverse(next_code[len]++, len);

    //Tracecv(tree != static_ltree, (stderr,"\nn %3d %c l %2d c %4x (%x) ",
    //     n, (isgraph(n) ? n : ' '), len, tree[n].Code, next_code[len]-1));
  }
}


/* ===========================================================================
 * Initialize the various 'constant' tables.
 */
function tr_static_init() {
  var n;        /* iterates over tree elements */
  var bits;     /* bit counter */
  var length;   /* length value */
  var code;     /* code value */
  var dist;     /* distance index */
  var bl_count = new Array(MAX_BITS + 1);
  /* number of codes at each bit length for an optimal tree */

  // do check in _tr_init()
  //if (static_init_done) return;

  /* For some embedded targets, global variables are not initialized: */
/*#ifdef NO_INIT_GLOBAL_POINTERS
  static_l_desc.static_tree = static_ltree;
  static_l_desc.extra_bits = extra_lbits;
  static_d_desc.static_tree = static_dtree;
  static_d_desc.extra_bits = extra_dbits;
  static_bl_desc.extra_bits = extra_blbits;
#endif*/

  /* Initialize the mapping length (0..255) -> length code (0..28) */
  length = 0;
  for (code = 0; code < LENGTH_CODES - 1; code++) {
    base_length[code] = length;
    for (n = 0; n < (1 << extra_lbits[code]); n++) {
      _length_code[length++] = code;
    }
  }
  //Assert (length == 256, "tr_static_init: length != 256");
  /* Note that the length 255 (match length 258) can be represented
   * in two different ways: code 284 + 5 bits or code 285, so we
   * overwrite length_code[255] to use the best encoding:
   */
  _length_code[length - 1] = code;

  /* Initialize the mapping dist (0..32K) -> dist code (0..29) */
  dist = 0;
  for (code = 0; code < 16; code++) {
    base_dist[code] = dist;
    for (n = 0; n < (1 << extra_dbits[code]); n++) {
      _dist_code[dist++] = code;
    }
  }
  //Assert (dist == 256, "tr_static_init: dist != 256");
  dist >>= 7; /* from now on, all distances are divided by 128 */
  for (; code < D_CODES; code++) {
    base_dist[code] = dist << 7;
    for (n = 0; n < (1 << (extra_dbits[code] - 7)); n++) {
      _dist_code[256 + dist++] = code;
    }
  }
  //Assert (dist == 256, "tr_static_init: 256+dist != 512");

  /* Construct the codes of the static literal tree */
  for (bits = 0; bits <= MAX_BITS; bits++) {
    bl_count[bits] = 0;
  }

  n = 0;
  while (n <= 143) {
    static_ltree[n * 2 + 1]/*.Len*/ = 8;
    n++;
    bl_count[8]++;
  }
  while (n <= 255) {
    static_ltree[n * 2 + 1]/*.Len*/ = 9;
    n++;
    bl_count[9]++;
  }
  while (n <= 279) {
    static_ltree[n * 2 + 1]/*.Len*/ = 7;
    n++;
    bl_count[7]++;
  }
  while (n <= 287) {
    static_ltree[n * 2 + 1]/*.Len*/ = 8;
    n++;
    bl_count[8]++;
  }
  /* Codes 286 and 287 do not exist, but we must include them in the
   * tree construction to get a canonical Huffman tree (longest code
   * all ones)
   */
  gen_codes(static_ltree, L_CODES + 1, bl_count);

  /* The static distance tree is trivial: */
  for (n = 0; n < D_CODES; n++) {
    static_dtree[n * 2 + 1]/*.Len*/ = 5;
    static_dtree[n * 2]/*.Code*/ = bi_reverse(n, 5);
  }

  // Now data ready and we can init static trees
  static_l_desc = new StaticTreeDesc(static_ltree, extra_lbits, LITERALS + 1, L_CODES, MAX_BITS);
  static_d_desc = new StaticTreeDesc(static_dtree, extra_dbits, 0,          D_CODES, MAX_BITS);
  static_bl_desc = new StaticTreeDesc(new Array(0), extra_blbits, 0,         BL_CODES, MAX_BL_BITS);

  //static_init_done = true;
}


/* ===========================================================================
 * Initialize a new block.
 */
function init_block(s) {
  var n; /* iterates over tree elements */

  /* Initialize the trees. */
  for (n = 0; n < L_CODES;  n++) { s.dyn_ltree[n * 2]/*.Freq*/ = 0; }
  for (n = 0; n < D_CODES;  n++) { s.dyn_dtree[n * 2]/*.Freq*/ = 0; }
  for (n = 0; n < BL_CODES; n++) { s.bl_tree[n * 2]/*.Freq*/ = 0; }

  s.dyn_ltree[END_BLOCK * 2]/*.Freq*/ = 1;
  s.opt_len = s.static_len = 0;
  s.last_lit = s.matches = 0;
}


/* ===========================================================================
 * Flush the bit buffer and align the output on a byte boundary
 */
function bi_windup(s)
{
  if (s.bi_valid > 8) {
    put_short(s, s.bi_buf);
  } else if (s.bi_valid > 0) {
    //put_byte(s, (Byte)s->bi_buf);
    s.pending_buf[s.pending++] = s.bi_buf;
  }
  s.bi_buf = 0;
  s.bi_valid = 0;
}

/* ===========================================================================
 * Copy a stored block, storing first the length and its
 * one's complement if requested.
 */
function copy_block(s, buf, len, header)
//DeflateState *s;
//charf    *buf;    /* the input data */
//unsigned len;     /* its length */
//int      header;  /* true if block header must be written */
{
  bi_windup(s);        /* align on byte boundary */

  if (header) {
    put_short(s, len);
    put_short(s, ~len);
  }
//  while (len--) {
//    put_byte(s, *buf++);
//  }
  utils.arraySet(s.pending_buf, s.window, buf, len, s.pending);
  s.pending += len;
}

/* ===========================================================================
 * Compares to subtrees, using the tree depth as tie breaker when
 * the subtrees have equal frequency. This minimizes the worst case length.
 */
function smaller(tree, n, m, depth) {
  var _n2 = n * 2;
  var _m2 = m * 2;
  return (tree[_n2]/*.Freq*/ < tree[_m2]/*.Freq*/ ||
         (tree[_n2]/*.Freq*/ === tree[_m2]/*.Freq*/ && depth[n] <= depth[m]));
}

/* ===========================================================================
 * Restore the heap property by moving down the tree starting at node k,
 * exchanging a node with the smallest of its two sons if necessary, stopping
 * when the heap property is re-established (each father smaller than its
 * two sons).
 */
function pqdownheap(s, tree, k)
//    deflate_state *s;
//    ct_data *tree;  /* the tree to restore */
//    int k;               /* node to move down */
{
  var v = s.heap[k];
  var j = k << 1;  /* left son of k */
  while (j <= s.heap_len) {
    /* Set j to the smallest of the two sons: */
    if (j < s.heap_len &&
      smaller(tree, s.heap[j + 1], s.heap[j], s.depth)) {
      j++;
    }
    /* Exit if v is smaller than both sons */
    if (smaller(tree, v, s.heap[j], s.depth)) { break; }

    /* Exchange v with the smallest son */
    s.heap[k] = s.heap[j];
    k = j;

    /* And continue down the tree, setting j to the left son of k */
    j <<= 1;
  }
  s.heap[k] = v;
}


// inlined manually
// var SMALLEST = 1;

/* ===========================================================================
 * Send the block data compressed using the given Huffman trees
 */
function compress_block(s, ltree, dtree)
//    deflate_state *s;
//    const ct_data *ltree; /* literal tree */
//    const ct_data *dtree; /* distance tree */
{
  var dist;           /* distance of matched string */
  var lc;             /* match length or unmatched char (if dist == 0) */
  var lx = 0;         /* running index in l_buf */
  var code;           /* the code to send */
  var extra;          /* number of extra bits to send */

  if (s.last_lit !== 0) {
    do {
      dist = (s.pending_buf[s.d_buf + lx * 2] << 8) | (s.pending_buf[s.d_buf + lx * 2 + 1]);
      lc = s.pending_buf[s.l_buf + lx];
      lx++;

      if (dist === 0) {
        send_code(s, lc, ltree); /* send a literal byte */
        //Tracecv(isgraph(lc), (stderr," '%c' ", lc));
      } else {
        /* Here, lc is the match length - MIN_MATCH */
        code = _length_code[lc];
        send_code(s, code + LITERALS + 1, ltree); /* send the length code */
        extra = extra_lbits[code];
        if (extra !== 0) {
          lc -= base_length[code];
          send_bits(s, lc, extra);       /* send the extra length bits */
        }
        dist--; /* dist is now the match distance - 1 */
        code = d_code(dist);
        //Assert (code < D_CODES, "bad d_code");

        send_code(s, code, dtree);       /* send the distance code */
        extra = extra_dbits[code];
        if (extra !== 0) {
          dist -= base_dist[code];
          send_bits(s, dist, extra);   /* send the extra distance bits */
        }
      } /* literal or match pair ? */

      /* Check that the overlay between pending_buf and d_buf+l_buf is ok: */
      //Assert((uInt)(s->pending) < s->lit_bufsize + 2*lx,
      //       "pendingBuf overflow");

    } while (lx < s.last_lit);
  }

  send_code(s, END_BLOCK, ltree);
}


/* ===========================================================================
 * Construct one Huffman tree and assigns the code bit strings and lengths.
 * Update the total bit length for the current block.
 * IN assertion: the field freq is set for all tree elements.
 * OUT assertions: the fields len and code are set to the optimal bit length
 *     and corresponding code. The length opt_len is updated; static_len is
 *     also updated if stree is not null. The field max_code is set.
 */
function build_tree(s, desc)
//    deflate_state *s;
//    tree_desc *desc; /* the tree descriptor */
{
  var tree     = desc.dyn_tree;
  var stree    = desc.stat_desc.static_tree;
  var has_stree = desc.stat_desc.has_stree;
  var elems    = desc.stat_desc.elems;
  var n, m;          /* iterate over heap elements */
  var max_code = -1; /* largest code with non zero frequency */
  var node;          /* new node being created */

  /* Construct the initial heap, with least frequent element in
   * heap[SMALLEST]. The sons of heap[n] are heap[2*n] and heap[2*n+1].
   * heap[0] is not used.
   */
  s.heap_len = 0;
  s.heap_max = HEAP_SIZE;

  for (n = 0; n < elems; n++) {
    if (tree[n * 2]/*.Freq*/ !== 0) {
      s.heap[++s.heap_len] = max_code = n;
      s.depth[n] = 0;

    } else {
      tree[n * 2 + 1]/*.Len*/ = 0;
    }
  }

  /* The pkzip format requires that at least one distance code exists,
   * and that at least one bit should be sent even if there is only one
   * possible code. So to avoid special checks later on we force at least
   * two codes of non zero frequency.
   */
  while (s.heap_len < 2) {
    node = s.heap[++s.heap_len] = (max_code < 2 ? ++max_code : 0);
    tree[node * 2]/*.Freq*/ = 1;
    s.depth[node] = 0;
    s.opt_len--;

    if (has_stree) {
      s.static_len -= stree[node * 2 + 1]/*.Len*/;
    }
    /* node is 0 or 1 so it does not have extra bits */
  }
  desc.max_code = max_code;

  /* The elements heap[heap_len/2+1 .. heap_len] are leaves of the tree,
   * establish sub-heaps of increasing lengths:
   */
  for (n = (s.heap_len >> 1/*int /2*/); n >= 1; n--) { pqdownheap(s, tree, n); }

  /* Construct the Huffman tree by repeatedly combining the least two
   * frequent nodes.
   */
  node = elems;              /* next internal node of the tree */
  do {
    //pqremove(s, tree, n);  /* n = node of least frequency */
    /*** pqremove ***/
    n = s.heap[1/*SMALLEST*/];
    s.heap[1/*SMALLEST*/] = s.heap[s.heap_len--];
    pqdownheap(s, tree, 1/*SMALLEST*/);
    /***/

    m = s.heap[1/*SMALLEST*/]; /* m = node of next least frequency */

    s.heap[--s.heap_max] = n; /* keep the nodes sorted by frequency */
    s.heap[--s.heap_max] = m;

    /* Create a new node father of n and m */
    tree[node * 2]/*.Freq*/ = tree[n * 2]/*.Freq*/ + tree[m * 2]/*.Freq*/;
    s.depth[node] = (s.depth[n] >= s.depth[m] ? s.depth[n] : s.depth[m]) + 1;
    tree[n * 2 + 1]/*.Dad*/ = tree[m * 2 + 1]/*.Dad*/ = node;

    /* and insert the new node in the heap */
    s.heap[1/*SMALLEST*/] = node++;
    pqdownheap(s, tree, 1/*SMALLEST*/);

  } while (s.heap_len >= 2);

  s.heap[--s.heap_max] = s.heap[1/*SMALLEST*/];

  /* At this point, the fields freq and dad are set. We can now
   * generate the bit lengths.
   */
  gen_bitlen(s, desc);

  /* The field len is now set, we can generate the bit codes */
  gen_codes(tree, max_code, s.bl_count);
}


/* ===========================================================================
 * Scan a literal or distance tree to determine the frequencies of the codes
 * in the bit length tree.
 */
function scan_tree(s, tree, max_code)
//    deflate_state *s;
//    ct_data *tree;   /* the tree to be scanned */
//    int max_code;    /* and its largest code of non zero frequency */
{
  var n;                     /* iterates over all tree elements */
  var prevlen = -1;          /* last emitted length */
  var curlen;                /* length of current code */

  var nextlen = tree[0 * 2 + 1]/*.Len*/; /* length of next code */

  var count = 0;             /* repeat count of the current code */
  var max_count = 7;         /* max repeat count */
  var min_count = 4;         /* min repeat count */

  if (nextlen === 0) {
    max_count = 138;
    min_count = 3;
  }
  tree[(max_code + 1) * 2 + 1]/*.Len*/ = 0xffff; /* guard */

  for (n = 0; n <= max_code; n++) {
    curlen = nextlen;
    nextlen = tree[(n + 1) * 2 + 1]/*.Len*/;

    if (++count < max_count && curlen === nextlen) {
      continue;

    } else if (count < min_count) {
      s.bl_tree[curlen * 2]/*.Freq*/ += count;

    } else if (curlen !== 0) {

      if (curlen !== prevlen) { s.bl_tree[curlen * 2]/*.Freq*/++; }
      s.bl_tree[REP_3_6 * 2]/*.Freq*/++;

    } else if (count <= 10) {
      s.bl_tree[REPZ_3_10 * 2]/*.Freq*/++;

    } else {
      s.bl_tree[REPZ_11_138 * 2]/*.Freq*/++;
    }

    count = 0;
    prevlen = curlen;

    if (nextlen === 0) {
      max_count = 138;
      min_count = 3;

    } else if (curlen === nextlen) {
      max_count = 6;
      min_count = 3;

    } else {
      max_count = 7;
      min_count = 4;
    }
  }
}


/* ===========================================================================
 * Send a literal or distance tree in compressed form, using the codes in
 * bl_tree.
 */
function send_tree(s, tree, max_code)
//    deflate_state *s;
//    ct_data *tree; /* the tree to be scanned */
//    int max_code;       /* and its largest code of non zero frequency */
{
  var n;                     /* iterates over all tree elements */
  var prevlen = -1;          /* last emitted length */
  var curlen;                /* length of current code */

  var nextlen = tree[0 * 2 + 1]/*.Len*/; /* length of next code */

  var count = 0;             /* repeat count of the current code */
  var max_count = 7;         /* max repeat count */
  var min_count = 4;         /* min repeat count */

  /* tree[max_code+1].Len = -1; */  /* guard already set */
  if (nextlen === 0) {
    max_count = 138;
    min_count = 3;
  }

  for (n = 0; n <= max_code; n++) {
    curlen = nextlen;
    nextlen = tree[(n + 1) * 2 + 1]/*.Len*/;

    if (++count < max_count && curlen === nextlen) {
      continue;

    } else if (count < min_count) {
      do { send_code(s, curlen, s.bl_tree); } while (--count !== 0);

    } else if (curlen !== 0) {
      if (curlen !== prevlen) {
        send_code(s, curlen, s.bl_tree);
        count--;
      }
      //Assert(count >= 3 && count <= 6, " 3_6?");
      send_code(s, REP_3_6, s.bl_tree);
      send_bits(s, count - 3, 2);

    } else if (count <= 10) {
      send_code(s, REPZ_3_10, s.bl_tree);
      send_bits(s, count - 3, 3);

    } else {
      send_code(s, REPZ_11_138, s.bl_tree);
      send_bits(s, count - 11, 7);
    }

    count = 0;
    prevlen = curlen;
    if (nextlen === 0) {
      max_count = 138;
      min_count = 3;

    } else if (curlen === nextlen) {
      max_count = 6;
      min_count = 3;

    } else {
      max_count = 7;
      min_count = 4;
    }
  }
}


/* ===========================================================================
 * Construct the Huffman tree for the bit lengths and return the index in
 * bl_order of the last bit length code to send.
 */
function build_bl_tree(s) {
  var max_blindex;  /* index of last bit length code of non zero freq */

  /* Determine the bit length frequencies for literal and distance trees */
  scan_tree(s, s.dyn_ltree, s.l_desc.max_code);
  scan_tree(s, s.dyn_dtree, s.d_desc.max_code);

  /* Build the bit length tree: */
  build_tree(s, s.bl_desc);
  /* opt_len now includes the length of the tree representations, except
   * the lengths of the bit lengths codes and the 5+5+4 bits for the counts.
   */

  /* Determine the number of bit length codes to send. The pkzip format
   * requires that at least 4 bit length codes be sent. (appnote.txt says
   * 3 but the actual value used is 4.)
   */
  for (max_blindex = BL_CODES - 1; max_blindex >= 3; max_blindex--) {
    if (s.bl_tree[bl_order[max_blindex] * 2 + 1]/*.Len*/ !== 0) {
      break;
    }
  }
  /* Update opt_len to include the bit length tree and counts */
  s.opt_len += 3 * (max_blindex + 1) + 5 + 5 + 4;
  //Tracev((stderr, "\ndyn trees: dyn %ld, stat %ld",
  //        s->opt_len, s->static_len));

  return max_blindex;
}


/* ===========================================================================
 * Send the header for a block using dynamic Huffman trees: the counts, the
 * lengths of the bit length codes, the literal tree and the distance tree.
 * IN assertion: lcodes >= 257, dcodes >= 1, blcodes >= 4.
 */
function send_all_trees(s, lcodes, dcodes, blcodes)
//    deflate_state *s;
//    int lcodes, dcodes, blcodes; /* number of codes for each tree */
{
  var rank;                    /* index in bl_order */

  //Assert (lcodes >= 257 && dcodes >= 1 && blcodes >= 4, "not enough codes");
  //Assert (lcodes <= L_CODES && dcodes <= D_CODES && blcodes <= BL_CODES,
  //        "too many codes");
  //Tracev((stderr, "\nbl counts: "));
  send_bits(s, lcodes - 257, 5); /* not +255 as stated in appnote.txt */
  send_bits(s, dcodes - 1,   5);
  send_bits(s, blcodes - 4,  4); /* not -3 as stated in appnote.txt */
  for (rank = 0; rank < blcodes; rank++) {
    //Tracev((stderr, "\nbl code %2d ", bl_order[rank]));
    send_bits(s, s.bl_tree[bl_order[rank] * 2 + 1]/*.Len*/, 3);
  }
  //Tracev((stderr, "\nbl tree: sent %ld", s->bits_sent));

  send_tree(s, s.dyn_ltree, lcodes - 1); /* literal tree */
  //Tracev((stderr, "\nlit tree: sent %ld", s->bits_sent));

  send_tree(s, s.dyn_dtree, dcodes - 1); /* distance tree */
  //Tracev((stderr, "\ndist tree: sent %ld", s->bits_sent));
}


/* ===========================================================================
 * Check if the data type is TEXT or BINARY, using the following algorithm:
 * - TEXT if the two conditions below are satisfied:
 *    a) There are no non-portable control characters belonging to the
 *       "black list" (0..6, 14..25, 28..31).
 *    b) There is at least one printable character belonging to the
 *       "white list" (9 {TAB}, 10 {LF}, 13 {CR}, 32..255).
 * - BINARY otherwise.
 * - The following partially-portable control characters form a
 *   "gray list" that is ignored in this detection algorithm:
 *   (7 {BEL}, 8 {BS}, 11 {VT}, 12 {FF}, 26 {SUB}, 27 {ESC}).
 * IN assertion: the fields Freq of dyn_ltree are set.
 */
function detect_data_type(s) {
  /* black_mask is the bit mask of black-listed bytes
   * set bits 0..6, 14..25, and 28..31
   * 0xf3ffc07f = binary 11110011111111111100000001111111
   */
  var black_mask = 0xf3ffc07f;
  var n;

  /* Check for non-textual ("black-listed") bytes. */
  for (n = 0; n <= 31; n++, black_mask >>>= 1) {
    if ((black_mask & 1) && (s.dyn_ltree[n * 2]/*.Freq*/ !== 0)) {
      return Z_BINARY;
    }
  }

  /* Check for textual ("white-listed") bytes. */
  if (s.dyn_ltree[9 * 2]/*.Freq*/ !== 0 || s.dyn_ltree[10 * 2]/*.Freq*/ !== 0 ||
      s.dyn_ltree[13 * 2]/*.Freq*/ !== 0) {
    return Z_TEXT;
  }
  for (n = 32; n < LITERALS; n++) {
    if (s.dyn_ltree[n * 2]/*.Freq*/ !== 0) {
      return Z_TEXT;
    }
  }

  /* There are no "black-listed" or "white-listed" bytes:
   * this stream either is empty or has tolerated ("gray-listed") bytes only.
   */
  return Z_BINARY;
}


var static_init_done = false;

/* ===========================================================================
 * Initialize the tree data structures for a new zlib stream.
 */
function _tr_init(s)
{

  if (!static_init_done) {
    tr_static_init();
    static_init_done = true;
  }

  s.l_desc  = new TreeDesc(s.dyn_ltree, static_l_desc);
  s.d_desc  = new TreeDesc(s.dyn_dtree, static_d_desc);
  s.bl_desc = new TreeDesc(s.bl_tree, static_bl_desc);

  s.bi_buf = 0;
  s.bi_valid = 0;

  /* Initialize the first block of the first file: */
  init_block(s);
}


/* ===========================================================================
 * Send a stored block
 */
function _tr_stored_block(s, buf, stored_len, last)
//DeflateState *s;
//charf *buf;       /* input block */
//ulg stored_len;   /* length of input block */
//int last;         /* one if this is the last block for a file */
{
  send_bits(s, (STORED_BLOCK << 1) + (last ? 1 : 0), 3);    /* send block type */
  copy_block(s, buf, stored_len, true); /* with header */
}


/* ===========================================================================
 * Send one empty static block to give enough lookahead for inflate.
 * This takes 10 bits, of which 7 may remain in the bit buffer.
 */
function _tr_align(s) {
  send_bits(s, STATIC_TREES << 1, 3);
  send_code(s, END_BLOCK, static_ltree);
  bi_flush(s);
}


/* ===========================================================================
 * Determine the best encoding for the current block: dynamic trees, static
 * trees or store, and output the encoded block to the zip file.
 */
function _tr_flush_block(s, buf, stored_len, last)
//DeflateState *s;
//charf *buf;       /* input block, or NULL if too old */
//ulg stored_len;   /* length of input block */
//int last;         /* one if this is the last block for a file */
{
  var opt_lenb, static_lenb;  /* opt_len and static_len in bytes */
  var max_blindex = 0;        /* index of last bit length code of non zero freq */

  /* Build the Huffman trees unless a stored block is forced */
  if (s.level > 0) {

    /* Check if the file is binary or text */
    if (s.strm.data_type === Z_UNKNOWN) {
      s.strm.data_type = detect_data_type(s);
    }

    /* Construct the literal and distance trees */
    build_tree(s, s.l_desc);
    // Tracev((stderr, "\nlit data: dyn %ld, stat %ld", s->opt_len,
    //        s->static_len));

    build_tree(s, s.d_desc);
    // Tracev((stderr, "\ndist data: dyn %ld, stat %ld", s->opt_len,
    //        s->static_len));
    /* At this point, opt_len and static_len are the total bit lengths of
     * the compressed block data, excluding the tree representations.
     */

    /* Build the bit length tree for the above two trees, and get the index
     * in bl_order of the last bit length code to send.
     */
    max_blindex = build_bl_tree(s);

    /* Determine the best encoding. Compute the block lengths in bytes. */
    opt_lenb = (s.opt_len + 3 + 7) >>> 3;
    static_lenb = (s.static_len + 3 + 7) >>> 3;

    // Tracev((stderr, "\nopt %lu(%lu) stat %lu(%lu) stored %lu lit %u ",
    //        opt_lenb, s->opt_len, static_lenb, s->static_len, stored_len,
    //        s->last_lit));

    if (static_lenb <= opt_lenb) { opt_lenb = static_lenb; }

  } else {
    // Assert(buf != (char*)0, "lost buf");
    opt_lenb = static_lenb = stored_len + 5; /* force a stored block */
  }

  if ((stored_len + 4 <= opt_lenb) && (buf !== -1)) {
    /* 4: two words for the lengths */

    /* The test buf != NULL is only necessary if LIT_BUFSIZE > WSIZE.
     * Otherwise we can't have processed more than WSIZE input bytes since
     * the last block flush, because compression would have been
     * successful. If LIT_BUFSIZE <= WSIZE, it is never too late to
     * transform a block into a stored block.
     */
    _tr_stored_block(s, buf, stored_len, last);

  } else if (s.strategy === Z_FIXED || static_lenb === opt_lenb) {

    send_bits(s, (STATIC_TREES << 1) + (last ? 1 : 0), 3);
    compress_block(s, static_ltree, static_dtree);

  } else {
    send_bits(s, (DYN_TREES << 1) + (last ? 1 : 0), 3);
    send_all_trees(s, s.l_desc.max_code + 1, s.d_desc.max_code + 1, max_blindex + 1);
    compress_block(s, s.dyn_ltree, s.dyn_dtree);
  }
  // Assert (s->compressed_len == s->bits_sent, "bad compressed size");
  /* The above check is made mod 2^32, for files larger than 512 MB
   * and uLong implemented on 32 bits.
   */
  init_block(s);

  if (last) {
    bi_windup(s);
  }
  // Tracev((stderr,"\ncomprlen %lu(%lu) ", s->compressed_len>>3,
  //       s->compressed_len-7*last));
}

/* ===========================================================================
 * Save the match info and tally the frequency counts. Return true if
 * the current block must be flushed.
 */
function _tr_tally(s, dist, lc)
//    deflate_state *s;
//    unsigned dist;  /* distance of matched string */
//    unsigned lc;    /* match length-MIN_MATCH or unmatched char (if dist==0) */
{
  //var out_length, in_length, dcode;

  s.pending_buf[s.d_buf + s.last_lit * 2]     = (dist >>> 8) & 0xff;
  s.pending_buf[s.d_buf + s.last_lit * 2 + 1] = dist & 0xff;

  s.pending_buf[s.l_buf + s.last_lit] = lc & 0xff;
  s.last_lit++;

  if (dist === 0) {
    /* lc is the unmatched char */
    s.dyn_ltree[lc * 2]/*.Freq*/++;
  } else {
    s.matches++;
    /* Here, lc is the match length - MIN_MATCH */
    dist--;             /* dist = match distance - 1 */
    //Assert((ush)dist < (ush)MAX_DIST(s) &&
    //       (ush)lc <= (ush)(MAX_MATCH-MIN_MATCH) &&
    //       (ush)d_code(dist) < (ush)D_CODES,  "_tr_tally: bad match");

    s.dyn_ltree[(_length_code[lc] + LITERALS + 1) * 2]/*.Freq*/++;
    s.dyn_dtree[d_code(dist) * 2]/*.Freq*/++;
  }

// (!) This block is disabled in zlib defaults,
// don't enable it for binary compatibility

//#ifdef TRUNCATE_BLOCK
//  /* Try to guess if it is profitable to stop the current block here */
//  if ((s.last_lit & 0x1fff) === 0 && s.level > 2) {
//    /* Compute an upper bound for the compressed length */
//    out_length = s.last_lit*8;
//    in_length = s.strstart - s.block_start;
//
//    for (dcode = 0; dcode < D_CODES; dcode++) {
//      out_length += s.dyn_dtree[dcode*2]/*.Freq*/ * (5 + extra_dbits[dcode]);
//    }
//    out_length >>>= 3;
//    //Tracev((stderr,"\nlast_lit %u, in %ld, out ~%ld(%ld%%) ",
//    //       s->last_lit, in_length, out_length,
//    //       100L - out_length*100L/in_length));
//    if (s.matches < (s.last_lit>>1)/*int /2*/ && out_length < (in_length>>1)/*int /2*/) {
//      return true;
//    }
//  }
//#endif

  return (s.last_lit === s.lit_bufsize - 1);
  /* We avoid equality with lit_bufsize because of wraparound at 64K
   * on 16 bit machines and because stored blocks are restricted to
   * 64K-1 bytes.
   */
}

exports._tr_init  = _tr_init;
exports._tr_stored_block = _tr_stored_block;
exports._tr_flush_block  = _tr_flush_block;
exports._tr_tally = _tr_tally;
exports._tr_align = _tr_align;

},{"../utils/common":1}],8:[function(require,module,exports){
'use strict';

// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

function ZStream() {
  /* next input byte */
  this.input = null; // JS specific, because we have no pointers
  this.next_in = 0;
  /* number of bytes available at input */
  this.avail_in = 0;
  /* total number of input bytes read so far */
  this.total_in = 0;
  /* next output byte should be put there */
  this.output = null; // JS specific, because we have no pointers
  this.next_out = 0;
  /* remaining free space at output */
  this.avail_out = 0;
  /* total number of bytes output so far */
  this.total_out = 0;
  /* last error message, NULL if no error */
  this.msg = ''/*Z_NULL*/;
  /* not visible by applications */
  this.state = null;
  /* best guess about the data type: binary or text */
  this.data_type = 2/*Z_UNKNOWN*/;
  /* adler32 value of the uncompressed data */
  this.adler = 0;
}

module.exports = ZStream;

},{}],"/lib/deflate.js":[function(require,module,exports){
'use strict';


var zlib_deflate = require('./zlib/deflate');
var utils        = require('./utils/common');
var strings      = require('./utils/strings');
var msg          = require('./zlib/messages');
var ZStream      = require('./zlib/zstream');

var toString = Object.prototype.toString;

/* Public constants ==========================================================*/
/* ===========================================================================*/

var Z_NO_FLUSH      = 0;
var Z_FINISH        = 4;

var Z_OK            = 0;
var Z_STREAM_END    = 1;
var Z_SYNC_FLUSH    = 2;

var Z_DEFAULT_COMPRESSION = -1;

var Z_DEFAULT_STRATEGY    = 0;

var Z_DEFLATED  = 8;

/* ===========================================================================*/


/**
 * class Deflate
 *
 * Generic JS-style wrapper for zlib calls. If you don't need
 * streaming behaviour - use more simple functions: [[deflate]],
 * [[deflateRaw]] and [[gzip]].
 **/

/* internal
 * Deflate.chunks -> Array
 *
 * Chunks of output data, if [[Deflate#onData]] not overridden.
 **/

/**
 * Deflate.result -> Uint8Array|Array
 *
 * Compressed result, generated by default [[Deflate#onData]]
 * and [[Deflate#onEnd]] handlers. Filled after you push last chunk
 * (call [[Deflate#push]] with `Z_FINISH` / `true` param)  or if you
 * push a chunk with explicit flush (call [[Deflate#push]] with
 * `Z_SYNC_FLUSH` param).
 **/

/**
 * Deflate.err -> Number
 *
 * Error code after deflate finished. 0 (Z_OK) on success.
 * You will not need it in real life, because deflate errors
 * are possible only on wrong options or bad `onData` / `onEnd`
 * custom handlers.
 **/

/**
 * Deflate.msg -> String
 *
 * Error message, if [[Deflate.err]] != 0
 **/


/**
 * new Deflate(options)
 * - options (Object): zlib deflate options.
 *
 * Creates new deflator instance with specified params. Throws exception
 * on bad params. Supported options:
 *
 * - `level`
 * - `windowBits`
 * - `memLevel`
 * - `strategy`
 * - `dictionary`
 *
 * [http://zlib.net/manual.html#Advanced](http://zlib.net/manual.html#Advanced)
 * for more information on these.
 *
 * Additional options, for internal needs:
 *
 * - `chunkSize` - size of generated data chunks (16K by default)
 * - `raw` (Boolean) - do raw deflate
 * - `gzip` (Boolean) - create gzip wrapper
 * - `to` (String) - if equal to 'string', then result will be "binary string"
 *    (each char code [0..255])
 * - `header` (Object) - custom header for gzip
 *   - `text` (Boolean) - true if compressed data believed to be text
 *   - `time` (Number) - modification time, unix timestamp
 *   - `os` (Number) - operation system code
 *   - `extra` (Array) - array of bytes with extra data (max 65536)
 *   - `name` (String) - file name (binary string)
 *   - `comment` (String) - comment (binary string)
 *   - `hcrc` (Boolean) - true if header crc should be added
 *
 * ##### Example:
 *
 * ```javascript
 * var pako = require('pako')
 *   , chunk1 = Uint8Array([1,2,3,4,5,6,7,8,9])
 *   , chunk2 = Uint8Array([10,11,12,13,14,15,16,17,18,19]);
 *
 * var deflate = new pako.Deflate({ level: 3});
 *
 * deflate.push(chunk1, false);
 * deflate.push(chunk2, true);  // true -> last chunk
 *
 * if (deflate.err) { throw new Error(deflate.err); }
 *
 * console.log(deflate.result);
 * ```
 **/
function Deflate(options) {
  if (!(this instanceof Deflate)) return new Deflate(options);

  this.options = utils.assign({
    level: Z_DEFAULT_COMPRESSION,
    method: Z_DEFLATED,
    chunkSize: 16384,
    windowBits: 15,
    memLevel: 8,
    strategy: Z_DEFAULT_STRATEGY,
    to: ''
  }, options || {});

  var opt = this.options;

  if (opt.raw && (opt.windowBits > 0)) {
    opt.windowBits = -opt.windowBits;
  }

  else if (opt.gzip && (opt.windowBits > 0) && (opt.windowBits < 16)) {
    opt.windowBits += 16;
  }

  this.err    = 0;      // error code, if happens (0 = Z_OK)
  this.msg    = '';     // error message
  this.ended  = false;  // used to avoid multiple onEnd() calls
  this.chunks = [];     // chunks of compressed data

  this.strm = new ZStream();
  this.strm.avail_out = 0;

  var status = zlib_deflate.deflateInit2(
    this.strm,
    opt.level,
    opt.method,
    opt.windowBits,
    opt.memLevel,
    opt.strategy
  );

  if (status !== Z_OK) {
    throw new Error(msg[status]);
  }

  if (opt.header) {
    zlib_deflate.deflateSetHeader(this.strm, opt.header);
  }

  if (opt.dictionary) {
    var dict;
    // Convert data if needed
    if (typeof opt.dictionary === 'string') {
      // If we need to compress text, change encoding to utf8.
      dict = strings.string2buf(opt.dictionary);
    } else if (toString.call(opt.dictionary) === '[object ArrayBuffer]') {
      dict = new Uint8Array(opt.dictionary);
    } else {
      dict = opt.dictionary;
    }

    status = zlib_deflate.deflateSetDictionary(this.strm, dict);

    if (status !== Z_OK) {
      throw new Error(msg[status]);
    }

    this._dict_set = true;
  }
}

/**
 * Deflate#push(data[, mode]) -> Boolean
 * - data (Uint8Array|Array|ArrayBuffer|String): input data. Strings will be
 *   converted to utf8 byte sequence.
 * - mode (Number|Boolean): 0..6 for corresponding Z_NO_FLUSH..Z_TREE modes.
 *   See constants. Skipped or `false` means Z_NO_FLUSH, `true` means Z_FINISH.
 *
 * Sends input data to deflate pipe, generating [[Deflate#onData]] calls with
 * new compressed chunks. Returns `true` on success. The last data block must have
 * mode Z_FINISH (or `true`). That will flush internal pending buffers and call
 * [[Deflate#onEnd]]. For interim explicit flushes (without ending the stream) you
 * can use mode Z_SYNC_FLUSH, keeping the compression context.
 *
 * On fail call [[Deflate#onEnd]] with error code and return false.
 *
 * We strongly recommend to use `Uint8Array` on input for best speed (output
 * array format is detected automatically). Also, don't skip last param and always
 * use the same type in your code (boolean or number). That will improve JS speed.
 *
 * For regular `Array`-s make sure all elements are [0..255].
 *
 * ##### Example
 *
 * ```javascript
 * push(chunk, false); // push one of data chunks
 * ...
 * push(chunk, true);  // push last chunk
 * ```
 **/
Deflate.prototype.push = function (data, mode) {
  var strm = this.strm;
  var chunkSize = this.options.chunkSize;
  var status, _mode;

  if (this.ended) { return false; }

  _mode = (mode === ~~mode) ? mode : ((mode === true) ? Z_FINISH : Z_NO_FLUSH);

  // Convert data if needed
  if (typeof data === 'string') {
    // If we need to compress text, change encoding to utf8.
    strm.input = strings.string2buf(data);
  } else if (toString.call(data) === '[object ArrayBuffer]') {
    strm.input = new Uint8Array(data);
  } else {
    strm.input = data;
  }

  strm.next_in = 0;
  strm.avail_in = strm.input.length;

  do {
    if (strm.avail_out === 0) {
      strm.output = new utils.Buf8(chunkSize);
      strm.next_out = 0;
      strm.avail_out = chunkSize;
    }
    status = zlib_deflate.deflate(strm, _mode);    /* no bad return value */

    if (status !== Z_STREAM_END && status !== Z_OK) {
      this.onEnd(status);
      this.ended = true;
      return false;
    }
    if (strm.avail_out === 0 || (strm.avail_in === 0 && (_mode === Z_FINISH || _mode === Z_SYNC_FLUSH))) {
      if (this.options.to === 'string') {
        this.onData(strings.buf2binstring(utils.shrinkBuf(strm.output, strm.next_out)));
      } else {
        this.onData(utils.shrinkBuf(strm.output, strm.next_out));
      }
    }
  } while ((strm.avail_in > 0 || strm.avail_out === 0) && status !== Z_STREAM_END);

  // Finalize on the last chunk.
  if (_mode === Z_FINISH) {
    status = zlib_deflate.deflateEnd(this.strm);
    this.onEnd(status);
    this.ended = true;
    return status === Z_OK;
  }

  // callback interim results if Z_SYNC_FLUSH.
  if (_mode === Z_SYNC_FLUSH) {
    this.onEnd(Z_OK);
    strm.avail_out = 0;
    return true;
  }

  return true;
};


/**
 * Deflate#onData(chunk) -> Void
 * - chunk (Uint8Array|Array|String): output data. Type of array depends
 *   on js engine support. When string output requested, each chunk
 *   will be string.
 *
 * By default, stores data blocks in `chunks[]` property and glue
 * those in `onEnd`. Override this handler, if you need another behaviour.
 **/
Deflate.prototype.onData = function (chunk) {
  this.chunks.push(chunk);
};


/**
 * Deflate#onEnd(status) -> Void
 * - status (Number): deflate status. 0 (Z_OK) on success,
 *   other if not.
 *
 * Called once after you tell deflate that the input stream is
 * complete (Z_FINISH) or should be flushed (Z_SYNC_FLUSH)
 * or if an error happened. By default - join collected chunks,
 * free memory and fill `results` / `err` properties.
 **/
Deflate.prototype.onEnd = function (status) {
  // On success - join
  if (status === Z_OK) {
    if (this.options.to === 'string') {
      this.result = this.chunks.join('');
    } else {
      this.result = utils.flattenChunks(this.chunks);
    }
  }
  this.chunks = [];
  this.err = status;
  this.msg = this.strm.msg;
};


/**
 * deflate(data[, options]) -> Uint8Array|Array|String
 * - data (Uint8Array|Array|String): input data to compress.
 * - options (Object): zlib deflate options.
 *
 * Compress `data` with deflate algorithm and `options`.
 *
 * Supported options are:
 *
 * - level
 * - windowBits
 * - memLevel
 * - strategy
 * - dictionary
 *
 * [http://zlib.net/manual.html#Advanced](http://zlib.net/manual.html#Advanced)
 * for more information on these.
 *
 * Sugar (options):
 *
 * - `raw` (Boolean) - say that we work with raw stream, if you don't wish to specify
 *   negative windowBits implicitly.
 * - `to` (String) - if equal to 'string', then result will be "binary string"
 *    (each char code [0..255])
 *
 * ##### Example:
 *
 * ```javascript
 * var pako = require('pako')
 *   , data = Uint8Array([1,2,3,4,5,6,7,8,9]);
 *
 * console.log(pako.deflate(data));
 * ```
 **/
function deflate(input, options) {
  var deflator = new Deflate(options);

  deflator.push(input, true);

  // That will never happens, if you don't cheat with options :)
  if (deflator.err) { throw deflator.msg || msg[deflator.err]; }

  return deflator.result;
}


/**
 * deflateRaw(data[, options]) -> Uint8Array|Array|String
 * - data (Uint8Array|Array|String): input data to compress.
 * - options (Object): zlib deflate options.
 *
 * The same as [[deflate]], but creates raw data, without wrapper
 * (header and adler32 crc).
 **/
function deflateRaw(input, options) {
  options = options || {};
  options.raw = true;
  return deflate(input, options);
}


/**
 * gzip(data[, options]) -> Uint8Array|Array|String
 * - data (Uint8Array|Array|String): input data to compress.
 * - options (Object): zlib deflate options.
 *
 * The same as [[deflate]], but create gzip wrapper instead of
 * deflate one.
 **/
function gzip(input, options) {
  options = options || {};
  options.gzip = true;
  return deflate(input, options);
}


exports.Deflate = Deflate;
exports.deflate = deflate;
exports.deflateRaw = deflateRaw;
exports.gzip = gzip;

},{"./utils/common":1,"./utils/strings":2,"./zlib/deflate":5,"./zlib/messages":6,"./zlib/zstream":8}]},{},[])("/lib/deflate.js")
});
var Ajax = function (url, data) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", url, true);

        // Send the proper header information along with the request
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status === 201) {
                    resolve(this.response);
                } else {
                    reject(this.response)
                }
            }
        }

        xhr.send(data);
    });
}
var FeedbackValues = function(init_values) {
  var self = this;

  var values = init_values || {};
  consoleLog('Feedback values initialised', values);

  var observers = {};

  this.on_change = false;

  var screenshot, overlays, element_path, paused_css_animations;

  // html_promise is still used even if no screenshot, so default to resolve false
  var html_promise = new Promise(function(resolve) {
    resolve(false);
  });

  function fire_observers(key) {
    if(observers[key]) {
      for(var i = 0; i < observers[key].length; i++) {
        observers[key][i](key, values[key]);
      }
    }

    if(self.on_change) {
      self.on_change();

      do_event_callback('value_change', { field: key,  value: values[key] });
    }
  };

  self.set = function(key, value) {
    if(values[key] !== value) {
      values[key] = value;

      // Save current values in sessionStorage
      sessionStorage.setItem('feedbackValues', JSON.stringify(values));

      fire_observers(key);
    }
  };

  self.unset = function(key) {
    consoleLog('Feedback value removed', key);
    
    delete values[key];

    fire_observers(key);    
  };

  self.get = function(key) {
    return values[key];
  };

  self.observes = function(keys, handler) {
    if(typeof keys === 'string') {
      keys = [keys];
    }

    for(var i = 0; i < keys.length; i++) {
      if(!observers[keys[i]]) {
        observers[keys[i]] = [];
      }

      observers[keys[i]].push(handler);
    }
  };

  self.trigger_observer = function(key) {
    fire_observers(key);
  };

  self.clear_observers = function() {
    observers = {};
  }

  self.get_page_html = function() {
    // Save field values on dom, as it doesn't seem to work on duplicate dom
    // Same for scroll pos as that doesn't work on duplicate dom either
    var all_elements = document.getElementsByTagName('*');

    for(var i = 0, l = all_elements.length; i < l; i++) {
      var element = all_elements[i];

      if(element !== document.body) {
        secret_sauce.save_field_value(element);
        secret_sauce.save_scroll_position(element);
      }
    }

    all_elements = null;

    // Save png version of all canvas elements - also on live dom, as canvas content is not carried over to 
    // duplicate dom
    var canvases  = document.getElementsByTagName('canvas');

    for(i = 0, l = canvases.length; i < l; i++) {
      secret_sauce.canvas_to_png(canvases[i]);
    }

    // pause CSS animations - live dom again, will be undone after dom is duplicated
    paused_css_animations = secret_sauce.pause_css_animations();

    // Duplicate the entire DOM, true on cloneNode means deep copy
    var dd = document.documentElement.cloneNode(true);

    // find any styles that were created with insertRule and manually recreate them in duplicate dom, as they won't be carried over
    if(document.styleSheets && document.styleSheets.length) {
      var stylesheet;
      var dd_stylesheet;
      for(i = 0; i < document.styleSheets.length; i++) {
        stylesheet = document.styleSheets[i];

        if(!stylesheet.href && stylesheet.ownerNode.innerHTML.replace(/ |\r|\n/g, '').length == 0 && stylesheet.cssRules.length > 0) {
          dd_stylesheet = document.createElement('style');
          dd.getElementsByTagName('body')[0].appendChild(dd_stylesheet);

          for(var s = 0; s < stylesheet.cssRules.length; s++) {
            dd_stylesheet.innerHTML += (stylesheet.cssRules[s].cssText || '')+'\n';
          }
        }
      }
    }

    // Remove the saber canvas png stuff from live dom
    for(i = 0, l = canvases.length; i < l; i++) {
      canvases[i].removeAttribute('data-saber-feedback-canvas-png');
    }

    canvases = null;

    // find any nested links and wrap them in object
    var nested_links = dd.querySelectorAll('a a');
    
    for (var j = 0, l = nested_links.length; j < l; j++) {
      var o = document.createElement('object');
      secret_sauce.wrap_element(nested_links[j], o);
    }

    // remove all script tags from duplicate dom
    secret_sauce.remove_elements(dd.getElementsByTagName('script'));

    // remove the Feedback button from duplicate dom
    var feedback_button = dd.querySelectorAll('.saber_feedback_button');
    secret_sauce.remove_elements(feedback_button);
    feedback_button = null;

    // remove autofocus from input fields to stop page scrolling crazyness
    var collection = secret_sauce.get_elements_by_tag_names(['input', 'textarea', 'button'], dd);

    for(j = 0, l = collection.length; j < l; j++) {
      collection[j].removeAttribute('autofocus');
    }

    // remove integrity and crossorigin attributes from link (CSS) tags
    collection = secret_sauce.get_elements_by_tag_names(['link'], dd);
 
    for(j = 0, l = collection.length; j < l; j++) {
      collection[j].removeAttribute('integrity');
      collection[j].removeAttribute('crossorigin');
    }

    collection = null;

    // Make all URLs in duplicate dom absolute
    html_promise = url_utils.relative_to_absolute_urls_in_document(dd).then(function() {
      var html = hot_sauce.document_html(dd);

      secret_sauce.resume_css_animations(paused_css_animations);
      paused_css_animations = [];

      try {
        // don't bother with compression if html is less than 5k chars, or at all on Internet Explorer
        if(navigator.appName === 'Microsoft Internet Explorer' || html.length < 5000) {
          throw('Use raw HTML');
        }

        return(btoa(pako.deflate(html, { to: 'string', level: 1 })));
      }
      catch(e) {
        // all errors revert to saving the raw HTML
        return(html);
      }
    });
  };

  // initially there's no screenshot
  self.has_screenshot = false;

  self.add_screenshot = function(modals) {
    self.has_screenshot = true;
    
    var scroll_pos = secret_sauce.window_scroll_position();

    screenshot = {
      scroll_top: scroll_pos.top,
      scroll_left: scroll_pos.left,
      cookies: settings.get('cookies') || false
    };

    overlays = [];

    for(var i = 0; i < modals.length; i++) {
      overlays.push(modals[i].as_overlay(scroll_pos));
    }
  };

  self.remove_screenshot = function() {
    self.has_screenshot = false;
  };

  self.set_element_path = function(path) {
    element_path = path;
  };

  self.as_json = function() {
    return html_promise.then(function(html) {
      var website_id = window.saberWebsiteConfig.apiKey;
      var viewport_size = secret_sauce.viewport_size();
      var page_size = secret_sauce.page_size();

      var params = {
        feedback_report: {
          url: location.href,
          viewport_width: viewport_size.width,
          viewport_height: viewport_size.height,
          page_height: page_size.height,
          page_width: page_size.width,
          events: event_log.events(),
          plugins: [],
          screenshot_id: null
        },
        feedback_values: values
      }

      if(navigator && navigator.plugins) {
        for(var i = 0; i < navigator.plugins.length; i++) {
          params.feedback_report.plugins.push(navigator.plugins[i].name);
        }
      }

      if(self.has_screenshot && html) {
        params.screenshot = screenshot;
        params.screenshot.html = html;
        // remove element path for now, as it's not working in all browsers
        //params.screenshot.element_path = element_path;
        params.overlays = overlays;
      }

      return(JSON.stringify(params));
    })
  }
};
var KeyboardEventHandlerFactory = new (function() {
  this.build = function(key, key_code, action) {
    return new (function() {
      var handler = function(e) {
        if((e.key && e.key === key) || (!e.key && e.which === key_code)) {
          action(e);
        }
      }

      // start listening, both main window and isolation frame
      window.addEventListener('keydown', handler, true);
      isolation_frame.contentWindow.addEventListener('keydown', handler, true);

      // stop listening
      this.stop = function() {
        window.removeEventListener('keydown', handler, true);
        isolation_frame.contentWindow.removeEventListener('keydown', handler, true);
      }
    });
  }

  // short cuts for common key codes
  this.on_escape = function(action) {
    return this.build('Escape', 27, action);
  }

  this.on_return = function(action) {
    return this.build('Enter', 13, action);
  }

  this.close_on_escape = function() {
    return this.on_escape(self.close);
  }
})();

            // JS classes

/**
relative URLs can be:


[src],[href],[background]
src attribute - image.jpg
href attribute - image.jpg
background attribute - image.jpg

[srcset]
srcset attribute - medium.jpg 1000w, large.jpg 2000w

style,[style]
style attribute - url(image.jpg)
style tag content - url(image.jpg)
*/
var url_utils = new function() {
  var self = this;
  
  // dummy link tag used to turn a relative URL into an absolute one
  var dummy_link  = document.createElement('a');

  // helper to translate blob type urls to data type urls
  // utilises atob and btoa, even though those arent available in IE 9, as blob URLs aren't avaialble in IE 9 either
  self.blob_to_data_url = function(url) {
    var promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest;
      xhr.responseType = 'blob';

      xhr.onload = function() {
        var reader = new FileReader;

        reader.onload = function() {
          resolve(reader.result);
        };

        reader.onerror = function() {
          resolve('#');
        }

        reader.readAsDataURL(xhr.response);
      };

      xhr.onerror = function() {
        resolve('#');
      }

      xhr.open('GET', url);
      xhr.send();
    });

    return promise.then((base64_url) => {
      var css_math = base64_url.match(/^data:text\/css;base64,(.+)/);

      if(css_math) {
        var base64_css = css_math[1];
        var raw_css = atob(base64_css);

        return this.urls_in_string(raw_css).then((fixed_css) => {
          return `data:text/css;base64,${btoa(fixed_css)}`;
        });
      }
      else {
        return base64_url;
      }
    });
  };

  // -------------- process_nodes handlers -------------- \\
  self.relative_to_absolute_url = function(url) {
    var promise = new Promise((resolve, reject) => {
      // if the URL is a blob URL, resolve it to a base64 encoded data URL
      // this will contain the entire content, so no need to make it absolute or add HTTP Auth
      if(url.indexOf('blob:') === 0) {
        this.blob_to_data_url(url).then((absolute_url) => {
          resolve(absolute_url);
        });
      }

      // Normal URL, so make it absolute and add HTTP Auth
      else {
        dummy_link.setAttribute('href', url);

        var absolute_url;

        // use a try/catch here as IE flips out if you try to do .href= on an image tag, even though .href returns the src attr
        try {
          absolute_url = dummy_link.href;
        }
        catch(e) { }

        resolve(absolute_url);
      }
    });

    return promise;
  };

  self.urls_in_string = function(string) {
    var promises = [];

    var matches = string.match(/url\('?"?(.+?)'?"?\)/gi);

    if(matches) {
      for(var i = 0; i < matches.length; i++) {
        var promise = new Promise((resolve, reject) => {
          var match = matches[i];

          var url = match.match(/url\('?"?(.+?)'?"?\)/i)[1];

          this.relative_to_absolute_url(url).then((absolute_url) => {
            var fixed_match = match.replace(url, absolute_url);
            string = string.replace(match, fixed_match);
            resolve();
          })
        });

        promises.push(promise);
      }
    }

    return Promise.all(promises).then(() => {
      return string;
    })
  };

  self.urls_in_srcset = function(srcset) {
    var promises = [];

    // each directive in a srcset is comma, and optionally space, separated
    // eg: medium.jpg 1000w, large.jpg 2000w
    // split them into an array
    var srcset = srcset.split(/, ?/);

    for(var i = 0; i < srcset.length; i++) {
      var promise = new Promise((resolve, reject) => {
        var srcset_index = i;

        // separate the image url from the size directive
        var image_parts = srcset[srcset_index].split(' ');

        this.relative_to_absolute_url(image_parts[0]).then((absolute_url) => {
          image_parts[0] = absolute_url;

          srcset[srcset_index] = image_parts.join(' ');

          resolve();
        });
      });
      
      promises.push(promise);
    }

    return Promise.all(promises).then(() => {
      return srcset.join(', ');
    });
  };
  // -------------- end handlers -------------- \\


  self.process_node = function(node, attribute, handler) {
    return handler.call(this, attribute.get(node)).then((absolute_url) => {
      attribute.set(node, absolute_url);
    });
  }

  self.process_nodes = function(node_list, attribute, handler) {
    var promises = [];
    var node_list_length = node_list.length;

    for (var i = 0; i < node_list_length; i++) {
      promises.push(this.process_node(node_list[i], attribute, handler));
    }

    return promises;
  };

  /**
   * Loops through all attribute type relative urls in the target document and makes them absolute
   * 
   * Returns an array of promises that resolve when the url has been absolutised
   */
  self.relative_to_absolute_urls_in_document = function(target_document) {
    // ----------------- Style attributes ----------------- \\
    var nodes = target_document.querySelectorAll('[style]');

    var attribute = {
      get(node) {
        return node.style.cssText || '';
      },
      set(node, new_value) {
        node.style.cssText = new_value;
      }
    }
    
    var promises = this.process_nodes(nodes, attribute, this.urls_in_string);

    // ----------------- Style tags ----------------- \\
    nodes = target_document.querySelectorAll('style');

    attribute = {
      get(node) {
        if(node.textContent) {
          return node.textContent;
        }
        else {
          return node.innerText || '';
        }
      },
      set(node, new_value) {
        if(node.textContent) {
          node.textContent = new_value;
        }
        else {
          node.innerText = new_value;
        }
      }
    }
    
    promises = promises.concat(this.process_nodes(nodes, attribute, this.urls_in_string));


    // ----------------- Responsive imeage (srcset) ----------------- \\
    nodes = target_document.querySelectorAll('[srcset]');

    attribute = {
      get(node) {
        return node.srcset || '';
      },
      set(node, new_value) {
        node.setAttribute('srcset', new_value);
      }
    }
    
    promises = promises.concat(this.process_nodes(nodes, attribute, this.urls_in_srcset));



    // ----------------- All other URL containing attributes ----------------- \\
    var url_attributes = ['href', 'src', 'background'];

    for(var i = 0; i < url_attributes.length; i++) {
      // Put attribute name in a closure to stop it being changed by the loop before it's needed again
      ((attribute_name) => {
        var attribute = {
          get(node) {
            var value = node[attribute_name];

            if(typeof value === 'string') {
              return value;
            }
            else {
              return '';
            }
          },
          set(node, new_value) {
            node.setAttribute(attribute_name, new_value);
          }
        }

        var nodes = target_document.querySelectorAll(`[${attribute_name}]`);
        
        promises = promises.concat(this.process_nodes(nodes, attribute, this.relative_to_absolute_url));
      })(url_attributes[i]);
    }

    return Promise.all(promises);
  };
}
var hot_sauce = {
  // get doctype as a string
  doctype: function() {
    var doctype = '';
    
    if(document.doctype) {
      doctype = '<!DOCTYPE ';
      doctype += document.doctype.name;
      doctype += document.doctype.publicId ? ' PUBLIC "'+document.doctype.publicId+'"' : '';
      doctype += !document.doctype.publicId && document.doctype.systemId ? ' SYSTEM' : '';
      doctype += document.doctype.systemId ? ' "'+document.doctype.systemId+'"' : '';
      doctype += '>';
    }

    return doctype;
  },

  // get a element's outer HTML
  element_html: function(element) {
    // Use try/catch to return '' on error, as not all node types will work (document is one)
    try {
      // if IE or Chrome use the internal method (node.outerHTML)
      // otherwise wrap the node in a div and use innerHTML on that div
      return element.outerHTML || (function() {
        // create an empty container div
        var div = document.createElement('div');

        // deep clone this node into the continer div
        div.appendChild(element.cloneNode(true));

        // the inner HTML of the container div is now the same as the outer HTML of this
        return div.innerHTML;
      })();
    }
    catch(e) {
      return '';
    }
  },

  document_html: function(document_element) {
    var document_element = document_element || document.documentElement;
    
    return this.doctype() + this.element_html(document_element);
  }
}
var event_log = new function() {
  var self = this;
  var disabled = false;

  this.set_disabled = (new_disabled_value) => {
    disabled = new_disabled_value;

    if(disabled) {
      this.clear_event_log();
    }
  };

  var event_limit = 100;

  this.set_event_limit = (new_event_limit) => {
    event_limit = new_event_limit;
    prune_event_log();
  }

  /**
   * Log new event
   */
  let log_event = (type, data) => {
    if(disabled) {
      return;
    }

    data.timestamp = new Date().toString();
    data.type = type;

    events.push(data);

    prune_event_log();

    // error here probably means the sessionStorage is full, so clear event log
    try {
      save_event_log();
    }
    catch(error) {
      this.clear_event_log();
    }
  }

  this.log_page_load = (method, url) => {
    log_event('page_load', { method: method, url: url });
  }

  this.log_ajax = (method, url) => {
    log_event('ajax', { method: method, url: url });
  }

  this.log_error = (data) => {
    log_event('error', data);
  }

  this.log_feedback = (content) => {
    log_event('feedback', { content: content });
  }

  this.events = () => {
    return events;
  }

  /**
   * Clear the event log, both in this object, and saved
   */
  this.clear_event_log = () => {
    events = [];
    save_event_log();
  }

  /**
   * Save the error log for this session in sessionStorate as IE8 supports it
   */
  let save_event_log = () => {
    window.sessionStorage.saber_feedback_event_log = JSON.stringify(events);
  }

  let prune_event_log = () => {
    let log_length = events.length;
    if(log_length > event_limit) {
      events = events.slice(log_length - event_limit);
      save_event_log();
    }
  }

  /**
   * constructor
   */
  if(window.sessionStorage.saber_feedback_event_log == undefined || window.sessionStorage.saber_feedback_event_log == '') {
    window.sessionStorage.saber_feedback_event_log = '[]';
  }
    
  let events = JSON.parse(window.sessionStorage.saber_feedback_event_log);

  /**
   * Log AJAX requests
   */
  (function() {
    var proxied = window.XMLHttpRequest.prototype.open;

    window.XMLHttpRequest.prototype.open = function() {
      event_log.log_ajax(arguments[0], arguments[1]);
      
      return proxied.apply(this, [].slice.call(arguments));
    };
  })();

  /**
   * Log Errors
   */
  // save any existing error hander
  let _on_error = window.onerror;

  window.onerror = (message, url, line, column, error) => {
    let stack_trace;

    message = secret_sauce.default_value(message, '');
    url = secret_sauce.default_value(url, '(anonymous function)');
    line = secret_sauce.default_value(line, false);
    column = secret_sauce.default_value(column, false);
    error = secret_sauce.default_value(error, false);

    if(error) {
      stack_trace = error.stack;
    }

    this.log_error({ message: message, url: url, line: line, column: column, stack_trace: stack_trace });

    if(_on_error) {
      _on_error(message, url, line, column, error);
    }
  }

  // Log this page load
  this.log_page_load('GET', location.href);
}
var settings = new function () {
    var self = this;

    // default settings
    var settings = {
        // from DB
        language: 'en',
        position: 'right',
        style: 'label',
        background_color: '#222',
        text_color: '#fff',
        hide_button: false,
        label_text: false,
        require_screenshot: window.saberWebsiteConfig.requireScreenshot,
        no_highlights: false,
        auto_open: false,
        fields: window.saberWebsiteConfig.fields,

        // runtime only
        feedback_values: {},
        display_screenshot_button: true,
        cookies: {},
        show_help: true,
        show_thanks: true,
        suppress_localhost_warning: false,
        disable_event_log: false,
        event_log_max_length: 100,

        // callbacks
        on_ready: function () {
        },        // when widget is ready to be used (button added to page)
        on_open: function () {
        },         // when feedback is clicked
        on_complete: function () {
        },     // when feedback report is submitted. called before on_close
        on_cancel: function () {
        },       // when widget is closed without submitting a report, called before on_close
        on_close: function () {
        },        // when widget is closed, called regardless of whether the report was submitted or canceled
        on_value_change: function () {
        }  // when any value on the field is changed
    };

    function is_hash(hash) {
        // don't return true for arrays, but only if isArray exists
        return typeof hash === 'object' && hash !== null && (!Array.isArray || !Array.isArray(hash));
    }

    function deep_merge(target, options) {
        var i;

        for (i in options) {
            var option = options[i];

            if (is_hash(option)) {
                if (!is_hash(target[i])) {
                    target[i] = {};
                }

                deep_merge(target[i], option);
            } else {
                // special case for language, as it's not set here
                if (i === 'language' && settings.language !== option) {
                    i18n.set_current_locale(option);
                }

                if (target === settings.feedback_values) {
                    // LEGACY - old underscore prefixed value names
                    if (i.indexOf('_') === 0) {
                        if (i === '_nps') {
                            i = 'NPS rating';
                        } else if (i === '_rating') {
                            if (typeof option === 'number') {
                                i = 'Star rating';
                            } else if (option === 'Good' || option === 'Bad') {
                                i = 'Thumb rating';
                            } else {
                                i = 'Emoji rating';
                            }
                        } else {
                            // remove the underscore and capitalise first letter
                            i = i[1].toUpperCase() + i.slice(2);
                        }
                    }

                    // Update the FeedbackValues object, which will in turn update the form
                    if (values) {
                        values.set(i, option);
                    }
                }

                target[i] = option;
            }
        }
    };

    self.get = function (key) {
        return settings[key];
    };

    // param is hash, or key, value:
    // {thing:'stuff'} or 'thing', 'stuff'
    self.set = function () {
        switch (arguments.length) {
            case 1:
                deep_merge(settings, arguments[0]);
                break;

            case 2:
                var options = {};
                options[arguments[0]] = arguments[1];

                deep_merge(settings, options);
                break;
        }
    };

    self.get_all = function () {
        return settings;
    };
}
var state_machine = new function() {
  var current_state, last_state, last_params;

  var states = {};

  var self = this;

  self.register_state = function(state, name) {
    consoleLog('Registering State: ' + name);
    states[name] = state;
  }

  self.change = function(new_state, params) {
    // make sure requested state has been registerd
    if(states[new_state]) {
      if(settings.get('demo_mode')) {
        return false;
      }

      if(states[current_state]) {
        states[current_state].leave();
      }

      last_state = current_state;
      current_state = new_state;

      last_params = params || {};
  
      consoleLog('State changed to', new_state, last_params);
      states[new_state].enter(last_state, last_params);
    }
  }

  // reload the current state, using the same last_state and params from the previous entry
  self.reload_state = function() {
    // don't do anything if not currently in a valid state
    if(states[current_state]) {
      consoleLog('Reloading current State', current_state, last_params);
      var state = states[current_state];

      state.leave();

      state.enter(last_state, last_params);
    }
  }

  self.get_current_state = function() {
    return current_state;
  }
}

var secret_sauce = {
  default_value: function(value, fallback) {
    if(typeof value === 'undefined') {
      return fallback;
    }

    return value;
  },

  // Make sure current field vlaue is set as an attribute
  save_field_value: function(element) {
    var tag_name = String(element.tagName).toLowerCase();

    // checkboxes, radios and textboxes
    if (tag_name == 'input') {
      var type = String(element.getAttribute('type')).toLowerCase();

      // checkboxes and radio buttons, set the checked attribut in HTML
      if(type == 'checkbox' || type == 'radio') {
        if(element.checked) {
          element.setAttribute('checked', 'checked');
        } 
        else {
          element.removeAttribute('checked');
        }
      }

      // set the value attribute in HTML for text fields
      // don't do this for password or credit card number / verification fields
      // CC regex taken from https://code.google.com/p/chromium/codesearch#chromium/src/components/autofill/core/browser/autofill_regex_constants.cc
      else if(type != 'password') {
        var field_name = String(element.getAttribute('name')).toLowerCase();
        var cc_number_regex = /(card|cc|acct).?(number|#|no|num)|nummer|credito|numero|número|numéro|カード番号|Номер.*карты|信用卡号|信用卡号码|信用卡卡號|카드/g
        var cc_verification_regex = /verification|card identification|security code|card code|cvn|cvv|cvc|csc|cvd|cid|ccv/g

        if(!cc_number_regex.test(field_name) && !cc_verification_regex.test(field_name)) {
          element.setAttribute('value', element.value);
        }
      }
    }

    // textareas set the inner text in HTML
    else if(tag_name == 'textarea') {
      var text = document.createTextNode(element.value);
      element.innerHTML = '';
      element.appendChild(text);
      text = null;
    }

    // select lists - work on the option sub-elements, set selected attribute in HTML
    else if(tag_name == 'option') {
      if(element.selected) {
        element.setAttribute('selected', 'selected');
      }

      else {
        element.removeAttribute('selected');
      }
    }
  },

  /**
   * Get the size of the view port
   */
  viewport_size: function() {
    // document.documentElement.clientX returns the viewport size excluding scrollbars.
    // if not available, window.innerX returns the viewport size including the scrollbars.
    // document.body.clientX is a last option if nothing else works. It returns the size of the entire page.
    return {
      width: document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth,
      height: document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight
    };   
  },

  /** 
   * Get the size of the page
   */
  page_size: function() {
    var doc_element_max = { width: 0, height: 0 };
    if(document.documentElement) {
      doc_element_max.width = Math.max(document.documentElement.clientWidth, document.documentElement.scrollWidth, document.documentElement.offsetWidth);
      doc_element_max.height = Math.max(document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    }

    var doc_body_max = { width: 0, height: 0 }; 
    if(document.body) {
      doc_body_max.width = Math.max(document.body.scrollWidth, document.body.offsetWidth);
      doc_body_max.height = Math.max(document.body.scrollHeight, document.body.offsetHeight);
    }

    return { width: Math.max(doc_element_max.width, doc_body_max.width), height: Math.max(doc_element_max.height, doc_body_max.height) };
  },
    
  /**
   * Fit element to the view port
   * 
   * @param element - the element to be fitted
   */
  fit_element_to_viewport: function(element) {
    if(element) {
      // get viewport width and height
      var viewport_size = this.viewport_size();
      
      element.style.position = 'fixed';
      element.style.width = viewport_size.width+"px";
      element.style.height = viewport_size.height+"px";
      element.style.top = 0;
      element.style.left = 0;
      element.style.right = null;
      element.style.bottom = null;
    }
  },

  preventDefault: function(e) {
    e = e || window.event;
    
    if (e.preventDefault) {
      e.preventDefault();
    }

    e.returnValue = false;  
  },

  /**
   * If the element is scrolled, add the scroll position to it in data attributes
   */
  save_scroll_position: function(element) {
    var scroll = { top: 0, left: 0 };
    var tag_name = String(element.tagName).toLowerCase();

    if(tag_name == 'body') {
      scroll = this.window_scroll_position();
    }
    else {
      scroll.top = element.scrollTop;
      scroll.left = element.scrollLeft;
    }

    if(scroll.top || scroll.left) {
      element.setAttribute('data-saber-feedback-scroll', scroll.left+'x'+scroll.top);
    }
    // if both are 0, make sure any previously saved scroll positions are removed
    else {
      element.removeAttribute('data-saber-feedback-scroll');
    }
  },

  /**
   * Get the scroll position of the document, there are three different methods to get the scroll position: 
   *     window.page[X|Y]Offset, document.documentElement.scroll[Left|Top], document.body.scroll[Left|Top]
   * They can return the position, be undefined, or return 0 even when they should return an int.
   */
  window_scroll_position: function() {
    // comparison function for the three values
    function filter_bad_values(value_array) {
      // find the smallest non-zero value, or zero if there aren't any
      var best_value = 0;
      
      for(var i = 0; i < value_array.length; i++) {
        if(typeof value_array[i] != 'undefined') {
          if((value_array[i] && value_array[i] < best_value) || best_value == 0) {
            best_value = value_array[i];
          }
        }
      }

      return best_value;
    }

    return { 
      top: filter_bad_values([window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop]),
      left: filter_bad_values([window.pageXOffset, document.documentElement.scrollLeft, document.body.scrollLeft])
    };
  },

  swap_elements: function(old_element, new_element) {
    old_element.parentNode.replaceChild(new_element, old_element);
  },

  wrap_element: function(wrap_this, with_this) {
    wrap_this.parentNode.insertBefore(with_this, wrap_this);

    with_this.appendChild(wrap_this);
  },

  remove_elements: function(element_list) {
    if(!element_list) return;

    element_list = this.force_array(element_list);

    for(var i = 0, l = element_list.length; i < l; i++) {
      // always use index zero, as removing the element from the dom also shifts it from the array
      // make sure element's parentNode exists (ie: element is in DOM)
      if(element_list[0].parentNode) {
        element_list[0].parentNode.removeChild(element_list[0]);
      }
    }
  },

  /**
   * Get element by ID from within the specified node, useful when working on a duplicated DOM
   * that doesn't have a document root, so no native getElementById method
   */
  get_element_by_id_in: function(element, id, tag_name) {
    var tag_name = tag_name || '*';

    var suspects = element.getElementsByTagName(tag_name);

    for(var i = 0, l = suspects.length; i < l; i++) {
      if(suspects[i].getAttribute('id') == id) {
        return suspects[i];
      }
    }

    return null;
  },

  /**
   * Get a collection of elements based on an array of tag names, eg: ['p', 'a'] will get all paragraphs and links
   */
  get_elements_by_tag_names: function(tag_names, target_document=document) {
    var elements = [];

    for(var i = 0; i < tag_names.length; i++) {
      var tags = target_document.getElementsByTagName(tag_names[i]);

      for(var j = 0; j < tags.length; j++) {
        elements.push(tags[j]);
      }
    }

    return elements;
  },

  /**
   * Return an array containing the parameter, unless parameter is already an array/hash, in which case
   * just return param
   */
  force_array: function(to_be_forced) {
    if(!to_be_forced[0]) {
      return [to_be_forced];
    }

    return to_be_forced;
  },

  /**
   * Add a data-saber-feedback-canvas-png attribute containing the dataUrl representation of the canvas image
   */
  canvas_to_png: function(canvas) {
    if(canvas.toDataURL) {
      // some canvasses can't be exported (such as Google streetview) due to cors, so allow for that
      try {
        canvas.setAttribute('data-saber-feedback-canvas-png', canvas.toDataURL());
      }
      catch(e) {}
    }
  },

  /**
   * Check to see if a CSS property is animatable
   */
  is_property_animatable(property) {
    let animatable = ['background', 'background-color', 'background-position', 'background-size', 'border', 'border-bottom', 'border-bottom-color', 'border-bottom-left-radius', 'border-bottom-right-radius', 'border-bottom-width', 'border-color', 'border-left', 'border-left-color', 'border-left-width', 'border-right', 'border-right-color', 'border-right-width', 'border-spacing', 'border-top', 'border-top-color', 'border-top-left-radius', 'border-top-right-radius', 'border-top-width', 'bottom', 'box-shadow', 'clip', 'color', 'column-count', 'column-gap', 'column-rule', 'column-rule-color', 'column-rule-width', 'column-width', 'columns', 'filter', 'flex', 'flex-basis', 'flex-grow', 'flex-shrink', 'font', 'font-size', 'font-size-adjust', 'font-stretch', 'font-weight', 'height', 'left', 'letter-spacing', 'line-height', 'margin', 'margin-bottom', 'margin-left', 'margin-right', 'margin-top', 'max-height', 'max-width', 'min-height', 'min-width', 'opacity', 'order', 'outline', 'outline-color', 'outline-offset', 'outline-width', 'padding', 'padding-bottom', 'padding-left', 'padding-right', 'padding-top', 'perspective', 'perspective-origin', 'right', 'text-decoration-color', 'text-indent', 'text-shadow', 'top', 'transform', 'transform-origin', 'vertical-align', 'visibility', 'width', 'word-spacing', 'z-index'];

    for(let i = 0, l = animatable.length; i < l; i++) {
      if(property === animatable[i]) {
        return true;
      }
    }

    return false;
  },

  /**
   * Pause any running CSS animations, return an array of paused elements so that they may be resumed later
   */
  pause_css_animations() {
    if(!('getComputedStyle' in window)) {
      return false;
    }

    // use feature detection to figure out which (if any) vendor prefix
    let vender_prefixes = ['', '-webkit-', '-moz-', '-o-'];
    let vender_prefixes_length = vender_prefixes.length;

    let vender_prefix = false;

    let a_style = document.createElement('a').style;
    
    for(let i = 0; i < vender_prefixes_length; i++) {
      a_style.setProperty(`${vender_prefixes[i]}animation-duration`, '42s');
      let test_prop_value = a_style.getPropertyValue(`${vender_prefixes[i]}animation-duration`);

      if(test_prop_value && test_prop_value == '42s') {
        vender_prefix = vender_prefixes[i];

        // break the loop
        i = vender_prefixes_length;
      }
    }

    // browser doesn't support animation, so no point looking
    if(vender_prefix === false) {
      return false;
    }

    let all_elements = document.getElementsByTagName('*');
    let all_elements_length = all_elements.length;

    let paused_elements = [];

    for(var i = 0; i < all_elements_length; i++) {
      let element = all_elements[i];
      let element_style = getComputedStyle(element);

      if(parseFloat(element_style.getPropertyValue(`${vender_prefix}animation-duration`)) > 0) {
        // save the original element style so that it can be reinstated later
        element.setAttribute('data-saber-feedback-original-style', element.style.cssText);
        
        for(let j = 0, l = element_style.length; j < l; j++) {
          let style_property = element_style.item(j);

          // only save animatable properties
          if(this.is_property_animatable(style_property)) {
            let style_value = element_style.getPropertyValue(style_property);
              
            element.style.setProperty(style_property, style_value, 'important');
          }
        }

        element.style.setProperty(`${vender_prefix}animation-play-state`, 'paused', 'important');

        paused_elements.push(element);
      }
    }

    return paused_elements;
  },

  /**
   * Resume paused CSS animations, requires an array of elements
   */
  resume_css_animations(paused_elements) {
    for(let i = 0, l = paused_elements.length; i < l; i++) {
      let element = paused_elements[i];

      element.setAttribute('style', element.getAttribute('data-saber-feedback-original-style'));

      element.removeAttribute('data-saber-feedback-original-style');
    }
  }
}

var translations = {};
// Bosnian
translations.ba = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Kategorije informacija",
                "Email": "Email adresa",
                "Opt in": "Želite li biti obaviješteni o ažuriranjima za  {{website}}?",
                "Message": "Poruka",
                "Name": "Ime",
                "Subject": "Predmet",
                "Net Promoter Score": "Kolika je vjerovatnoća da ćete preporučiti {{website}} prijatlju ili kolegi?",
                "Emoji rating": "Šta mislite o {{website}}?",
                "Thumb rating": "Šta mislite o {{website}}?",
                "Star rating": "Šta mislite o  {{website}}?",
                "Priority": "Prioritet",
                "Speed": "Koliko brzo se učitala ova stranica?"
            },
            "options": {
                "Category":{
                    "Question": "Pitanje",
                    "Problem": "Problem",
                    "Suggestion": "Prijedlog",
                    "Compliment": "Pohvala",
                    "Typo": "Pravopis ili gramatika",
                    "Other": "Ostalo"
                },
                "Emoji rating": {
                    "Hate": "Ne volim ovo",
                    "Dislike": "Ne sviđa mi se",
                    "Neutral": "Neutralno",
                    "Like": "Sviđa mi se",
                    "Love": "Volim ovo"
                },
                "Thumb rating": {
                    "Good": "Dobro",
                    "Bad": "Loše"
                },
                "Priority": {
                    "Low": "Nizak",
                    "Normal": "Normalan",
                    "High": "Visok",
                    "Urgent": "Hitno"
                },
                "Speed": {
                    "Fast": "Brzo",
                    "Normal": "Normalno",
                    "Slow": "Sporo"
                }
            }
        },
        "dropdown_please_choose": " - Molimo izaberite - ",
        "screenshot_added": "Screenshot snimljen",
        "required": "Obavezno",
        "powered_by": "Powered by"
    },
    "screenshot":{
        "info": "Kliknite na dio stranice za koji želite poslati povratnu informaciju",
        "touch_info": "Odaberite dio na stranici na koji se odnosi povratna informacija",
        "classic_info": "Kliknite i povucite za označavanje bilo kojeg dijela stranice koja se odnosi na povratnu informaciju."
    },
    "feedback_button": "Povratna informacija",
    "loading": "Učitavanje...",
    "complete": "Zahvaljujemo na povratnoj informaciji!",
    "errors": {
        "localhost_warning": {
            "title": "Upozorenje: Koristite Saber na localhost okruženju",
            "body": [
                "Saber mora imati pristup fotografijama website-a , CSS dokumentima, i prilagođenim fontovima za izradu fotografija zaslona. Možte nastaviti koristiti Saber na localhostu, ali fotografije zaslona neće biti ispravno prikazane, i u nekim slučajevima,povratna informacija neće biti proslijeđena u potpunosti.",
                "Ako želite koristiti Saber prije dodadavanja na aktivnu stranicu, najbolje je da koristite, ili online staging server, ili localhost servis kao što je ngrok.io",
                "Možete zaustavtti prikazivanje ove poruke dodavanjem <code>suppress_localhost_warning:&nbsp;true</code> vašem embed kodu, više informacija možete pronaći <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Izvinjavamo se, nesto je učinjeno pogrešno. Molimo pokušajte ponovo.",
        "screenshot_requried": "Molimo dodajte screenshot",
        "field_invalid": {
            "required": "Ovo polje je obavezno",
            "email": "E-mail adresa nije validna",
            "url": "URL nije validan"
        }
    },
    "buttons": {
        "ok":"OK",
        "submit": "Pošalji feedback",
        "add_screenshot": "Dodaj screenshot",
        "edit_screenshot": "Uredi screenshot",
        "cancel": "Otkaži",
        "complete": "Vrati se na Website"
    },
    "promo": {
        "title": "Feedback alat omogućen od",
        "content": "Dodajte ovaj alat na Vašu web stranicu!"
    }
}
// Czech
translations.cz = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Kategorie zpětné vazby",
                "Email": "E-mailová adresa",
                "Message": "Zpráva",
                "Name": "Jméno",
                "Subject": "Předmět",
                "Priority": "Priorita",
                "Speed": "Jak rychle se tato stránka načetla?",
                "Net Promoter Score": "S jakou pravděpodobností byste doporučili stránku {{website}} příteli nebo kolegovi?",
                "Emoji rating": "Co si myslíte o {{website}}?",
                "Thumb rating": "Co si myslíte o {{website}}?",
                "Star rating": "Co si myslíte o {{website}}?",
                "Opt in": "Chtěli byste od {{website}} dostávat informace o novinkách?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Nenávidím",
                    "Dislike": "Nelíbí se mi",
                    "Neutral": "Neutrální",
                    "Like": "Líbí se mi",
                    "Love": "Miluji"
                },
                "Thumb rating": {
                    "Good": "Dobře",
                    "Bad": "Špatně"
                },
                "Category": {
                    "Question": "Otázka",
                    "Problem": "Problém",
                    "Suggestion": "Návrh",
                    "Compliment": "Kompliment",
                    "Typo": "Pravopis nebo gramatika",
                    "Other": "Další"
                },
                "Priority": {
                    "Low": "Nízká",
                    "Normal": "Normální",
                    "High": "Vysoká",
                    "Urgent": "Urgentní"
                },
                "Speed": {
                    "Fast": "Rychle",
                    "Normal": "Normálně",
                    "Slow": "Pomalu"
                }
            }
        },
        "dropdown_please_choose": " - Vyberte prosím - ",
        "screenshot_added": "Snímek obrazovky přidán",
        "required": "povinné",
        "powered_by": "Powered by"
    },
    "screenshot": {
        "info": "Klikněte na stránce na položku, která se týká vaší zpětné vazby",
        "touch_info": "Klepněte na stránce na položku, která se týká vaší zpětné vazby",
        "classic_info": "Klikněte a tažením zvýrazněte jakékoli části této stránky, které se vztahují k vaší zpětné vazbě."
    },
    "feedback_button": "Zpětná vazba",
    "loading": "Načítání...",
    "complete": "Děkujeme za zpětnou vazbu!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Omlouváme se, něco se pokazilo. Zkuste to prosím později.",
        "screenshot_requried": "Přidejte prosím snímek obrazovky",
        "field_invalid": {
            "email": "Nejde o platnou e-mailovou adresu",
            "url": "Nejde o platnou adresu URL",
            "required": "Toto pole je povinné"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Odeslat zpětnou vazbu",
        "add_screenshot": "Přidat snímek obrazovky",
        "edit_screenshot": "Změnit snímek obrazovky",
        "cancel": "Zrušit",
        "complete": "Zavřít"
    },
    "promo": {
        "title": "Zpětnou vazbu zajišťuje",
        "content": "Získejte nástroj na <strong>zpětnou</strong> vazbu pro vlastní stránky ještě dnes!"
    }
}
// German
translations.de = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Feedback-Kategorie",
                "Email": "E-Mail-Adresse",
                "Message": "Nachricht",
                "Name": "Name",
                "Subject": "Betreff",
                "Priority": "Priorität",
                "Speed": "Wie schnell wurde die Seite geladen?",
                "Net Promoter Score": "Wie wahrscheinlich ist es, dass Sie {{website}} einem Freund oder Kollegen empfehlen werden?",
                "Emoji rating": "Was denken Sie über {{website}}?",
                "Thumb rating": "Was denken Sie über {{website}}?",
                "Star rating": "Was denken Sie über {{website}}?",
                "Opt in": "Möchten Sie aktuelle Informationen von {{website}} erhalten?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Gefällt mir gar nicht",
                    "Dislike": "Gefällt mir weniger gut",
                    "Neutral": "Neutral",
                    "Like": "Gefällt mir gut",
                    "Love": "Gefällt mir sehr gut"
                },
                "Thumb rating": {
                    "Good": "Gut",
                    "Bad": "Schlecht"
                },
                "Category": {
                    "Question": "Frage",
                    "Problem": "Problem",
                    "Suggestion": "Anregung",
                    "Compliment": "Kompliment",
                    "Typo": "Rechtschreibung oder Grammatik",
                    "Other": "Sonstiges"
                },
                "Priority": {
                    "Low": "Niedrig",
                    "Normal": "Normal",
                    "High": "Hoch",
                    "Urgent": "Dringend"
                },
                "Speed": {
                    "Fast": "Schnell",
                    "Normal": "Normal",
                    "Slow": "Langsam"
                }
            }
        },
        "dropdown_please_choose": " - Bitte wählen Sie - ",
        "screenshot_added": "Screenshot hinzugefügt",
        "required": "erforderlich",
        "powered_by": "Powered by"
    },
    "screenshot": {
        "info": "Klicken Sie das Element auf der Seite, auf das sich Ihr Feedback bezieht",
        "touch_info": "Berühren Sie das Element auf der Seite, auf das sich Ihr Feedback bezieht",
        "classic_info": "Markieren Sie die Abschnitte dieser Website, auf die sich Ihr Feedback bezieht."
    },
    "feedback_button": "Rückmeldung",
    "loading": "Lädt ...",
    "complete": "Vielen Dank für das Feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Es tut uns leid, da ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.",
        "screenshot_requried": "Bitte füge einen Screenshot hinzu",
        "field_invalid": {
            "email": "Die E-Mail-Adresse ist ungültig",
            "url": "Die URL ist ungültig",
            "required": "Dies ist ein Pflichtfeld."
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Feedback senden",
        "add_screenshot": "Screenshot hinzufügen",
        "edit_screenshot": "Screenshot ändern",
        "cancel": "Abbrechen",
        "complete": "Schließen"
    },
    "promo": {
        "title": "Feedback präsentiert von",
        "content": "Holen Sie sich noch heute dieses Programm für Feedback zu Ihrer Webseite!"
    }
}
// Danish
translations.dk = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Tilbagemeldingskategori",
                "Email": "E-mailadresse",
                "Message": "Besked",
                "Name": "Navn",
                "Subject": "Emne",
                "Priority": "Prioritet",
                "Speed": "Hvor hurtigt blev denne side indlæst?",
                "Net Promoter Score": "Hvor stor er sandsynligheden for at du vil anbefale {{website}} til en ven eller kollega?",
                "Emoji rating": "Hvad synes du om {{website}}?",
                "Thumb rating": "Hvad synes du om {{website}}?",
                "Star rating": "Hvad synes du om {{website}}?",
                "Opt in": "Vil du gerne modtage opdateringer fra {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Hader",
                    "Dislike": "Synes ikke om",
                    "Neutral": "Neutral",
                    "Like": "Synes godt om",
                    "Love": "Elsker"
                },
                "Thumb rating": {
                    "Good": "God",
                    "Bad": "Dårlig"
                },
                "Category": {
                    "Question": "Spørgsmål",
                    "Problem": "Problem",
                    "Suggestion": "Forslag",
                    "Compliment": "Kompliment",
                    "Typo": "Stavning eller grammatik",
                    "Other": "Andet"
                },
                "Priority": {
                    "Low": "Lav",
                    "Normal": "Normal",
                    "High": "Høj",
                    "Urgent": "Haster"
                },
                "Speed": {
                    "Fast": "Hurtig",
                    "Normal": "Normal",
                    "Slow": "Langsom"
                }
            }
        },
        "dropdown_please_choose": " - Vælg venligst - ",
        "screenshot_added": "Skærmbillede tilføjet",
        "required": "påkrævet",
        "powered_by": "Drevet af"
    },
    "screenshot": {
        "info": "Tryk den genstand på siden, der relaterer sig til din feedback",
        "touch_info": "Rør den genstand på siden, der relaterer sig til din feedback",
        "classic_info": "Klik og træk for at fremhæve dele af denne side som relaterer sig til din tilbagemelding."
    },
    "feedback_button": "Tilbagemelding",
    "loading": "Henter...",
    "complete": "Tak for tilbagemeldingen!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "We're sorry, something has gone wrong. Please try again later.",
        "screenshot_requried": "Tilføj venligst et skærmbillede",
        "field_invalid": {
            "email": "Dette er ikke en gyldig email-adresse",
            "url": "Dette er ikke en gyldig webadresse",
            "required": "Dette felt er påkrævet"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Send tilbagemelding",
        "add_screenshot": "Tilføj skærmbillede",
        "edit_screenshot": "Skift skærmbillede",
        "cancel": "Annuller",
        "complete": "Luk"
    },
    "promo": {
        "title": "Tilbagemelding drevet af",
        "content": "Få dit værktøj til hjemmesidetilbagemelding i dag!"
    }
}
// English
translations.en = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Feedback category",
                "Email": "Email address",
                "Opt in": "Would you like to receive updates from {{website}}?",
                "Message": "Message",
                "Name": "Name",
                "Subject": "Subject",
                "Net Promoter Score": "How likely are you to recommend {{website}} to a friend or colleague?",
                "Emoji rating": "What do you think of {{website}}?",
                "Thumb rating": "What do you think of {{website}}?",
                "Star rating": "What do you think of {{website}}?",
                "Priority": "Priority",
                "Speed": "How fast did this page load?"
            },
            "options": {
                "Category": {
                    "Question": "Question",
                    "Problem": "Problem",
                    "Suggestion": "Suggestion",
                    "Compliment": "Compliment",
                    "Typo": "Spelling or grammar",
                    "Other": "Other"
                },
                "Emoji rating": {
                    "Hate": "Hate",
                    "Dislike": "Dislike",
                    "Neutral": "Neutral",
                    "Like": "Like",
                    "Love": "Love"
                },
                "Thumb rating": {
                    "Good": "Good",
                    "Bad": "Bad"
                },
                "Priority": {
                    "Low": "Low",
                    "Normal": "Normal",
                    "High": "High",
                    "Urgent": "Urgent"
                },
                "Speed": {
                    "Fast": "Fast",
                    "Normal": "Normal",
                    "Slow": "Slow"
                }
            }
        },
        "dropdown_please_choose": " - Please choose - ",
        "screenshot_added": "Screenshot added",
        "required": "required",
        "powered_by": "Powered by"
    },
    "screenshot": {
        "info": "Click the item on the page that relates to your feedback",
        "touch_info": "Touch the item on the page that relates to your feedback",
        "classic_info": "Click and drag to highlight any parts of this page that relate to your feedback."
    },
    "feedback_button": "Feedback",
    "loading": "Loading...",
    "complete": "Thanks for the feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber Feedback on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning:&nbsp;true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Something has gone wrong. Please try again later.",
        "screenshot_requried": "Please add a screenshot",
        "field_invalid": {
            "required": "This field is required",
            "email": "This is not a valid email address",
            "url": "This is not a valid URL"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Send feedback",
        "add_screenshot": "Add screenshot",
        "edit_screenshot": "Edit screenshot",
        "cancel": "Cancel",
        "complete": "Close"
    },
    "promo": {
        "title": "Feedback powered by",
        "content": "Get your own website feedback tool today!"
    }
}
// Spanish
translations.es = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Categoría de comentarios",
                "Email": "Correo electrónico",
                "Message": "Mensaje",
                "Name": "Nombre",
                "Subject": "Asunto",
                "Priority": "Prioridad",
                "Speed": "¿A qué velocidad se ha cargado esta página?",
                "Net Promoter Score": "¿Qué probabilidades hay de que recomiende {{website}} a un amigo o colega?",
                "Emoji rating": "¿Qué opinión le merece {{website}}?",
                "Thumb rating": "¿Qué opinión le merece {{website}}?",
                "Star rating": "¿Qué opinión le merece {{website}}?",
                "Opt in": "¿Desea recibir actualizaciones de {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Repulsa",
                    "Dislike": "Desagrado",
                    "Neutral": "Neutral",
                    "Like": "Agrado",
                    "Love": "Sumo agrado"
                },
                "Thumb rating": {
                    "Good": "Buena",
                    "Bad": "Mala"
                },
                "Category": {
                    "Question": "Pregunta",
                    "Problem": "Problema",
                    "Suggestion": "Sugerencia",
                    "Compliment": "Cumplido",
                    "Typo": "Ortografía o Gramática",
                    "Other": "Otro"
                },
                "Priority": {
                    "Low": "Baja",
                    "Normal": "Normal",
                    "High": "Alta",
                    "Urgent": "Urgente"
                },
                "Speed": {
                    "Fast": "Rápido",
                    "Normal": "Normal",
                    "Slow": "Lento"
                }
            }
        },
        "dropdown_please_choose": " - Elige - ",
        "screenshot_added": "Se ha añadido la captura de pantalla",
        "required": "obligatorio",
        "powered_by": "Respaldado por"
    },
    "screenshot": {
        "info": "Pulsa el elemento de la página que guarde relación con tus comentarios",
        "touch_info": "Toca el elemento de la página que guarde relación con tus comentarios",
        "classic_info": "Haz clic y arrastra para resaltar las partes de esta página relacionadas con tus comentarios."
    },
    "feedback_button": "Observación",
    "loading": "Cargando...",
    "complete": "¡Gracias por los comentarios!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Lo sentimos, algo ha ido mal. Inténtalo de nuevo más tarde",
        "screenshot_requried": "Añade una captura de pantalla",
        "field_invalid": {
            "email": "Esta dirección de correo electrónico no es válida",
            "url": "Esta URL no es válida",
            "required": "Este campo es obligatorio"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Enviar comentarios",
        "add_screenshot": "Añade la captura de pantalla",
        "edit_screenshot": "Cambia la captura de pantalla",
        "cancel": "Cancelar",
        "complete": "Cerrar"
    },
    "promo": {
        "title": "Comentarios gratuitos facilitados por",
        "content": "¡Consiga hoy su herramienta <strong>GRATUITA</strong> de comentarios del sitio web!"
    }
}
// Finnish
translations.fi = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Palautekategoria",
                "Email": "Sähköposti",
                "Opt in": "Haluaisitko saada {{website}} ajankohtaistiedotteet sähköpostiisi?",
                "Message": "Viesti",
                "Name": "Nimi",
                "Subject": "Aihe",
                "Net Promoter Score": "Suosittelisitko sivustoa {{website}} ystävälle tai kollegalle?",
                "Emoji rating": "Mitä mieltä olet sivustosta {{website}}?",
                "Thumb rating": "Mitä mieltä olet sivustosta{{website}}?",
                "Star rating": "Mitä mieltä olet sivustosta {{website}}?",
                "Priority": "Prioriteetti",
                "Speed": "Kuinka nopeasti sivu latautui?"
            },
            "options": {
                "Category": {
                    "Question": "Kysymys",
                    "Problem": "Ongelma",
                    "Suggestion": "Ehdotus",
                    "Compliment": "Kiitos",
                    "Typo": "Kirjoitus- tai kielioppivirhe",
                    "Other": "Muu"
                },
                "Emoji rating": {
                    "Hate": "Inhoan",
                    "Dislike": "En pidä",
                    "Neutral": "Neutraali",
                    "Like": "Pidän",
                    "Love": "Rakastan"
                },
                "Thumb rating": {
                    "Good": "Hyvä",
                    "Bad": "Huono"
                },
                "Priority": {
                    "Low": "Matala",
                    "Normal": "Normaali",
                    "High": "Korkea",
                    "Urgent": "Kiireellinen"
                },
                "Speed": {
                    "Fast": "Nopea",
                    "Normal": "Normaali",
                    "Slow": "Hidas"
                }
            }
        },
        "dropdown_please_choose": " - Valitse - ",
        "screenshot_added": "Kuvakaappaus lisätty",
        "required": "pakollinen",
        "powered_by": "Powered by"
    },
    "screenshot": {
        "info": "Klikkaa sitä kohtaa sivulla, johon palautteesi liittyy.",
        "touch_info": "Kosketa sitä kohtaa sivulla, johon palautteesi liittyy.",
        "classic_info": "Maalaa ne osat sivusta, joihin palautteesi liittyy."
    },
    "feedback_button": "Palaute",
    "loading": "Ladataan...",
    "complete": "Kiitos palautteestasi!",
    "errors": {
        "localhost_warning": {
            "title": "Varoitus: Näytät käyttävän Saber Feedbackiä localhost-ympäristössä.",
            "body": [
                "Kuvakaappauksia varten Saberillä pitää olla pääsy sivustosi kuviin, CSS tiedostoihin ja mahdollisiin fonttitiedostoihin. Voit jatkaa Saberin käyttöä localhostilla, mutta kuvakaappaukset eivät silloin näy oikein ja joissain tapauksissa palautetta ei voi lähettää lainkaan.",
                "Jos haluat testata Saberia ennen tuotantokäyttöä, suosittelemme, että käytät staging-palvelinta tai localhost tunnelointipalvelua kuten ngrok.io.",
                "Voit estää tämän vietin näyttämisen lisäämällä tämän rivin sivulle upotettavaan koodiin: <code>suppress_localhost_warning:&nbsp;true</code> <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">Lisätietoja täällä</a>"
            ]
        },
        "general_error": "Tapahtui tuntematon virhe. Yritä myöhemmin uudelleen.",
        "screenshot_requried": "Lisää kuvakaappaus",
        "field_invalid": {
            "required": "Tämä tieto on pakollinen",
            "email": "Sähköpostiosoite on virheellinen",
            "url": "URL on virheellinen"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Lähetä palaute",
        "add_screenshot": "Lisää kuvakaappaus",
        "edit_screenshot": "Vaihda kuvakaappaus",
        "cancel": "Peruuta",
        "complete": "Sulje"
    },
    "promo": {
        "title": "Palautelomakkeen toteutus",
        "content": "Hanki oma palautetyökalu jo tänään!"
    }
}

// French
translations.fr = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Catégorie de l'avis",
                "Email": "Adresse e-mail",
                "Message": "Message",
                "Name": "Nom",
                "Subject": "Sujet",
                "Priority": "Priorité",
                "Speed": "À quelle vitesse la page à t-elle chargé ?",
                "Net Promoter Score": "Dans quelle mesure êtes-vous susceptible de recommander {{website}} à un ami ou à un collègue ?",
                "Emoji rating": "Que pensez-vous de {{website}} ?",
                "Thumb rating": "Que pensez-vous de {{website}} ?",
                "Star rating": "Que pensez-vous de {{website}} ?",
                "Opt in": "Souhaitez-vous recevoir les mises à jour de {{website}} ?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Je déteste",
                    "Dislike": "Je n'aime pas",
                    "Neutral": "Neutre",
                    "Like": "J'aime",
                    "Love": "J'adore"
                },
                "Thumb rating": {
                    "Good": "Bon",
                    "Bad": "Mauvais"
                },
                "Category": {
                    "Question": "Question",
                    "Problem": "Problème",
                    "Suggestion": "Suggestion",
                    "Compliment": "Compliment",
                    "Typo": "Orthographe ou Grammaire",
                    "Other": "Autre"
                },
                "Priority": {
                    "Low": "Basse",
                    "Normal": "Normale",
                    "High": "Haute",
                    "Urgent": "Urgente"
                },
                "Speed": {
                    "Fast": "Rapidement",
                    "Normal": "Normalement",
                    "Slow": "Lentement"
                }
            }
        },
        "dropdown_please_choose": " - Veuillez sélectionner - ",
        "screenshot_added": "Capture d'écran ajoutée",
        "required": "requis",
        "powered_by": "Géré par"
    },
    "screenshot": {
        "info": "Cliquez l'élément de la page qui concerne vos commentaires",
        "touch_info": "Touchez l'élément de la page qui concerne vos commentaires",
        "classic_info": "Cliquez et faites glisser pour mettre en évidence toutes les parties de cette page qui se rapportent à votre avis."
    },
    "feedback_button": "Remarque",
    "loading": "Chargement ...",
    "complete": "Merci d'avoir donné votre avis !",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Nous sommes désolés, quelque chose s'est mal passé. Veuillez réessayer plus tard.",
        "screenshot_requried": "Veuillez ajouter une capture d'écran",
        "field_invalid": {
            "email": "Ce n'est pas une adresse e-mail valide",
            "url": "Ce n'est pas une URL valide",
            "required": "Ce champ est requis"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Envoyer l'avis",
        "add_screenshot": "Ajoutez une capture d'écran",
        "edit_screenshot": "Changez de capture d'écran",
        "cancel": "Quitter",
        "complete": "Fermer"
    },
    "promo": {
        "title": "Retour gratuit proposé par",
        "content": "Obtenez votre outil <strong>GRATUIT</strong> de commentaires de site aujourd'hui !"
    }
}
// Hungarian
translations.hu = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Észrevétel Kategória",
                "Email": "E-mail Cím",
                "Message": "Üzenet",
                "Name": "Név",
                "Subject": "Tárgy",
                "Priority": "Fontosság",
                "Speed": "Milyen gyorsan töltődött be az oldal?",
                "Net Promoter Score": "Mennyire tartod valószínűnek, hogy ajánlanád a következőt: {{website}} egy ismerősödnek vagy munkatársadnak?",
                "Emoji rating": "Mit gondolsz a következőről: {{website}}?",
                "Thumb rating": "Mit gondolsz a következőről: {{website}}?",
                "Star rating": "Mit gondolsz a következőről: {{website}}?",
                "Opt in": "Szeretne frissítéseket kapni a(z) {{website}} weboldaltól?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Utálom",
                    "Dislike": "Nem tetszik",
                    "Neutral": "Közömbös",
                    "Like": "Tetszik",
                    "Love": "Imádom"
                },
                "Thumb rating": {
                    "Good": "Jó",
                    "Bad": "Rossz"
                },
                "Category": {
                    "Question": "Kérdés",
                    "Problem": "Probléma",
                    "Suggestion": "Javaslat",
                    "Compliment": "Dicséret",
                    "Typo": "Helyesírás vagy Nyelvtani Hiba",
                    "Other": "Egyéb"
                },
                "Priority": {
                    "Low": "Alacsony",
                    "Normal": "Normál",
                    "High": "Magas",
                    "Urgent": "Sürgős"
                },
                "Speed": {
                    "Fast": "Gyorsan",
                    "Normal": "Normálisan",
                    "Slow": "Lassan"
                }
            }
        },
        "dropdown_please_choose": " - Kérem Válasszon - ",
        "screenshot_added": "Képernyőkép hozzáadva",
        "required": "kötelező",
        "powered_by": "Szolgáltatja"
    },
    "screenshot": {
        "info": "Kattints az oldalon azt a tárgyat, amely kapcsolódik visszajelzésedhez",
        "touch_info": "Érintsd meg az oldalon azt a tárgyat, amely kapcsolódik visszajelzésedhez",
        "classic_info": "Kattintson rá és húzza be az oldal bármely részét, amely kapcsolódik az észrevételhez."
    },
    "feedback_button": "Észrevétel",
    "loading": "Töltés...",
    "complete": "Köszönjük az Észrevételt!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Sajnáljuk, valami hiba adódott. Kérjük, próbálja újra később.",
        "screenshot_requried": "Kérlek adj hozzá egy képernyőképet",
        "field_invalid": {
            "email": "Ez nem egy érvényes e-mail cím",
            "url": "Ez nem egy érvényes URL",
            "required": "Ez a mező kötelező"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Észrevétel Küldése",
        "add_screenshot": "Képernyőkép Hozzáadása",
        "edit_screenshot": "Képernyőkép Cserélése",
        "cancel": "Mégse",
        "complete": "Bezárás"
    },
    "promo": {
        "title": "A Visszajelzést szolgáltatja",
        "content": "Szerezze be weboldal-észrevételek eszközét még ma!"
    }
}
// Italian
translations.it = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Categoria feedback",
                "Email": "Indirizzo e-mail",
                "Opt in": "Vuoi ricevere aggiornamenti da {{website}}?",
                "Message": "Messaggio",
                "Name": "Nome",
                "Subject": "Oggetto",
                "Net Promoter Score": "Con quale probabilità consiglieresti {{website}} a un amico o collega?",
                "Emoji rating": "Che cosa pensi di {{website}}?",
                "Thumb rating": "Che cosa pensi di {{website}}?",
                "Star rating": "Che cosa pensi di {{website}}?",
                "Priority": "Priorità",
                "Speed": "Quanto velocemente è stata caricata questa pagina?"
            },
            "options": {
                "Category": {
                    "Question": "Domanda",
                    "Problem": "Problema",
                    "Suggestion": "Suggerimento",
                    "Compliment": "Complimento",
                    "Typo": "Ortografia o grammatica",
                    "Other": "Altro"
                },
                "Emoji rating": {
                    "Hate": "Odio",
                    "Dislike": "Non mi piace",
                    "Neutral": "Neutrale",
                    "Like": "Mi piace",
                    "Love": "Amo"
                },
                "Thumb rating": {
                    "Good": "Bene",
                    "Bad": "Male"
                },
                "Priority": {
                    "Low": "Bassa",
                    "Normal": "Normale",
                    "High": "Alta",
                    "Urgent": "Urgente"
                },
                "Speed": {
                    "Fast": "Veloce",
                    "Normal": "Normale",
                    "Slow": "Lento"
                }
            }
        },
        "dropdown_please_choose": " - Seleziona - ",
        "screenshot_added": "Screenshot aggiunto",
        "required": "obbligatorio",
        "powered_by": "Powered by"
    },
    "screenshot": {
        "info": "Fai clic sull'elemento della pagina che si riferisce al tuo feedback",
        "touch_info": "Tocca l'elemento della pagina che si riferisce al tuo feedback",
        "classic_info": "Clicca e trascina per evidenziare qualsiasi parte di questa pagina correlata al feedback."
    },
    "feedback_button": "Feedback",
    "loading": "In caricamento...",
    "complete": "Grazie per il feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Avviso: sembra che tu stia utilizzando Saber Feedback in un ambiente localhost",
            "body": [
                "Saber Feedback deve essere in grado di accedere alle immagini del tuo sito web, ai file CSS e ai caratteri personalizzati per creare gli screenshot. Puoi continuare a utilizzare Saber Feedback in localhost, ma gli screenshot non verranno visualizzati correttamente e, in alcuni casi, il feedback non verrà inviato affatto.",
                "Se vuoi provare Saber Feedback prima di aggiungerlo al tuo sito live, è meglio utilizzare un server di staging online o un servizio di tunneling localhost come ngrok.io",
                "Puoi interrompere la visualizzazione di questo messaggio aggiungendo <code>suppress_localhost_warning:&nbsp;true</code> al tuo codice di incorporamento, ulteriori informazioni possono essere trovate <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">nella documentazione</a>"
            ]
        },
        "general_error": "Qualcosa è andato storto. Per favore riprova più tardi.",
        "screenshot_requried": "Aggiungi uno screenshot",
        "field_invalid": {
            "required": "Questo campo è obbligatorio",
            "email": "Questo non è un indirizzo e-mail valido",
            "url": "Questo non è un URL valido"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Invia il feedback",
        "add_screenshot": "Aggiungi screenshot",
        "edit_screenshot": "Modifica screenshot",
        "cancel": "Annulla",
        "complete": "Chiudi"
    },
    "promo": {
        "title": "Feedback powered by",
        "content": "Ottieni oggi stesso uno strumento di feedback per il tuo sito web!"
    }
}
// Dutch
translations.nl = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Feedback categorie",
                "Email": "E-mailadres",
                "Message": "Bericht",
                "Name": "Naam",
                "Subject": "Onderwerp",
                "Priority": "Prioriteit",
                "Speed": "Hoe snel laadde deze pagina?",
                "Net Promoter Score": "Hoe waarschijnlijk is het dat u {{website}} aanbeveelt aan een vriend of collega?",
                "Emoji rating": "Wat vindt u van {{website}}?",
                "Thumb rating": "Wat vindt u van {{website}}?",
                "Star rating": "Wat vindt u van {{website}}?",
                "Opt in": "Wil je updates ontvangen van {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Haat",
                    "Dislike": "Niet leuk",
                    "Neutral": "Neutraal",
                    "Like": "Leuk",
                    "Love": "Liefde"
                },
                "Thumb rating": {
                    "Good": "Goed",
                    "Bad": "Slecht"
                },
                "Category": {
                    "Question": "Vraag",
                    "Problem": "Probleem",
                    "Suggestion": "Suggestie",
                    "Compliment": "Compliment",
                    "Typo": "Spelling of Grammatica",
                    "Other": "Anders"
                },
                "Priority": {
                    "Low": "Laag",
                    "Normal": "Normaal",
                    "High": "Hoog",
                    "Urgent": "Urgent"
                },
                "Speed": {
                    "Fast": "Snel",
                    "Normal": "Normaal",
                    "Slow": "Langzaam"
                }
            }
        },
        "dropdown_please_choose": " - Gelieve te kiezen - ",
        "screenshot_added": "Screenshot toegevoegd",
        "required": "verplicht",
        "powered_by": "Verzorgd door"
    },
    "screenshot": {
        "info": "Klik op het artikel op de pagina die bij uw feedback past",
        "touch_info": "Raak het artikel op de pagina die bij uw feedback past aan",
        "classic_info": "Klik en sleep om een deel van deze pagina dat betrekking heeft op uw feedback te markeren."
    },
    "feedback_button": "Feedback",
    "loading": "Aan het laden...",
    "complete": "Bedankt voor de feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Het spijt ons, er is iets fout gegaan. Probeer het later opnieuw.",
        "screenshot_requried": "Voeg alstublieft een screenshot toe",
        "field_invalid": {
            "email": "Dit is geen geldig e-mailadres",
            "url": "Dit is geen geldige URL",
            "required": "Dit veld is verplicht"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Feedback verzenden",
        "add_screenshot": "Screenshot toevoegen",
        "edit_screenshot": "Screenshot aanpassen",
        "cancel": "Maak ongedaan",
        "complete": "Sluiten"
    },
    "promo": {
        "title": "Gratis feedback aangedreven door",
        "content": "Krijg vandaag jouw <strong>GRATIS</strong> website feedback hulpmiddel!"
    }
}
// Portuguese
translations.pt = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Categoria de Feedback",
                "Email": "Endereço de Email",
                "Message": "Mensagem",
                "Name": "Nome",
                "Subject": "Assunto",
                "Priority": "Prioritário",
                "Speed": "Quão rápido foi o carregamento desta página?",
                "Net Promoter Score": "Qual a probabilidade de recomendar o {{website}} a um amigo ou colega?",
                "Emoji rating": "Qual a sua opinião sobre o {{website}}?",
                "Thumb rating": "Qual a sua opinião sobre o {{website}}?",
                "Star rating": "Qual a sua opinião sobre o {{website}}?",
                "Opt in": "Gostaria de receber atualizações de {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Detesto",
                    "Dislike": "Não gosto",
                    "Neutral": "Indiferente",
                    "Like": "Gosto",
                    "Love": "Adoro"
                },
                "Thumb rating": {
                    "Good": "Bom",
                    "Bad": "Mau"
                },
                "Category": {
                    "Question": "Questão",
                    "Problem": "Problema",
                    "Suggestion": "Sugestão",
                    "Compliment": "Elogio",
                    "Typo": "Ortografia ou Gramática",
                    "Other": "Outro"
                },
                "Priority": {
                    "Low": "Baixa",
                    "Normal": "Normal",
                    "High": "Alta",
                    "Urgent": "Urgente"
                },
                "Speed": {
                    "Fast": "Rápido",
                    "Normal": "Normal",
                    "Slow": "Lento"
                }
            }
        },
        "dropdown_please_choose": " - Por Favor Escolha - ",
        "screenshot_added": "Captura de ecrã adicionada",
        "required": "obrigatório",
        "powered_by": "Desenvolvido por"
    },
    "screenshot": {
        "info": "Clique no item na página relacionada com o seu comentário",
        "touch_info": "Toque no item na página relacionada com o seu comentário",
        "classic_info": "Clique e arraste para destacar qualquer parte da página que esteja relacionada com o seu feedback."
    },
    "feedback_button": "Comentário",
    "loading": "A carregar...",
    "complete": "Obrigado pelo Feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Pedimos desculpa, algo correu mal. Por favor tente novamente mais tarde.",
        "screenshot_requried": "Por favor, adicione uma captura de ecrã",
        "field_invalid": {
            "email": "Este endereço de correio eletrónico não é válido",
            "url": "Este endereço da Internet não é válido",
            "required": "Este campo é obrigatório"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Enviar Feedback",
        "add_screenshot": "Adicionar Captura de Tela",
        "edit_screenshot": "Alterar Captura de Tela",
        "cancel": "Cancelar",
        "complete": "Fechar"
    },
    "promo": {
        "title": "Feedback gratuito fornecido pela",
        "content": "Obtenha hoje mesmo a sua ferramenta <strong>GRÁTIS</strong> de feedback sobre sítios web!"
    }
}
// Russian
translations.ru = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Написать отзыв о разделе",
                "Email": "Адрес электронной почты",
                "Message": "Сообщение",
                "Name": "имя",
                "Subject": "Тема",
                "Priority": "Приоритет",
                "Speed": "Насколько быстро загрузилась страница?",
                "Net Promoter Score": "Насколько вероятно, что вы порекомендуете {{website}} другу или коллеге?",
                "Emoji rating": "Как вы относитесь к {{website}}?",
                "Thumb rating": "Как вы относитесь к {{website}}?",
                "Star rating": "Как вы относитесь к {{website}}?",
                "Opt in": "Вы хотели бы получать обновления от {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Ненавижу",
                    "Dislike": "Не нравится",
                    "Neutral": "Нейтрально",
                    "Like": "Нравится",
                    "Love": "Люблю"
                },
                "Thumb rating": {
                    "Good": "Хорошо",
                    "Bad": "Плохо"
                },
                "Category": {
                    "Question": "Вопрос",
                    "Problem": "Проблема",
                    "Suggestion": "Предложение",
                    "Compliment": "Комплимент",
                    "Typo": "Написание и Грамматика",
                    "Other": "Другое"
                },
                "Priority": {
                    "Low": "Низкий",
                    "Normal": "Нормальный",
                    "High": "Высокий",
                    "Urgent": "Срочный"
                },
                "Speed": {
                    "Fast": "Быстро",
                    "Normal": "Нормально",
                    "Slow": "Медленно"
                }
            }
        },
        "dropdown_please_choose": " - Выберите, пожалуйста - ",
        "screenshot_added": "Скриншот добавлен",
        "required": "обязательный",
        "powered_by": "Поддержка сайта"
    },
    "screenshot": {
        "info": "Нажмите элемент на странице, который относится к вашему отзыву",
        "touch_info": "Коснитесь элемент на странице, который относится к вашему отзыву",
        "classic_info": "Щелкните мышкой по любой части данной страницы, относящейся к вашему отзыву, и перетащите ее."
    },
    "feedback_button": "Отзыв",
    "loading": "Загрузка...",
    "complete": "Спасибо за Отзыв!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Просим прощения, но что-то пошло не так. Повторите попытку позже, пожалуйста.",
        "screenshot_requried": "Пожалуйста, добавьте скриншот",
        "field_invalid": {
            "email": "Это некорректный адрес электронной почты",
            "url": "Это некорректная ссылка",
            "required": "Это обязательное поле"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Отправить Отзыв",
        "add_screenshot": "Добавить Скриншот",
        "edit_screenshot": "Изменить Скриншот",
        "cancel": "Отмена",
        "complete": "Закрыть"
    },
    "promo": {
        "title": "Бесплатный отзыв на базе",
        "content": "Получите уже сегодня вашу <strong>БЕСПЛАТНУЮ</strong> утилиту для оценки сайта!"
    }
}
// Swedish
translations.se = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Feedback-kategori",
                "Email": "E-postadress",
                "Message": "Meddelande",
                "Name": "Namn",
                "Subject": "Ämne",
                "Priority": "Prioritet",
                "Speed": "Hur snabbt laddades den här sidan?",
                "Net Promoter Score": "Hur sannolikt är det att du skulle rekommendera {{website}} till en vän eller kollega?",
                "Emoji rating": "Vad tycker du om {{website}}?",
                "Thumb rating": "Vad tycker du om {{website}}?",
                "Star rating": "Vad tycker du om {{website}}?",
                "Opt in": "Skulle du vilja få uppdateringar från {{website}}?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Avskyr",
                    "Dislike": "Ogillar",
                    "Neutral": "Varken eller",
                    "Like": "Gillar",
                    "Love": "Älskar"
                },
                "Thumb rating": {
                    "Good": "Bra",
                    "Bad": "Dåligt"
                },
                "Category": {
                    "Question": "Fråga",
                    "Problem": "Problem",
                    "Suggestion": "Förslag",
                    "Compliment": "Komplimang",
                    "Typo": "Stavning eller grammatik",
                    "Other": "Annat"
                },
                "Priority": {
                    "Low": "Låg",
                    "Normal": "Normal",
                    "High": "Hög",
                    "Urgent": "Brådskande"
                },
                "Speed": {
                    "Fast": "Snabbt",
                    "Normal": "Normalt",
                    "Slow": "Långsamt"
                }
            }
        },
        "dropdown_please_choose": " - Välj - ",
        "screenshot_added": "Skärmbild tillagd",
        "required": "obligatoriskt",
        "powered_by": "Drivs av"
    },
    "screenshot": {
        "info": "Klicka föremålet på sidan som din återkoppling berör",
        "touch_info": "Peka på föremålet på sidan som din återkoppling berör",
        "classic_info": "Klicka och dra för att markera de delar av sidan som är relevanta för din feedback."
    },
    "feedback_button": "Feedback",
    "loading": "Laddar...",
    "complete": "Tack för din feedback!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Tyvärr har ett fel uppstått. Försök igen senare.",
        "screenshot_requried": "Lägg till en skärmbild",
        "field_invalid": {
            "email": "Detta är inte en giltig e-postadress",
            "url": "Detta är inte en giltig URL",
            "required": "Detta fält är obligatoriskt"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Skicka feedback",
        "add_screenshot": "Lägg till skärmdump",
        "edit_screenshot": "Ändra skärmdump",
        "cancel": "Avbryt",
        "complete": "Stäng"
    },
    "promo": {
        "title": "Gratis återkoppling drivs av",
        "content": "Få ditt verktyg för återkoppling av webbplatser <strong>GRATIS</strong> idag!"
    }
}
// turkish
translations.tr = {
    "form": {
        "fields": {
            "labels": {
                "Category": "Geri Bildirim Kategorisi",
                "Email": "E-posta Adresi",
                "Message": "Mesaj",
                "Name": "Isim",
                "Subject": "Konu",
                "Priority": "Öncelik",
                "Speed": "Bu sayfa ne kadar hızlı yüklendi?",
                "Net Promoter Score": "{{website}} sitesini bir arkadaşına veya iş arkadaşına önermen ne kadar olası?",
                "Emoji rating": "{{website}} hakkında ne düşünüyorsun?",
                "Thumb rating": "{{website}} hakkında ne düşünüyorsun?",
                "Star rating": "{{website}} hakkında ne düşünüyorsun?",
                "Opt in": "{{website}} adlı siteden güncellemeler almak ister misin?"
            },
            "options": {
                "Emoji rating": {
                    "Hate": "Nefret ettim",
                    "Dislike": "Beğenmedim",
                    "Neutral": "Nötr",
                    "Like": "Beğendim",
                    "Love": "Bayıldım"
                },
                "Thumb rating": {
                    "Good": "İyi",
                    "Bad": "Kötü"
                },
                "Category": {
                    "Question": "Soru",
                    "Problem": "Sorun",
                    "Suggestion": "Öneri",
                    "Compliment": "İltifat",
                    "Typo": "Yazım veya Dil Bilgisi",
                    "Other": "Diğer"
                },
                "Priority": {
                    "Low": "Düşük",
                    "Normal": "Normal",
                    "High": "Yüksek",
                    "Urgent": "Acil"
                },
                "Speed": {
                    "Fast": "Hızlı",
                    "Normal": "Normal",
                    "Slow": "Yavaş"
                }
            }
        },
        "dropdown_please_choose": " - Lütfen Seçin - ",
        "screenshot_added": "Ekran görüntüsü eklendi",
        "required": "zorunlu",
        "powered_by": "Geribildiriminizi açıklayın"
    },
    "screenshot": {
        "info": "Sayfadaki geri bildiriminle ilgili olan ögeye tıkla",
        "touch_info": "Sayfadaki geri bildiriminle ilgili olan ögeye dokun",
        "classic_info": "Bu sayfanın geri bildiriminizle ilgili yerlerini seçmek için tıklayın ve sürükleyin"
    },
    "feedback_button": "Geribildirim",
    "loading": "Yükleniyor...",
    "complete": "Geri Bildirim için Teşekkürler!",
    "errors": {
        "localhost_warning": {
            "title": "Warning: You seem to be using Saber on a localhost environment",
            "body": [
                "Saber needs to be able to access your website's images, CSS files, and custom fonts to create screenshots. You can continue to use Saber on a localhost, but screenshots will not render correctly, and in some cases, feedback will not submit at all.",
                "If you want to try Saber before adding it to your live site, it's best to either use an online staging server, or a localhost tunneling service such as ngrok.io",
                "You can stop this message from showing by adding <code>suppress_localhost_warning: true</code> to your embed code, more information can be found <a href=\"https://docs.saberfeedback.com/run-time-options/#suppress_localhost_warning\" target=\"_blank\">in the documentation</a>"
            ]
        },
        "general_error": "Üzgünüz, bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyin.",
        "screenshot_requried": "Lütfen bir ekran görüntüsü ekleyin",
        "field_invalid": {
            "email": "Bu geçerli bir eposta adresi değil",
            "url": "Bu geçerli bir URL değil",
            "required": "Bu alan gereklidir"
        }
    },
    "buttons": {
        "ok": "OK",
        "submit": "Geri Bildirim Gönder",
        "add_screenshot": "Ekran Görüntüsü Ekle",
        "edit_screenshot": "Ekran Görüntüsünü Değiştir",
        "cancel": "İptal",
        "complete": "Kapat"
    },
    "promo": {
        "title": "Ücretsiz geri bildirim bir [[[app name]]] hizmetidir",
        "content": "<strong>ÜCRETSİZ</strong> web sitesi geri bildirim aracını bugün al!"
    }
}

// this code assumes that a variable called 'translations' has already been declared
// 'translations' maps locale codes to the translations for each language
var i18n = new function () {
    var self = this;

    // fallback locale is English
    var current_locale = 'en';

    function traverse(translations, key_parts) {
        var part = key_parts.shift();

        var translation = translations[part];

        // not found
        if (!translation) {
            return false;
        }

        // found this level, go deeper
        else if (key_parts.length > 0) {
            return traverse(translation, key_parts);
        }

        // found final
        else {
            return translation;
        }
    }

    self.get_current_locale = function () {
        return current_locale;
    }

    self.set_current_locale = function (new_locale) {
        if (translations[new_locale]) {
            current_locale = new_locale;
        }
    }

    self.t = function (key, fallback) {
        var translation;

        // first try DB translations
        var db_translations = window.saberWebsiteConfig.i18n;

        if (db_translations[current_locale]) {
            translation = traverse(db_translations[current_locale], key.split('.'));
        }

        // next try current_locale
        if (!translation) {
            translation = traverse(translations[current_locale], key.split('.'));
        }

        // next try fallback param
        if (!translation && fallback) {
            return fallback;
        }

        // next try the fallback_locale
        if (!translation && current_locale !== fallback_locale) {
            translation = traverse(translations['en'], key.split('.'));
        }

        if (!translation) {
            // finally just return a more human readable version of the key
            translation = key.split('.').pop();

            // make sure translation is defined
            if (translation.length > 0) {
                translation = translation[0].toUpperCase() + translation.slice(1);
            }
        }

        if (typeof translation === 'string') {
            var displayName = window.saberWebsiteConfig.displayName
            var replacement = displayName.replace("'", "\\'");
            return translation.replace(/{{website}}/g, replacement);
        }

        // returning a hash of translations, no {{website}} replacement
        return translation;
    }
};


            // States
const stateBase = function() {
  var self = this;

  self.views = [];

  self.load_view = function(view_name, params) {
    var view = new Views[view_name](self, params || {});

    self.views.push(view);

    consoleLog('Loading View', view_name);

    return view;
  }

  self.enter = function() {

  }

  self.kill_views = function() {
    for(var i = 0; i < self.views.length; i++) {
      self.views[i].destroy();
    }

    self.views = [];
  }

  self.leave = function() {
    self.kill_views();
  }
}
// Everything that happens in states derived from this are isolated from the host site in an iframe
const stateIsolated = function() {
  var self = this;

  States.base.call(self);

  var last_window_size;
  var resize_timer = null;

  self.load_view = function(view_name, params) {
    var view = new Views[view_name](self, params || {});

    self.views.push(view);

    consoleLog('Loading View', view_name);

    return view;
  }

  self.position_iframe = function() {
    // position and size isolation frame to match the document height and viewport width
    var page_size = secret_sauce.page_size();
    var viewport_size = secret_sauce.viewport_size();
    var scroll_pos = secret_sauce.window_scroll_position();

    last_window_size = viewport_size;

    isolation_frame.style.top = 0;
    isolation_frame.style.left = `${scroll_pos.left}px`;
    isolation_frame.width = viewport_size.width;
    isolation_frame.height = viewport_size.height;
    isolation_frame.style.height = viewport_size.height + "px";

    if(self.reposition) {
      self.reposition();
    }
  }

  // called by the window.onresize event
  self.reposition_iframe = function() {
    if(resize_timer !== null) {
      clearTimeout(resize_timer);
    }

    resize_timer = setTimeout(function() {
      resize_timer = null;

      consoleLog('Reposition isolation frame');

      self.position_iframe();
    }, 100); // wait 100 ms between repositionings of iframe on resize to avoid it happening too often
  }

  self.enter = function() {
    // default iframe position
    self.position_iframe();

    // common iframe styling
    isolation_frame.style.display = 'block';
    isolation_frame.style.position = 'fixed';
    isolation_frame.style.zIndex = 2147483647 - 5; // max z-index minus 5
    isolation_frame.style.border = 'none';

    window.addEventListener('resize', self.reposition_iframe);
  }

  self.kill_views = function() {
    for(var i = 0; i < self.views.length; i++) {
      self.views[i].destroy();
    }

    self.views = [];
  }

  var _leave = self.leave;
  self.leave = function() {
    _leave();

    isolation_frame.style.display = 'none';

    window.removeEventListener('resize', self.reposition_iframe);
  }
}
const stateMessage = function(main) {
  var self = this;

  States.isolated.call(self);
 
  var _enter = self.enter;
  self.enter = function(old_state, params) {
    self.load_view('modal');
    self.load_view('message', params);

    self.escape_handler = KeyboardEventHandlerFactory.close_on_escape();

    if(params.buttons) {
      var primary_button = params.buttons[params.primary_button || 0];

      if(primary_button.on_click) {
        self.return_handler = KeyboardEventHandlerFactory.on_return(primary_button.on_click);
      }
    }
 
    _enter();
  }

  var _leave = self.leave;
  self.leave = function() {
    _leave();

    self.escape_handler.stop();

    if(self.return_handler) {
      self.return_handler.stop();
    }
  }
}
const stateIdle = function(main) {
  var self = this;

  States.base.call(self);
 
  self.enter = function(old_state) {
    // set the event log length limit
    event_log.set_event_limit(settings.get('event_log_max_length'));

    // disable and clear event log if config says so
    event_log.set_disabled(settings.get('disable_event_log'));

    
    // make a copy of feedback values so they won't be persisted through closing and re-opening feedback
    var feedback_values = {};

    // If sessionStorage contains our values, use them
    if(sessionStorage.getItem('feedbackValues')) {
      feedback_values = JSON.parse(sessionStorage.getItem('feedbackValues'));
    } else {
      for(var i in settings.get('feedback_values')) {
        feedback_values[i] = settings.get('feedback_values')[i];
      }
    }

    values = new FeedbackValues(feedback_values);
    
    if(!settings.get('hide_button')) {
      self.load_view('button');
    }

    // if auto_open is true, go straight to form state
    if(settings.get('auto_open')) {
      // set auto_open to false now, otherwise Saber would never get back to idle state
      settings.set('auto_open', false);

      main.open();
    }
  }

  self.open_feedback = function(e) { 
    e.preventDefault();
    
    main.open();
  }
}
const stateForm = function(main) {
  var self = this;

  States.isolated.call(self);

  function dont_submit_in_textarea(e) {
    if(e.target.tagName.toUpperCase() !== "TEXTAREA") {
      self.submit();
    }
  }

  self.resize_iframe_on_error = function() {
    var element_size = self.form_view.get_form_container().getBoundingClientRect();
    var isolation_frame_size = isolation_frame.getBoundingClientRect();

    if(element_size.height + element_size.top > isolation_frame_size.height) {
      isolation_frame.height = element_size.height + element_size.top + 20;
      isolation_frame.style.height = (element_size.height + element_size.top + 20) + "px";
    }
  }
 
  var _enter = self.enter;
  self.enter = function(old_state, params) {
    if(values.has_screenshot) {
      // delay showing the form to allow user to briefly see their highlight, as well as stop highlight
      // clicks bubbling to form      
      setTimeout(function() {
        self.form_view = self.load_view('form');
        
        self.screenshot_added();
      }, 300);

      var highlight_view = self.load_view('highlight');

      if(params.highlighted_element) {
        highlight_view.highlight_element(params.highlighted_element);
      }
      else if(params.bounding_box) {
        highlight_view.highlight_bounding_box(params.bounding_box, params.page_size);
      }

      // click interceptor exists to stop screenshot highlight clicks bubbling down to any click events on the host page
      var click_interceptor = document.createElement('div');
      // background is to force IE to render this a solid, so that it intercepts body clicks
      click_interceptor.style.position = 'fixed';
      click_interceptor.style.top = 0;
      click_interceptor.style.right = 0;
      click_interceptor.style.bottom = 0;
      click_interceptor.style.left = 0;
      click_interceptor.style.zIndex = 2147483647 - 7; // max z-index minus 7
      click_interceptor.style.background = 'rgba(0, 0, 0, 0);';
      
      highlight_view.add_root_element(click_interceptor);
    }
    else {
      self.form_view = self.load_view('form');
      
      var modal = self.load_view('modal');
      modal.set_on_click(main.close);
    }
   
    _enter();

    // reposition the iframe when ever a value is changed, as it could also cause the form size to change
    values.on_change = self.resize_iframe_on_error;
    
    self.escape_handler = KeyboardEventHandlerFactory.close_on_escape();
    self.return_handler = KeyboardEventHandlerFactory.on_return(dont_submit_in_textarea);
  }

  var _leave = self.leave;
  self.leave = function() {
    _leave();

    // observers are created in the enter (form view) stage, so clear them on leaving this state
    values.clear_observers();

    // clear the value on change listener
    values.on_change = false;
   
    self.escape_handler.stop();
    self.return_handler.stop();
  }

  self.screenshot_added = function() {
    var info_view = self.load_view('info', {
      message: '<img class="icon icon-ok" src="'+window.saberFeedbackOptions.jsHost + '/images/icon-ok.svg"> '+i18n.t('form.screenshot_added'),
      trait: 'screenshot_added'
    });

    setTimeout(function() {
      info_view.destroy();
    }, 3000);
  }

  self.add_edit_screenshot = function(touch) {
    state_machine.change('screenshot', { touch: touch });
  }

  self.submit = function(e) {
    e.preventDefault();

    var field;
    var valid = true;

    var errors = [];
    
    for(var i = 0, l = self.form_view.form_fields.length; i < l; i++) {
      field = self.form_view.form_fields[i];

      if(!field.validate()) {
        valid = false;

        errors.push(field.container);
      }
    }

    if(settings.get('demo_mode')) {
      return false;
    }

    // check screenshot
    if(!self.form_view.validate_screenshot()) {
      errors.push(self.form_view.get_screenshot_button());
      valid = false;
    }

    if(valid) {
      state_machine.change('message', {
        message: i18n.t('loading'),
        css_class: 'feedback-loading'
      });

      event_log.log_feedback('Feedback Report Submitted');

      values.as_json().then(function(body) {
        var url = window.saberFeedbackOptions.apiHost + '/websites/' +
            window.saberWebsiteConfig.apiKey + '/feedback';
        var req = new Ajax(url, body).then(function(response) {
          do_event_callback('complete', JSON.parse(response));

          state_machine.change('complete');
        }, function(response) {
          state_machine.change('general_error');
        });
      });
    }

    else if(errors.length > 0) {
      // resize the iframe to make sure it shows the error messages
      self.resize_iframe_on_error();

      // If the field is focusable focus it, which will scroll to it
      var error_field = errors[0].querySelector('input,textarea,select');
      if(error_field) {
        error_field.focus();
      }
      // otherwise manually scroll to it
      else {
        window.scrollTo(0, errors[0].offsetTop + isolation_frame.offsetTop);
      }
    }

    return false;
  }

  self.cancel_feedback = function() {
    do_event_callback('cancel');

    main.close();
  }
}
const stateScreenshot = function(main) {
  var self = this;

  States.isolated.call(self);

  var highlight_view, info_view, touch_start_scroll, mousedown_pos, bounding_box, page_size;

  function touchstart_handler(e) {
    e.stopPropagation();

    touch_start_scroll = secret_sauce.window_scroll_position();
    
    body.addEventListener('touchend', touchend_handler, false);
  };

  function touchend_handler(e) {
    e.stopPropagation();

    var touch_end_scroll = secret_sauce.window_scroll_position();
    var touch_threshold = 3; //px

    // make sure the page hasn't scrolled, ie: this is definitely just a tap
    if((Math.abs(touch_start_scroll.top - touch_end_scroll.top) < touch_threshold) && (Math.abs(touch_start_scroll.left - touch_end_scroll.left) < touch_threshold)) {
      // delay the removal of the modal, so that no buttons under the modal will accidentally be triggered by touch events
      // (can't use prevent default on touchstart as it also stops scrolling)
      setTimeout(function() {
        highlight_view.hide_all_modals();

        // Android devices don't set pageX for touch elements, so use first changedTouch instead
        var pageX = e.pageX;
        if(typeof pageX === "undefined") {
          pageX = e.changedTouches[0].pageX;
        }

        var pageY = e.pageY;
        if(typeof pageY === "undefined") {
          pageY = e.changedTouches[0].pageY;
        }

        var target = document.elementFromPoint(pageX - touch_end_scroll.left, pageY - touch_end_scroll.top);

        if(target) {
          highlight_view.highlight_element(target);

          values.add_screenshot(highlight_view.get_modals());
          
          state_machine.change('form', { highlighted_element: highlight_view.get_highlighted_element() });
        }
        else {
          highlight_view.init();
        }
      }, 10);
    }
  }

  function mousemove_handler(e) {
    if(e.target !== highlight_view.get_highlighted_element()) {
      highlight_view.highlight_element(e.target);
    }
  }

  function mousedown_handler(e) {
    e.stopPropagation();

    var highlighted_element = highlight_view.get_highlighted_element();

    if(highlighted_element) {
      values.add_screenshot(highlight_view.get_modals());

      state_machine.change('form', { highlighted_element: highlighted_element });
    }
  }

  function classic_mousedown_handler(e) {
    // only do this on left click or touch events
    if(e.which !== 1) {
      return;
    }

    page_size = secret_sauce.page_size();
    
    // get the click location
    bounding_box = {};
    mousedown_pos = { left: e.pageX, top: e.pageY };

    body.addEventListener('mousemove', classic_mousemove_handler, false);
    body.addEventListener('mouseup', classic_mouseup_handler, false);
  }

  function classic_mousemove_handler(e) {
    // stop scrolling in touch devices
    e.preventDefault();

    bounding_box = {
      top: mousedown_pos.top,
      left: mousedown_pos.left,
      right: e.pageX,
      bottom: e.pageY,
      width: e.pageX - mousedown_pos.left,
      height: e.pageY - mousedown_pos.top
    };
            
    // if either is negative then it's been inverted, so fix it
    if(bounding_box.width < 0) {
      // make width +ve
      bounding_box.width = -bounding_box.width;
      
      // swap the x locations
      bounding_box.right = bounding_box.left;
      bounding_box.left = e.pageX;
    }
      
    if(bounding_box.height < 0) {
      // make height +ve
      bounding_box.height = -bounding_box.height;
      
      // swap the u locations
      bounding_box.bottom = bounding_box.top;
      bounding_box.top = e.pageY;
    }

    highlight_view.highlight_bounding_box(bounding_box, page_size);
  }

  function classic_mouseup_handler(e) {
    // only accept screenshot if area is greater than 50 px
    if(bounding_box && bounding_box.width > 5 && bounding_box.height > 5) {
      values.add_screenshot(highlight_view.get_modals());

      state_machine.change('form', { bounding_box: bounding_box, page_size: page_size });
    }
    // otherwise, force a new highlight
    else {
      highlight_view.init();

      body.removeEventListener('mousemove', classic_mousemove_handler, false);
      body.removeEventListener('mouseup', classic_mouseup_handler, false);
    }
  }

  function kill_all_handlers() {
    body.removeEventListener('touchstart', touchstart_handler, false);
    body.removeEventListener('touchend', touchend_handler, false);

    body.removeEventListener('mousemove', mousemove_handler, false);
    body.removeEventListener('mousedown', mousedown_handler, true);
    
    body.removeEventListener('mousedown', classic_mousedown_handler, true);
    body.removeEventListener('mousemove', classic_mousemove_handler, false);
    body.removeEventListener('mouseup', classic_mouseup_handler, false);
  }

  self.position_iframe = function() {
    var max_iframe_width = 600;
    var viewport_size = secret_sauce.viewport_size();
    var iframe_width = Math.min(viewport_size.width, max_iframe_width);
    var scroll_pos = secret_sauce.window_scroll_position();

    isolation_frame.style.position = 'fixed';
    isolation_frame.style.top = 0;

    // position horizontally centered if screen is wider than max iframe width
    if(viewport_size.width > max_iframe_width) {
      var iframe_left = Math.round(viewport_size.width / 2) - Math.round(iframe_width / 2);
    }
    else {
      var iframe_left = 0;
    }
    
    isolation_frame.style.left = `${iframe_left}px`;

    isolation_frame.width = iframe_width;

    // initially set iframe height to match viewport so the height of the info message can be calculated
    isolation_frame.height = viewport_size.height;
    isolation_frame.style.height = viewport_size.height + "px";

    // wait 100 ms before setting iframe height to make sure the info panel is drawn
    setTimeout(function() {
      var element_size = info_view.info_container.getBoundingClientRect();

      isolation_frame.height = Math.ceil(element_size.height);
      isolation_frame.style.height = Math.ceil(element_size.height) + "px";
    }, 100);
  }

  function cancel() {
    values.remove_screenshot();
    state_machine.change('form');
  }
 
  var _enter = self.enter;
  self.enter = function(last_state, params) {
    var message = 'info';

    if(params.touch) {
      message = `touch_${message}`;
    }
    else if(settings.get('classic_highlight')) {
      message = `classic_${message}`;
    }

    info_view = self.load_view('info', { 
      message: i18n.t(`screenshot.${message}`),
      trait: 'screenshot_info',
      on_cancel: cancel
    });

    highlight_view = self.load_view('highlight');

    // --------------------- Touch events ------------------------ \\
    if(params.touch) {
      body.addEventListener('touchstart', touchstart_handler, false);
    }

    // ------------------ Legacy click-and-drag events ---------------- \\
    else if(settings.get('classic_highlight')) {
      body.addEventListener('mousedown', classic_mousedown_handler, true);
    }

    // --------------------- Click events ------------------------ \\
    else {
      body.addEventListener('mousemove', mousemove_handler, false);

      body.addEventListener('mousedown', mousedown_handler, true);
    }
    
    self.escape_handler = KeyboardEventHandlerFactory.on_escape(cancel);

    _enter();
  }

  var _leave = self.leave;
  self.leave = function() {
    _leave();

    kill_all_handlers();
    self.escape_handler.stop();
  }
}
const stateComplete = function (main) {
    var self = this;

    States.isolated.call(self);

    var _enter = self.enter;
    self.enter = function (old_state) {
        event_log.clear_event_log();

        var modal_view = self.load_view('modal');
        modal_view.set_on_click(main.close);

        if (window.saberWebsiteConfig.branding === 'standard') {
            var promo_view = self.load_view('promo');
            promo_view.set_cancel(main.close);

        } else {
            var message_view = self.load_view('message', {
                message: i18n.t('complete'),
                css_class: 'feedback-complete',
                buttons: [
                    {
                        label: i18n.t('buttons.complete'),
                        css_class: 'primary',
                        on_click: function () {
                            main.close();
                        }
                    }
                ]
            });
        }

        // Delete values saved in sessionStorage, except for value for 'Email', if present
        const FEEDBACK_VALUES = 'feedbackValues';
        const cachedValues = sessionStorage.getItem(FEEDBACK_VALUES);
        if (cachedValues) {
            const emailValue = (JSON.parse(cachedValues))['Email'];
            const reset_values = emailValue ? {Email: emailValue} : {};
            sessionStorage.setItem(FEEDBACK_VALUES, JSON.stringify(reset_values));
        }

        self.escape_handler = KeyboardEventHandlerFactory.close_on_escape();
        self.return_handler = KeyboardEventHandlerFactory.on_return(main.close);

        _enter();
    }

    var _leave = self.leave;
    self.leave = function () {
        _leave();

        self.escape_handler.stop();
        self.return_handler.stop();
    }
}
const stateGeneralError = function(main) {
  var self = this;

  States.isolated.call(self);
 
  var _enter = self.enter;
  self.enter = function(old_state) {
    event_log.clear_event_log();
                  
    var modal_view = self.load_view('modal');
    modal_view.set_on_click(main.close);

    var message_view = self.load_view('message', {
      message: i18n.t('errors.general_error'),
      css_class: 'feedback-error',
      buttons: [
        {
          label: i18n.t('buttons.ok'),
          css_class: 'primary',
          on_click: function() {
            state_machine.change('idle');
          }
        }
      ]
    });
    
    self.escape_handler = KeyboardEventHandlerFactory.close_on_escape();
    self.return_handler = KeyboardEventHandlerFactory.on_return(main.close);
   
    _enter();
  }

  var _leave = self.leave;
  self.leave = function() {
    _leave();

    self.escape_handler.stop();
    self.return_handler.stop();
  }
}

            var States = {
                base: stateBase,
                isolated: stateIsolated,
                message: stateMessage,
                idle: stateIdle,
                form: stateForm,
                screenshot: stateScreenshot,
                complete: stateComplete,
                general_error: stateGeneralError
            };

            // Views
const viewBase = function() {
  var self = this;

  self.root_elements = [];

  self.add_root_element = function(element) {
    self.root_elements.push(element);

    body.appendChild(element);
  }

  self.add_isolated_element = function(element) {
    self.root_elements.push(element);

    isolation_frame.contentWindow.document.body.appendChild(element);

    // if element is hanging out the bottom of iframe, extend the iframe
    // use a 100ms delay to ensure the element size is ready
    setTimeout(function() {
      var viewport_size = secret_sauce.viewport_size();

        isolation_frame.height = viewport_size.height;
        isolation_frame.style.height = viewport_size.height + "px";
    }, 100);
  }

  self.destroy = function() {
    for(var i = 0; i < self.root_elements.length; i++) {
      var element = self.root_elements[i];

      element.parentNode.removeChild(element);
    }

    self.root_elements = [];

    if(self.on_destroy) {
      self.on_destroy();
    }
  }
}
const viewMessage = function(state, params) {  
  var self = this;

  Views.base.call(self);

  self.message_container = document.createElement('div');
  self.message_container.className = 'saber-feedback saber-message';

  if(params.css_class) {
    self.message_container.className += ` ${params.css_class}`;
  }

  self.add_isolated_element(self.message_container);

  self.set_content = function(content) {
    if(typeof content === 'string') {
      content = { body: content };
    }

    if(typeof content.body === 'string') {
      content.body = [content.body];
    }

    var panel = document.createElement('div');
    panel.className = 'panel panel-default';
    self.message_container.appendChild(panel);

    if(content.title) {
      var panel_heading = document.createElement('div');
      panel_heading.className = 'panel-heading';

      var panel_title = document.createElement('h3');
      panel_title.className = 'panel-title';
      panel_title.textContent = content.title;
      
      panel_heading.appendChild(panel_title);
      panel.appendChild(panel_heading);
    }


    var panel_body = document.createElement('div');
    panel_body.className = 'panel-body';

    if(content.body) {
      var p;
      for(var i = 0; i < content.body.length; i++) {
        p = document.createElement('p');
        p.innerHTML = content.body[i];
        panel_body.appendChild(p);
      }
    }

    panel.appendChild(panel_body);

    self.buttons = document.createElement('div');
    self.buttons.style.textAlign = 'center';
    panel_body.appendChild(self.buttons);
  }

  self.add_button = function(label, btn_class, on_click) {
    var button = document.createElement('button');
    button.className = `btn btn-${btn_class} btn-lg`;
    button.textContent = label;

    button.onclick = on_click;

    self.buttons.appendChild(button);
  }

  if(params.message) {
    self.set_content(params.message);
  }

  if(params.buttons && params.buttons.length) {
    var button;

    for(var i = 0; i < params.buttons.length; i++) {
      button = params.buttons[i];

      self.add_button(button.label, button.css_class, button.on_click);
    }
  }
}
const viewButton = function(state) {
  var self = this;

  Views.base.call(self);

  // create the button
  var button = document.createElement('a');
  button.href = '#';
  
  button.setAttribute('id', 'saberfeedback_button');
  button.className = 'saber_feedback_button'
  button.className += ' bm_'+settings.get('style');
  button.className += ' bm_'+settings.get('position');

  var inner = document.createElement('span');

  inner.textContent = settings.get('label_text') || i18n.t('feedback_button');
  button.appendChild(inner);

  button.style.background = settings.get('background_color');
  button.style.color = settings.get('text_color');
  button.style.display = 'none';

  inner.style.background = settings.get('background_color');
  inner.style.color = settings.get('text_color');
  inner.style.borderColor = settings.get('text_color');

  self.add_root_element(button);
  
  button.onclick = state.open_feedback;
}
const viewForm = function (state) {
    var self = this;

    Views.base.call(self);

    var form_container = document.createElement('div');
    self.add_isolated_element(form_container);
    form_container.id = 'saber_feedback_form';
    form_container.className = 'saber-feedback';

    var form = document.createElement('form');
    form.className = 'panel panel-default';
    form_container.appendChild(form);
    form.noValidate = true; // Validation handled by JS

    var form_body = document.createElement('div');
    form_body.className = 'panel-body';
    form.appendChild(form_body);

    var jsHost = window.saberFeedbackOptions.jsHost;

    // Add cancel button
    var form_cancel = document.createElement('img');
    form_cancel.className = 'icon icon-cancel cancel-feedback';
    form_cancel.src = window.saberFeedbackOptions.jsHost + '/images/icon-close.svg';
    form_body.appendChild(form_cancel);

    form_cancel.onclick = state.cancel_feedback;

    var logo = document.createElement('p');
    logo.className = 'saber-logo';

    if (window.saberWebsiteConfig.branding === 'standard') {
        logo.innerHTML = '<img src="' + jsHost + '/images/logo.png" height="31">';
    }
    form_body.appendChild(logo);

    // load form fields from website model
    var fields = settings.get('fields');

    fields.sort(function (a, b) {
        if (a.sort < b.sort) return -1;

        if (a.sort > b.sort) return 1;

        return 0;
    });

    // array of field objects loaded into the JS form
    self.form_fields = [];

    for (var i = 0, l = fields.length; i < l; i++) {
        var field = fields[i];

        var f = null;

        if (field.active) {
            if (field.type == 'rating') {
                if (Fields.rating[field.display_as]) {
                    f = new Fields.rating[field.display_as](field, values);
                }
            } else if (Fields[field.type]) {
                f = new Fields[field.type](field, values);
            }

            if (f) {
                f.append_to(form_body);

                self.form_fields.push(f);
            }
        }
    }

    // logic listeners need to be set up after all the fields have been added to the DOM
    for (var i = 0, l = self.form_fields.length; i < l; i++) {
        self.form_fields[i].logic_listeners();
    }

    var buttons = document.createElement('div');
    buttons.className = 'text-center buttons';

    if (window.saberWebsiteConfig.screenshots &&
        settings.get('display_screenshot_button') &&
        window.saberWebsiteConfig.displayScreenshotButton) {
        // ------------- Screenshot button -------------- \\
        var screenshot_button = document.createElement('button');
        screenshot_button.className = 'btn btn-default btn-lg btn-screenshot';

        if (values.has_screenshot) {
            screenshot_button.textContent = i18n.t('buttons.edit_screenshot');
        } else {
            screenshot_button.textContent = i18n.t('buttons.add_screenshot');
        }

        buttons.appendChild(screenshot_button);

        // if no highlights, just add the screenshot HTML and stay on form
        if (settings.get('no_highlights')) {
            screenshot_button.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                values.add_screenshot([]);
                state.screenshot_added();

                buttons.removeChild(screenshot_button);
            }, true);
        } else {
            screenshot_button.addEventListener('mouseup', function (e) {
                e.preventDefault();
                e.stopPropagation();

                state.add_edit_screenshot(false);
            }, true);

            screenshot_button.addEventListener('touchstart', function (e) {
                e.preventDefault();
                e.stopPropagation();

                state.add_edit_screenshot(true);
            }, true);
        }
    }

    var screenshot_error_sm = false;
    var screenshot_error_lg = false;

    self.validate_screenshot = function () {
        if (window.saberWebsiteConfig.screenshots) {
            // reset
            if (screenshot_error_sm) {
                buttons.removeChild(screenshot_error_sm);
            }
            if (screenshot_error_lg) {
                buttons.removeChild(screenshot_error_lg);
            }
            buttons.className = buttons.className.replace(/ has-error/, '');

            if (settings.get('require_screenshot') && !values.has_screenshot) {
                buttons.className += ' has-error';

                screenshot_error_lg = document.createElement('span');
                screenshot_error_lg.className = 'help-block lg-screens';
                screenshot_error_lg.textContent = i18n.t('errors.screenshot_requried');
                buttons.appendChild(screenshot_error_lg);

                screenshot_error_sm = document.createElement('span');
                screenshot_error_sm.className = 'help-block sm-screens';
                screenshot_error_sm.textContent = i18n.t('errors.screenshot_requried');
                buttons.insertBefore(screenshot_error_sm, submit_button);

                return false;
            }
        }

        return true;
    }

    self.get_form_container = function () {
        return form_container;
    }

    self.get_screenshot_button = function () {
        return screenshot_button;
    }

    // -------------- Submit button --------------- \\
    if(window.saberWebsiteConfig.apiKey !== '') {
        var submit_button = document.createElement('button');
        submit_button.className = 'btn btn-primary btn-lg';
        submit_button.type = 'submit';
        submit_button.textContent = i18n.t('buttons.submit');

        buttons.appendChild(submit_button);

        form_body.appendChild(buttons);
        form.onsubmit = state.submit;
    }

    if (window.saberWebsiteConfig.branding !== 'none') {
        var branding = document.createElement('div');
        branding.classList.add('branding')

        var branding_link = document.createElement('a');

        var branding_logo = document.createElement('img');
        branding_logo.src = jsHost + '/images/saberfeedback-mini-logo.svg';
        branding_logo.height = '20';
        branding_logo.width = '21';

        branding_link.href = referrerLink('powered-by');
        branding_link.target = '_blank';
        branding_link.textContent = i18n.t('form.powered_by') + ' Saber Feedback';
        branding_link.prepend(branding_logo);
        branding.appendChild(branding_link);

        form_body.appendChild(branding);
    }

    // focus the first field
    var first_field = form_body.querySelector('input,textarea,select');
    if (first_field) {
        // Wait 100ms, so the form has been positioned and if the first field is a <select>
        // it opens the dropdown in the right position
        setTimeout(function(){ first_field.focus() }, 100);
    }
}

const viewModal = function(state, style) {
  var self = this;

  Views.base.call(self);

  self.element = document.createElement('div');
  self.element.className = 'saber-feedback saber-feedback-modal saber-feedback-overlay';

  self.element.style.position = 'fixed';
  self.element.style.top = 0;
  self.element.style.right = 0;
  self.element.style.bottom = 0;
  self.element.style.left = 0;
  self.element.style.background = 'rgba(0,0,0,0.3)';
  self.element.style.borderColor = '#337ab7';
  self.element.style.borderStyle = 'solid';
  self.element.style.borderWidth = 0;
  self.element.style.zIndex = 2147483647 - 10; // max z-index minus 10

  var overlay_coords = {
    style: 'modal'
  };

  self.position = function(position) {
    overlay_coords.top = position.top;
    overlay_coords.left = position.left;
    overlay_coords.bottom = position.top + position.height;
    overlay_coords.right = position.left + position.width;

    self.style({
      top: `${position.top}px`,
      height: `${position.height}px`,
      left: `${position.left}px`,
      width: `${position.width}px`,
      display: 'block',

      // default uses position: fixed with bottom and right to make full screen, so remove those when positioning
      position: 'absolute',
      right: 'auto',
      bottom: 'auto'
    });
  };

  self.style = function(style) {
    for(var i in style) {
      self.element.style[i] = style[i];
    }

    return self;
  };

  self.as_overlay = function(scroll_pos) {
    // offset the overlay coords by the scroll position to ensure the highlight ends up in the right place
    overlay_coords.top = Math.max(0, overlay_coords.top - scroll_pos.top);
    overlay_coords.bottom = Math.max(0, overlay_coords.bottom - scroll_pos.top);
    overlay_coords.left = Math.max(0, overlay_coords.left - scroll_pos.left);
    overlay_coords.right = Math.max(0, overlay_coords.right - scroll_pos.left);

    return overlay_coords;
  };

  self.set_on_click = function(handler) {
    self.element.onclick = handler;
  };

  self.style(style);

  self.add_root_element(self.element);
}
const viewInfo = function(state, params) {
  var self = this;

  self.traits = {
    screenshot_added: function() {
      self.info_container.className += ' screenshot-added';
      self.info.className += ' alert-success';
      self.add_isolated_element(self.info_container);
    },
    screenshot_info: function() {
      self.info.className += ' alert-info';
      self.add_isolated_element(self.info_container);
    }
  };

  Views.base.call(self);

  self.info_container = document.createElement('div');
  self.info_container.className = 'saber-feedback saber-info';

  self.info = document.createElement('div');
  self.info.className = 'alert';
  self.info.innerHTML = params.message;

  if(params.on_cancel) {
    var info_cancel = document.createElement('img');
    info_cancel.className = 'icon icon-cancel';
    info_cancel.src = window.saberFeedbackOptions.jsHost + '/images/icon-close.svg';
    self.info.appendChild(info_cancel);
    info_cancel.addEventListener('click', params.on_cancel, false);
    self.info.addEventListener('touchstart', params.on_cancel, false);
  }

  self.info_container.appendChild(self.info);

  self.traits[params.trait].call(self);
}
const viewHighlight = function(state, params) {
  var highlighted_element, bounding_box;

  var self = this;

  Views.base.call(self);

  var highlight_padding = 10;
  var border_width = 2;

  var body_click_listener_active = false;

  /**
   * 0 = top
   * 1 = top right
   * 2 = right
   * 3 = bottom right
   * 4 = bottom
   * 5 = bottom left
   * 6 = left
   * 7 = top left
   */
  var modals = [];

  self.init = function() {
    var modal;
    
    for(var i = 0; i < 8; i++) {
      // remove modal if it exists
      if(modals[i]) {
        modals[i].destroy();
      }

      // only show the first modal until they are positioned, to stop 8 full page
      // modals from stacking on top of each other and making the screen briefly black
      modal = state.load_view('modal', { display: i === 1 ? 'block' : 'none' });

      modals[i] = modal;
    }

    modals[0].style({ borderBottomWidth: `${border_width}px` });
    modals[2].style({ borderLeftWidth: `${border_width}px` });
    modals[4].style({ borderTopWidth: `${border_width}px` });
    modals[6].style({ borderRightWidth: `${border_width}px` });
  }
  self.init();

  // returns a unique identifier of the element, in the form of:
  // $GP_TAGNAME:nth-of-type($OFFSET)>$P_TAGNAME:nth-of-type($OFFSET)>$TAGNAME:nth-of-type($OFFSET)
  self.element_path = function(element) {
    if(element.parentElement) {
      var nth_of_type = 1;
      var siblings = element.parentElement.childNodes;
      var siblings_length = siblings.length;

      for(var i = 0; i < siblings_length; i++) {
        var sibling = siblings[i];
       
        if(sibling === element) {
          return self.element_path(element.parentElement)+'>'+element.nodeName+':nth-of-type('+(nth_of_type)+')';
        }
        
        if(sibling.nodeName === element.nodeName) {
          nth_of_type++;
        }
      }
    }
    else {
      return 'HTML';
    }
  };

  self.highlight_bounding_box = function(bounding_box, page_size) {
    // top
    modals[0].position({
      top: 0,
      height: bounding_box.top,
      left: bounding_box.left,
      width: bounding_box.width
    });

    // top-right
    modals[1].position({
      top: 0,
      height: bounding_box.top,
      left: bounding_box.right,
      width: Math.max(page_size.width - bounding_box.right - border_width, 0)
    });


    // right
    modals[2].position({
      top: bounding_box.top,
      height: bounding_box.height,
      left: bounding_box.right - border_width,
      width: Math.max(page_size.width - bounding_box.right - border_width, 0)
    });

    // bottom-right
    modals[3].position({
      top: bounding_box.bottom,
      height: Math.max(page_size.height - bounding_box.bottom - border_width, 0),
      left: bounding_box.right,
      width: Math.max(page_size.width - bounding_box.right - border_width, 0)
    });

    // bottom
    modals[4].position({
      top: bounding_box.bottom - border_width,
      height: Math.max(page_size.height - bounding_box.bottom - border_width, 0),
      left: bounding_box.left,
      width: bounding_box.width
    });

    // bottom-left
    modals[5].position({
      top: bounding_box.bottom,
      height: Math.max(page_size.height - bounding_box.bottom - border_width, 0),
      left: 0,
      width: bounding_box.left
    });

    // left
    modals[6].position({
      top: bounding_box.top,
      height: bounding_box.height,
      left: 0,
      width: bounding_box.left
    });

    // top-left
    modals[7].position({
      top: 0,
      height: bounding_box.top,
      left: 0,
      width: bounding_box.left
    });
  }

  self.highlight_element = function(element) {
    // find path to highlighted element
    values.set_element_path(self.element_path(element));

    highlighted_element = element;

    var page_size = secret_sauce.page_size();

    var modal_style;

    // figure out element offset position by looping up through offsetParent and adding it
    var offset_left = 0;
    var offset_top = 0;
    var computed_style;
    var offset_element = element;

    do {
      offset_left += offset_element.offsetLeft;
      offset_top += offset_element.offsetTop;

      // allow for additional scroll position offset when dealing with position: fixed;
      computed_style = window.getComputedStyle(offset_element);

      if(computed_style && computed_style.position === 'fixed') {
        offset_left += window.scrollX;
        offset_top += window.scrollY;
      }

      offset_element = offset_element.offsetParent;
    } while(offset_element);

    // create a padded bounding box around the highlighted element
    bounding_box = {
      left: Math.max(offset_left - highlight_padding, 0),
      top: Math.max(offset_top - highlight_padding, 0),
      right: Math.min(offset_left + element.offsetWidth + highlight_padding, page_size.width),
      bottom: Math.min(offset_top + element.offsetHeight + highlight_padding, page_size.height)
    };

    bounding_box.width = bounding_box.right - bounding_box.left;
    bounding_box.height = bounding_box.bottom - bounding_box.top; 

    // -------------------------- modals ------------------------- \\
    self.highlight_bounding_box(bounding_box, page_size);
  }

  self.get_highlighted_element = function() {
    return highlighted_element;
  }

  self.get_modals = function() {
    return modals;
  }

  self.hide_all_modals = function() {
    for(var i = 0; i < 8; i++) {
      modals[i].style({ display: 'none' });
    }
  }
}
const viewPromo = function(state, params) {  
  var self = this;

  Views.base.call(self);

  self.message_container = document.createElement('div');
  self.message_container.className = 'saber-feedback saber-message feedback-promo';

  if(params.css_class) {
    self.message_container.className += ` ${params.css_class}`;
  }

  self.add_isolated_element(self.message_container);

  var jsHost = window.saberFeedbackOptions.jsHost;

  // Add cancel button
  self.promo_cancel = document.createElement('img');
  self.promo_cancel.className = 'icon icon-cancel cancel-promo';
  self.promo_cancel.src = window.saberFeedbackOptions.jsHost + '/images/icon-close.svg';
  self.message_container.appendChild(self.promo_cancel);
  self.promo_cancel.onclick = state.done;

  var panel = document.createElement('div');
  panel.className = 'panel panel-default';
  self.message_container.appendChild(panel);


  var panel_body = document.createElement('div');
  panel_body.className = 'panel-body';

  panel_body.innerHTML = `<h3>${i18n.t('complete')}</h3>
    <h4>${i18n.t('promo.title')}</h4>
      <a target="_blank" href="${referrerLink('promo')}">
        <img src="${jsHost}/images/logo.png" width="240">
      </a>
    </span>

    <p>
      <a target="_blank" href="${referrerLink('promo')}">${i18n.t('promo.content')}</a>
    </p>`;

  panel.appendChild(panel_body);

  self.set_cancel = function(handler) {
    self.promo_cancel.onclick = handler;
  };
}

            var Views = {
                base: viewBase,
                message: viewMessage,
                button: viewButton,
                form: viewForm,
                modal: viewModal,
                info: viewInfo,
                highlight: viewHighlight,
                promo: viewPromo
            };


            // Fields
const fieldBase = function (field, values) {
    var self = this;

    self.value, self.element, self.label, self.container;

    self.field_name = field.name;
    self.field_name_safe = self.field_name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]/gi, '');
    self.field_id = `feedback-${self.field_name_safe}-field`;
    self.tag_name = 'input';
    self.is_required = false;
    self.is_visible = true;

    // ---------------- Validation --------------- \\
    self.been_validated = false;
    self.errors = [];

    self.set_required = function (required) {
        if (required === self.is_required) {
            return;
        }

        self.is_required = required;

        if (required) {
            var asterisk = document.createElement('em');
            asterisk.className = 'required';
            asterisk.title = i18n.t('form.required');
            asterisk.textContent = ' *';

            self.label.appendChild(asterisk);
        } else {
            var asterisk = self.label.getElementsByTagName('em');

            for (var i = 0; i < asterisk.length; i++) {
                self.label.removeChild(asterisk[i]);
            }
        }

        self.re_validate();
    };

    self.add_error = function (message) {
        if (self.container.className.indexOf('has-error') == -1) {
            self.container.className += ' has-error';
        }

        var error = document.createElement('span');
        error.className = 'help-block';
        error.textContent = message;

        self.errors.push(error);

        self.container.appendChild(error);
    };

    self.validate = function () {
        self.container.className = self.container.className.replace(' has-error', '');
        self.container.className = self.container.className.replace(' has-success', '');

        var i;

        for (i = 0; i < self.errors.length; i++) {
            self.container.removeChild(self.errors[i]);
        }

        self.errors = [];

        // don't run validations if the field is not currently visible
        if (self.is_visible) {
            for (i = 0; i < self.validations.length; i++) {
                self.validations[i]();
            }
        }

        if (self.errors.length === 0) {
            self.container.className += ' has-success';
        }

        self.been_validated = true;

        // return true if field is valid
        return self.errors.length === 0;
    };

    self.re_validate = function () {
        if (self.been_validated) {
            self.validate();
        }
    }

    self.validations = [
        function () {
            if (self.is_required && self.value === '') {
                self.add_error(i18n.t('errors.field_invalid.required'));
            }
        }
    ];

    // ---------------- Set up elements --------------------- \\
    self.create_element = function () {
        self.element = document.createElement(self.tag_name);
        self.element.className = 'form-control';
        self.element.id = self.field_id;
        self.element.name = self.field_name;

        self.element.onchange = self.set_value;
        self.element.onblur = self.set_value;
    };

    self.create_label = function () {
        self.label = document.createElement('label');

        // if an in-field translation is defined, use it
        if (field.i18n && field.i18n[i18n.get_current_locale()]) {
            self.label.textContent = field.i18n[i18n.get_current_locale()];
        } else {
            self.label.textContent = i18n.t(`form.fields.labels.${field.name}`, field.name);
        }

        self.label.className = 'control-label';

        self.label.setAttribute('for', self.field_id);

        self.set_required(field.required);

        return self.label;
    };

    self.create_container = function () {
        self.container = document.createElement('div');

        self.container.className = `form-group feedback-${self.field_name_safe} feedback-${field.type}`;

        return self.container;
    };


    // --------------------- Field Value ------------------- \\
    self.set_default_value = function () {
        self.element.value = self.value;
    };

    self.set_value = function () {
        self.value = self.element.value || '';

        self.validate();

        self.save_value();
    };

    self.save_value = function () {
        values.set(self.field_name, self.value);
    };

    self.append_to = function (form) {
        self.create_container();
        self.container.appendChild(self.create_label());
        self.container.appendChild(self.element);

        if (values.get(self.field_name)) {
            self.value = values.get(self.field_name);
            self.set_default_value();
            self.validate();
        } else {
            self.value = '';
        }

        form.appendChild(self.container);
    };

    // ------------------- Logic ----------------------- \\
    self.logic_factory = function (logic) {
        var logic_function;

        switch (logic.condition) {
            case 'is_defined':
            case 'not_is_defined':
                logic_function = function () {
                    return !!values.get(logic.field);
                }
                break;

            case 'equals':
            case 'not_equals':
                logic_function = function () {
                    return values.get(logic.field) == logic.value;
                }
                break;

            case 'contains':
            case 'not_contains':
                logic_function = function () {
                    var value = values.get(logic.field);
                    return !!value && (value.indexOf(logic.value) >= 0);
                }
                break;

            case 'starts_with':
            case 'not_starts_with':
                logic_function = function () {
                    var value = values.get(logic.field);
                    return !!value && (value.indexOf(logic.value) === 0);
                }
                break;

            case 'ends_with':
            case 'not_ends_with':
                logic_function = function () {
                    var value = values.get(logic.field);
                    return !!value && (value.lastIndexOf(logic.value) === value.length - logic.value.length);
                }
                break;

            case 'greater_than':
            case 'not_greater_than':
                logic_function = function () {
                    return parseFloat(values.get(logic.field)) > parseFloat(logic.value);
                }
                break;

            case 'less_than':
            case 'not_less_than':
                logic_function = function () {
                    return parseFloat(values.get(logic.field)) < parseFloat(logic.value);
                }
                break;
        }

        if (logic_function) {
            // not modifier
            if (logic.condition.indexOf('not_') === 0) {
                return function () {
                    return !logic_function();
                }
            } else {
                return logic_function;
            }
        }

        return false;
    };

    self.trigger_logic = function (state) {
        var target = isolation_frame.contentWindow.document.querySelector(`.feedback-${self.field_name_safe}`);

        if (state) {
            if (target.style.display !== 'block') {
                target.style.display = 'block';
                self.is_visible = true;

                // put back in values
                self.save_value();
            }
        } else {
            if (target.style.display !== 'none') {
                target.style.display = 'none';
                self.is_visible = false;

                // remove from values
                values.unset(field.name);
            }
        }
    }

    /*
    "logic":{
      "type": "and",
      "conditions": [
        {
          "field": "Email",
          "condition": "is_defined"
        },
        {
          "field": "NPS rating",
          "condition": "greater_than",
          "value": 8
        },
        {
          "field": "Email",
          "condition": "contains",
          "value":"@"
        }
      ]
    }
    */

    self.logic_listeners = function () {
        if (field.logic && field.logic.conditions) {
            var logic_fields = [];
            var logic_functions = [];

            for (var k = 0; k < field.logic.conditions.length; k++) {
                var condition = field.logic.conditions[k];

                if (logic_fields.indexOf(condition.field) === -1) {
                    logic_fields.push(condition.field);
                }

                var logic_function = self.logic_factory(condition);

                if (logic_function) {
                    logic_functions.push(logic_function);
                }
            }

            if (field.logic.type === 'and') {
                values.observes(logic_fields, function (key, value) {
                    var trigger_state = true;

                    for (var l = 0; l < logic_functions.length; l++) {
                        if (!logic_functions[l]()) {
                            trigger_state = false;

                            // break loop
                            l = logic_functions.length;
                        }
                    }

                    self.trigger_logic(trigger_state);
                });
            } else if (field.logic.type === 'or') {
                values.observes(logic_fields, function (key, value) {
                    var trigger_state = false;

                    // or - only need one sucess to trigger logic
                    for (var l = 0; l < logic_functions.length; l++) {
                        if (logic_functions[l]()) {
                            trigger_state = true;

                            // break loop
                            l = logic_functions.length;
                        }
                    }

                    self.trigger_logic(trigger_state);
                });
            }

            // trigger the observer to set everything up
            values.trigger_observer(logic_fields[0]);
        }
    }
}
const fieldParagraph = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.create_element = function () {
        self.element = document.createElement('p');
        self.element.id = self.field_id;

        // if an in-field translation is defined, use it
        if (field.i18n && field.i18n[i18n.get_current_locale()]) {
            self.element.textContent = field.i18n[i18n.get_current_locale()];
        } else {
            self.element.textContent = i18n.t(`form.fields.labels.${field.name}`, field.name);
        }

        /*
        Style example:
        "bold": true,
        "italic": true,
        "underline": true,
        "size": 24,
        "align": "center",
        "color": "#cc0000"
        */

        if (field.style) {
            if (field.style.bold) {
                self.element.style.fontWeight = 'bold';
            }
            if (field.style.italic) {
                self.element.style.fontStyle = 'italic';
            }
            if (field.style.underline) {
                self.element.style.textDecoration = 'underline';
            }
            if (field.style.size) {
                self.element.style.fontSize = `${field.style.size}px`;
            }
            if (field.style.align) {
                self.element.style.textAlign = field.style.align;
            }
            if (field.style.color) {
                self.element.style.color = field.style.color;
            }
        }
    };

    self.append_to = function (form) {
        self.create_container();
        self.container.appendChild(self.element);
        form.appendChild(self.container);
    };

    self.create_element();
}
const fieldText = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.create_element();
    self.element.onkeyup = self.set_value;

    // use try/catch as IE9 throws an error when trying to set HTML5 input types (eg: email)
    try {
        self.element.type = field.data_type || 'text';
    } catch (e) {
        self.element.type = 'text';
    }

    if (field.data_type === 'email') {
        self.element.autocomplete = field.data_type;
        self.validations.push(function () {
            if (self.value !== '' && !self.value.match(/^.+@.+$/)) {
                self.add_error(i18n.t('errors.field_invalid.email'));
            }
        });
    }
}
const fieldTextarea = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.tag_name = 'textarea';

    self.create_element();
    self.element.onkeyup = self.set_value;

    self.element.setAttribute('rows', field.rows);
}
const fieldDropdown = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.tag_name = 'select';

    self.set_default_value = function () {
        var option = self.element.querySelector(`option[value="${self.value}"]`);

        if (option) {
            option.selected = true;
        }
    }

    self.create_element();

    field.options.sort(function (a, b) {
        if (a.sort < b.sort) return -1;

        if (a.sort > b.sort) return 1;

        return 0;
    });

    var option, option_element;

    option_element = document.createElement('option');
    option_element.value = '';
    option_element.textContent = i18n.t('form.dropdown_please_choose');
    self.element.appendChild(option_element);

    for (var i = 0, l = field.options.length; i < l; i++) {
        option = field.options[i];

        if (option.active) {
            option_element = document.createElement('option');
            option_element.value = option.name;

            // if an in-option translation is defined, use it
            if (option.i18n && option.i18n[i18n.get_current_locale()]) {
                option_element.textContent = option.i18n[i18n.get_current_locale()];
            } else {
                option_element.textContent = i18n.t(`form.fields.options.${field.name}.${option.name}`, option.name);
            }

            self.element.appendChild(option_element);
        }
    }
}
const fieldCheckbox = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.value = false;

    self.set_value = function () {
        self.value = self.element.checked;

        self.save_value();
    };

    self.set_default_value = function () {
        if (self.value) {
            self.element.checked = true;
        }
    };

    self.save_value = function () {
        if (self.value) {
            values.set(self.field_name, 1);
        } else {
            values.unset(self.field_name);
        }
    };

    self.create_element();

    self.element.type = 'checkbox';

    // checkbox doesn't have the form-control classname
    self.element.className = '';

    self.append_to = function (form) {
        self.create_container();
        self.container.className = `checkbox feedback-${self.field_name_safe}`;

        self.label = document.createElement('label');

        self.label.appendChild(self.element);
        self.label.appendChild(document.createTextNode(i18n.t(`form.fields.labels.${field.name}`, field.name)));

        self.container.appendChild(self.label);

        if (values.get(self.field_name)) {
            self.value = !!values.get(self.field_name);
            self.set_default_value();
        }

        form.appendChild(self.container);
    }
}
const fieldRatingBase = function (field, values) {
    var self = this;

    Fields.base.call(self, field, values);

    self.set_value = function (value) {
        self.value = value;

        self.validate();

        self.save_value();
    };

    self.choose_option = function (option) {
        self.set_value(option.getAttribute('data-value'));

        var options = this.container.querySelectorAll('[data-value]');

        for (var i = 0; i < options.length; i++) {
            options[i].className = options[i].className.replace(/ ?active/, '');
        }

        option.className += ' active';
    };

    self.set_default_value = function () {
        var option = self.container.querySelector(`[data-value="${self.value}"]`);

        if (option) {
            self.choose_option(option);
        }
    };

    self.append_to = function (form) {
        self.create_container();
        self.container.className += ` display-as-${field.display_as}`;

        self.create_label();
        self.container.appendChild(self.label);

        var wrapper = document.createElement('div');
        wrapper.id = self.field_id;
        wrapper.className = 'rating-options';
        self.container.appendChild(wrapper);

        self.add_options(wrapper);

        if (values.get(self.field_name)) {
            self.value = values.get(self.field_name);
            self.set_default_value();
            self.validate();
        } else {
            self.value = '';
        }

        form.appendChild(self.container);
    }
}
const fieldRatingNumber = function (field, values) {
    var self = this;

    Fields.rating.base.call(self, field, values);

    self.add_options = function (wrapper) {
        var a;
        for (var i = 0; i <= 10; i++) {
            a = document.createElement('a');
            a.setAttribute('data-value', i);
            a.href = '#';
            a.textContent = i;

            wrapper.appendChild(a);

            a.onclick = function (e) {
                e.preventDefault();

                self.choose_option(e.target);
            }
        }
    };
}
const fieldRatingEmoji = function (field, values) {
    var self = this;

    Fields.rating.base.call(self, field, values);

    self.add_options = function (wrapper) {
        var img, option, label;

        var options = ['Hate', 'Dislike', 'Neutral', 'Like', 'Love'];

        for (var i = 0, l = options.length; i < l; i++) {
            option = options[i];

            label = i18n.t(`form.fields.options.${field.name}.${option}`);

            img = document.createElement('img');
            img.className = 'emoji-rating ' + option.toLowerCase();
            img.title = label;
            img.alt = label;
            img.width = 50;
            img.height = 50;
            img.src = window.saberFeedbackOptions.jsHost + '/images/rating/' + option.toLowerCase() + '.svg';
            img.setAttribute('data-value', option);

            img.onclick = function (e) {
                self.choose_option(e.target);
            };

            wrapper.appendChild(img);
        }
    };
}
const fieldRatingStar = function (field, values) {
    var self = this;

    Fields.rating.base.call(self, field, values);

    self.choose_option = function (option) {
        var value = parseInt(option.getAttribute('data-value'));

        self.set_value(value);

        var stars = option.parentElement.querySelectorAll('img.star-rating');

        for (var i = 0; i < stars.length; i++) {
            if (parseInt(stars[i].getAttribute('data-value')) <= value) {
                stars[i].className = 'star-rating icon-star';
                stars[i].src = window.saberFeedbackOptions.jsHost + '/images/icon-star.svg';
            } else {
                stars[i].className = 'star-rating icon-star-empty';
                stars[i].src = window.saberFeedbackOptions.jsHost + '/images/icon-star-empty.svg';
            }
        }
    };

    self.add_options = function (wrapper) {
        var star;

        for (var i = 1; i <= 5; i++) {
            star = document.createElement('img');
            star.className = 'star-rating icon-star-empty';
            star.src = window.saberFeedbackOptions.jsHost + '/images/icon-star-empty.svg';
            star.setAttribute('data-value', i);
            star.height = 47;
            star.width = 47;

            star.onclick = function (e) {
                self.choose_option(e.target);
            };

            wrapper.appendChild(star);
        }
    };
}
const fieldRatingThumb = function (field, values) {
    var self = this;

    Fields.rating.base.call(self, field, values);

    self.add_options = function (wrapper) {
        var icon, option;

        var options = ['Good', 'Bad'];

        for (var i = 0, l = options.length; i < l; i++) {
            option = options[i];

            icon = document.createElement('img');
            icon.className = 'icon-thumbs-' + (option === 'Good' ? 'up' : 'down') + ' thumb-rating ' + option.toLowerCase();
            icon.src = window.saberFeedbackOptions.jsHost + '/images/icon-thumbs-' + (option === 'Good' ? 'up' : 'down') + '.svg';
            icon.title = i18n.t(`form.fields.options.${field.name}.${option}`);
            icon.setAttribute('data-value', option);
            icon.width = 47;
            icon.height = 47;
            icon.onclick = function (e) {
                self.choose_option(e.target);
            };

            wrapper.appendChild(icon);
        }
    };
}

            var Fields = {
                base: fieldBase,
                paragraph: fieldParagraph,
                text: fieldText,
                textarea: fieldTextarea,
                dropdown: fieldDropdown,
                checkbox: fieldCheckbox,
                rating: {
                    base: fieldRatingBase,
                    numbers: fieldRatingNumber,
                    emojis: fieldRatingEmoji,
                    stars: fieldRatingStar,
                    thumbs: fieldRatingThumb
                }
            };

            // don't warn about unhandled rejections in production mode
            Promise._unhandledRejectionFn = function _unhandledRejectionFn(err) {
                if (window.saberFeedbackOptions.debug) {
                    debugger;
                }
            };

            // reference to 'this'
            var self = this;

            // ------------------- Sudo-globals (available anywhere in window.Saber) ----------------- \\

            // reference to document body
            var body;

            // isolation frame
            var isolation_frame;

            var values;

            function referrerLink(medium) {
                return `https://saberfeedback.com/powered-by-sf/?utm_source=live_button&utm_medium=${medium}&utm_campaign=widget`;
            }

            function consoleLog(...messages) {
                if (window.saberFeedbackOptions.debug) {
                    if ((typeof console !== 'undefined') && console.log) {
                        console.log(...messages);
                    }
                }
            }

            /**
             * @param imageName - a name of a file in our server's /images/ folder
             */
            function preloadImage(imageName) {
                var img = new Image();
                var jsHost = window.saberFeedbackOptions.jsHost;
                img.src = jsHost + '/images/' + imageName;
            }

            // -------------------------- Private methods --------------------------- //
            // Initialize available states
            function load_states() {
                var state;

                for (var state_name in States) {
                    // base is abstract, so don't instantiate
                    if (state_name !== 'base') {
                        state = new States[state_name](self);

                        state_machine.register_state(state, state_name);
                    }
                }
            }

            function do_event_callback(event, params) {
                try {
                    settings.get(`on_${event}`)(params);
                } catch (e) {
                }
            }

            // --------------------------- Public methods --------------------------- //

            /**
             * Initialise Saber - load the CSS, apply any options that were set before the JS loaded (saber_options and do('set_option'))
             * Only ever run once on each page load, after the window.Saber object has been created
             */
            this.construct = function () {
                // check the body exists, as some JS frameworks may have temporarily removed it
                body = document.getElementsByTagName('body')[0];

                if (body) {
                    // load available states
                    load_states();

                    // load the stylesheet
                    var button_css = document.createElement('link');
                    var head = document.getElementsByTagName('head')[0];
                    button_css.setAttribute('href', window.saberFeedbackOptions.jsHost + '/button-style-0ca31ce.css');
                    button_css.setAttribute('rel', 'stylesheet');
                    head.appendChild(button_css);

                    // override default options with those from DB
                    settings.set(window.saberWebsiteConfig.buttonSettings);

                    // overwrite db options with those passed in through Saber.go('set_option(s)', ...),
                    self.run();

                    // pre-load images
                    preloadImage('logo.png');
                    preloadImage('saberfeedback-mini-logo.svg');
                    preloadImage('rating/hate.svg');
                    preloadImage('rating/dislike.svg');
                    preloadImage('rating/neutral.svg');
                    preloadImage('rating/like.svg');
                    preloadImage('rating/love.svg');
                    preloadImage('icon-star.svg');
                    preloadImage('icon-star-empty.svg');
                    preloadImage('icon-thumbs-up.svg');
                    preloadImage('icon-thumbs-down.svg');
                    preloadImage('icon-ok.svg');
                    preloadImage('icon-close.svg');

                    // put the widget into idle mode once the isolation frame is fully ready
                    self.isolation_frame_ready = function () {
                        state_machine.change('idle');

                        // delay on_ready by 0.5 seconds to make sure the isolation frame is ready
                        setTimeout(function () {
                            do_event_callback('ready');
                        }, 500);
                    }

                    // set up the isolation frame
                    isolation_frame = document.createElement('iframe');
                    body.appendChild(isolation_frame);

                    // add custom CSS to the iframe
                    var customCss;
                    if (window.saberWebsiteConfig.whiteLabel) {
                        customCss = window.saberWebsiteConfig.customCss;
                    } else {
                        customCss = '';
                    }

                    var jsHost = window.saberFeedbackOptions.jsHost;

                    isolation_frame.className = 'saber-feedback saber-isolation-frame';
                    isolation_frame.setAttribute('frameborder', '0');
                    isolation_frame.setAttribute('allowtransparancy', true);
                    isolation_frame.style.display = 'none';
                    isolation_frame.style.overflow = 'hidden';
                    isolation_frame.scrolling = 'no';

                    isolation_frame.contentWindow.document.open();
                    isolation_frame.contentWindow.document.write(`<!DOCTYPE html>
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>Saber Feedback</title>
    <link href="${jsHost}/feedback-0ca31ce.css" rel="stylesheet"></link>
    <style type="text/css">${customCss}</style>
  </head>
  <body></body>
</html>`);
                    isolation_frame.contentWindow.document.close();

                    isolation_frame.contentWindow.document.body.addEventListener('load', self.isolation_frame_ready());
                } else {
                    setTimeout(this.construct, 1000);
                }
            };

            /**
             * open the widget
             */
            self.open = function () {
                // only do this if not already open
                if (state_machine.get_current_state() === 'idle') {
                    do_event_callback('open');

                    // get HTML as it was when feedback button was clicked
                    values.get_page_html();

                    event_log.log_feedback('Feedback Button Clicked');

                    if (/^localhost$|\.dev$|\.test$|(^10|^127|^192)\./i.test(location.hostname) && !settings.get('suppress_localhost_warning')) {
                        state_machine.change('message', {
                            message: i18n.t('errors.localhost_warning'),
                            buttons: [
                                {
                                    label: i18n.t('buttons.ok'),
                                    css_class: 'primary',
                                    on_click: function () {
                                        // don't show the warning next time
                                        settings.set('suppress_localhost_warning', true);

                                        state_machine.change('form');
                                    }
                                }
                            ]
                        });
                    } else {
                        state_machine.change('form');
                    }
                }

            };

            self.init = function (apiKey, widgetApiEndpointOverride) {

                // not currently used
                // plan is that this will be the way to inject the apiKey into the widget, triggering the fetching
                // of feedback form config from widgetapi, and adding the trigger button to the host web page
                console.log('Running Saber Feedback init command')
                if (!apiKey) {
                    throw Error('init called without required apiKey parameter');
                }

                let widgetApiEndpoint = 'https://widgetapi.saberfeedback.com/feedback_form?api_key=:id';
                if (widgetApiEndpointOverride) {
                    widgetApiEndpoint = widgetApiEndpointOverride;
                }

                console.log('apiKey = ' + apiKey);
                console.log('Using widget API endpoint: ' + widgetApiEndpoint)

                // the apiKey and widgetApiEndpoint are not yet used here. They will be soon, I hope.
            }

            /**
             * Close the widget and clean up
             */
            self.close = function () {
                if (state_machine.get_current_state() !== 'idle') {
                    do_event_callback('close');

                    state_machine.change('idle');
                }
            };

            /**
             * Load options
             */
            self.set_options = function (options) {
                settings.set(options);

                state_machine.reload_state();
            };

            /**
             * Convenience method to set a single option, and then reload Saber
             */
            self.set_option = function (option, value) {
                settings.set(option, value);

                state_machine.reload_state();
            };

            /**
             * Run through any saved commands
             */
            self.run = function () {
                for (var i = 0; i < commands.length; i++) {
                    var command = commands[i];

                    switch (command.length) {
                        case 1:
                            self[command[0]]();
                            break;

                        case 2:
                            self[command[0]](command[1]);
                            break;

                        case 3:
                            self[command[0]](command[1], command[2]);
                            break;

                        case 4:
                            self[command[0]](command[1], command[2], command[3]);
                            break;
                    }
                }

                commands = [];
            };


            /**
             * Updating options after Saber is already loaded
             */
            self.do = function () {
                commands.push(arguments);

                this.run();
            };

            if (window.saberFeedbackOptions.debug) {
                /**
                 * Allow debugging from context of Saber global
                 */
                self.debug = function (callback) {
                    if (callback) {
                        callback.call(self);
                    } else {
                        debugger;
                    }
                }
            }


        })((window.Saber && window.Saber.com) ? window.Saber.com : []);


        window.Saber.construct();
    }

})();
